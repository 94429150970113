import { useState, useEffect } from 'react';
import { fetchProfile } from '../services/mypageService';

export interface UserData {
    id:             string;
    password:       string;
    name:           string;
    email:          string;
    nick:           string;
    birth:          string;
    tell:           string;
    hp:             string;
    zipcode:        string;
    city:           string;
    image:          string;
    role:           string;
    allow_email:    string;
    allow_sms:      string;
    allow_contract: string;
    nickdate:       string;
    modate:         string;
    regdate:        string;
    memo:           string;
};

export function useProfile(userId: string) {
    const [userData, setData] = useState<UserData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (userId === '') return; // userId null이면 useEffect를 실행하지 않음

        const fetchData = async () => {
            try {
                const result = await fetchProfile(userId);
                setData(result as UserData); // 수정된 부분
            } catch (err) {
                setError((err as Error).message); // 에러 메시지 추출 수정
            } finally {
                setLoading(false);
            }
        };
        if (userId) { // userId가 존재할 때만 fetch 실행
            fetchData();
        }
    }, [userId]);

    return { userData, loading, error }; // 반환 값 수정
};