import React, { useState } from "react";

import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";
import SectionCheckboxControl from "pages/mypage/common/sectionCheckboxControl";

import VcChangePasswordModal from "pages/mypage/common/changePasswordModal";

import { mediaCompanyMenuItemsDataGroup } from "data/mypage/main";
import { generalUserProfileDataGroup } from "data/mypage/profile/generalUser";
import { businessUserProfileDataGroup } from "data/mypage/profile/businessUser";
import { profileCategoryCheckDataGroup, receiveAgreeDataGroup } from "data/mypage/common/receiveAgree";

function MediaCompanyProfile() {
    const [changePwModalOpenState, setChangePwModalOpenState] = useState(false);

    // modal open, close 이벤트
    function changePwModalOpenEvent() {
        setChangePwModalOpenState(true);
    }
    function changePwModalHandleCancel() {
        setChangePwModalOpenState(false);
    }

    const subButtonEventArray = [
        // 아이디
        { event: undefined },
        // 비밀번호
        {
            event: function passwordChangeEvent() {
                // console.log("비밀번호 변경하기");
                setChangePwModalOpenState(true);
            },
        },
        // 휴대폰
        {
            event: function sendAuthNumberEvent() {
                console.log("휴대폰 인증번호 전송");
            },
        },
        // 인증번호
        {
            event: function authNumberCheckEvent() {
                console.log("인증번호 확인");
            },
        },
        // 회사명
        { event: undefined },
        // 대표자명
        { event: undefined },
        // 사업자등록번호
        {
            event: function businessIdentificationNumberCheckEvent() {
                console.log("사업자등록번호 인증");
            },
        },
        // 업태업종
        { event: undefined },
        // 주소
        {
            event: function findPostcodeCheckEvent() {
                console.log("우편번호 찾기");
            },
        },
        // 세금계산서이메일
        { event: undefined },
    ];

    function modifyButtonEvent() {
        console.log("수정하기");
    }

    function handleInputChange(id: string, value: any) {
        console.log(`Input changed: ${id} = ${value}`);
    }

    const userMergeData = [...generalUserProfileDataGroup, ...businessUserProfileDataGroup];

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <SectionFormControl categoryInputData={userMergeData} subButtonEventData={subButtonEventArray} onInputChange={handleInputChange} />
            <SectionCheckboxControl titleData={profileCategoryCheckDataGroup.title} contentData={receiveAgreeDataGroup} />
        </Box>
    );

    const topTitleData = mediaCompanyMenuItemsDataGroup[0].label;

    return (
        <Box>
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifyButtonEvent}
                            buttonTitle="수정하기"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
            <VcChangePasswordModal open={changePwModalOpenState} onCancel={changePwModalHandleCancel} />
        </Box>
    );
}

export default MediaCompanyProfile;
