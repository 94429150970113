import { colorGroup } from "./palette";
import { defaultStyleGroup } from "./measurement";

const commonBorderRadiusDefault = defaultStyleGroup.border.radius.default;
const commonBorderRadiusFull = defaultStyleGroup.border.radius.full;

// antd component - button
export const VcAntButtonTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColor: colorGroup.realColor.white.main[100],
    backgroundColorHover: colorGroup.realColor.white.main[80],
    backgroundConceptColor: colorGroup.conceptColor.main[100],
    backgroundConceptColorHover: colorGroup.conceptColor.main[80],
    backgroundConceptColorSoft: colorGroup.conceptColor.main[30],
    backgroundDangerColor: colorGroup.dangerColor.main[100],
    backgroundDangerColorHover: colorGroup.dangerColor.main[80],
    backgroundDangerColorSoft: colorGroup.dangerColor.main[20],
    backgroundDisabledColor: colorGroup.realColor.white.main[20],
    borderColor: colorGroup.realColor.white.main[100],
    borderConceptColor: colorGroup.conceptColor.main[100],
    borderDangerColor: colorGroup.dangerColor.main[100],
    borderColorDisabled: colorGroup.realColor.white.main[20],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorWhiteDisabled: colorGroup.realColor.white.main[50],
    fontColorBlack: colorGroup.realColor.black.main[100],
    fontColorBlackDisabled: colorGroup.realColor.black.main[50],
};

// antd component - input
export const VcAntInputTheme = {
    backgroundColor: colorGroup.realColor.white.main[100],
    backgroundColorHover: colorGroup.realColor.white.main[80],
    borderColor: colorGroup.realColor.white.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
};

// antd component - TextArea
export const VcAntTextAreaTheme = {
    backgroundColor: colorGroup.realColor.white.main[100],
    backgroundColorHover: colorGroup.realColor.white.main[80],
    borderColor: colorGroup.realColor.white.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
};

// antd component - select
export const VcAntSelectTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColorFull: colorGroup.realColor.white.main[100],
    backgroundColorHover: colorGroup.realColor.white.main[100],
    borderColor: colorGroup.realColor.white.main[100],
    borderColorHover: colorGroup.conceptColor.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
    fontColorBlackSoft: colorGroup.realColor.black.main[30],
};

// antd component - Radio
export const VcAntRadioTheme = {
    backgroundColorDefault: colorGroup.realColor.clear,
    backgroundColorChecked: colorGroup.conceptColor.main[100],
    borderColor: colorGroup.realColor.white.main[100],
    borderColorHover: colorGroup.conceptColor.main[100],
    borderRadius: commonBorderRadiusDefault,
    innerPointColor: colorGroup.realColor.white.main[100],
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
};

// antd component - checkbox
export const VcAntCheckboxTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColorDefault: colorGroup.realColor.clear,
    backgroundColorDefaultHover: colorGroup.conceptColor.main[30],
    backgroundColorChecked: colorGroup.conceptColor.main[100],
    backgroundColorCheckedHover: colorGroup.conceptColor.main[80],
    borderColor: colorGroup.realColor.white.main[100],
    borderColorHover: colorGroup.conceptColor.main[100],
    borderRadius: commonBorderRadiusDefault,
    innerCheckedColor: colorGroup.realColor.white.main[100],
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
    disabledBackgroundColor: colorGroup.realColor.clear,
    disabledBorderColor: colorGroup.realColor.white.main[30],
    disabledFontColorWhite: colorGroup.realColor.white.main[30],
    disabledFontColorBlack: colorGroup.realColor.black.main[30],
};

// antd component - collapse
export const VcAntCollapseTheme = {
    headerBackgroundColor: colorGroup.realColor.white.main[100],
    headerBackgroundColorHover: colorGroup.realColor.white.main[80],
    contentBackgroundColor: colorGroup.realColor.gainsboro.main[100],
    contentBackgroundColorHover: colorGroup.realColor.gainsboro.main[80],
    borderColor: colorGroup.realColor.white.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
};

// antd component - segmented
export const VcAntSegmentedTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColorDefault: colorGroup.realColor.white.main[40],
    backgroundColorActive: colorGroup.realColor.white.main[100],
    borderColor: colorGroup.realColor.white.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
};

// antd component - switch
export const VcAntSwitchTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColorDefault: colorGroup.realColor.clear,
    backgroundColorDefaultHover: colorGroup.realColor.clear,
    backgroundColorActive: colorGroup.conceptColor.main[100],
    backgroundColorActiveHover: colorGroup.conceptColor.main[80],
    backgroundConceptColor: colorGroup.conceptColor.main[100],
    backgroundConceptColorHover: colorGroup.conceptColor.main[80],
    borderColor: colorGroup.realColor.white.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
};

// antd component - table
export const VcAntTableTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColor: colorGroup.realColor.white.main[100],
    backgroundColorHover: colorGroup.realColor.white.main[80],
    backgroundConceptColor: colorGroup.conceptColor.main[100],
    backgroundConceptColorHover: colorGroup.conceptColor.main[80],
    backgroundConceptColorSoft: colorGroup.conceptColor.main[30],
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
};

// antd component - datePicker
export const VcAntDatePickerTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColorFull: colorGroup.realColor.white.main[100],
    backgroundColorHover: colorGroup.realColor.white.main[80],
    borderColor: colorGroup.realColor.white.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
};

// 커스텀 scrollbar
export const VcScrollTheme = {
    scrollbarColorClear: colorGroup.realColor.clear,
    scrollbarColorFull: colorGroup.realColor.black.main[30],
    scrollbarColorThumb: colorGroup.conceptColor.main[60],
    scrollbarBorderRadius: commonBorderRadiusFull,
};
