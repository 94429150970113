import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import { priceUnit, contractFilterRadioData } from "data/mypage/advertiser/contract/list";

import { colorGroup } from "theme/palette";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    imgPath?: string;
    titleData?: string;
    description?: string;
    date?: any;
    price?: any;
    state?: string;
}

function AdvertiserContractListLayout({ className, style, imgPath, titleData, description, date, price, state }: vcProps) {
    const navigate = useNavigate();

    const detailPath = "/mypage/advertiser/contract-management/contract-media-name/detail";

    function contractListDetailViewOnClickEvent() {
        navigate(detailPath);
    }

    const adCheckStyles = {
        "&": {
            background: colorGroup.realColor.clear,
            color: colorGroup.realColor.white.main[100],
            borderColor: colorGroup.realColor.white.main[20],
        },
    };
    const adConfirmStyles = {
        "&": {
            background: colorGroup.conceptColor.main[100],
            color: colorGroup.realColor.white.main[100],
            borderColor: colorGroup.realColor.white.main[60],
            fontWeight: "bold",
        },
    };

    const setStyles = {
        "&": { cursor: "pointer" },
        ...style,
    };

    const localeStringPrice = price.toLocaleString("ko-KR");

    const dataLabel1 = contractFilterRadioData[1].label; // 광고검수
    const dataLabel2 = contractFilterRadioData[2].label; // 광고확정
    const stateFilter = state === "check" ? dataLabel1 : state === "confirm" ? dataLabel2 : "-";
    const stateBoxStyleFilter = state === "check" ? adCheckStyles : state === "confirm" ? adConfirmStyles : {};

    return (
        <Box className="advertiserContractListLayoutWrap" sx={setStyles} onClick={contractListDetailViewOnClickEvent}>
            <Flex justify="flex-start" align="center">
                <Box className="imgBox">
                    <Box className="imgBoxInner">
                        <img src={imgPath} alt="" />
                        {/* <img src="원래 이미지 주소.jpg" onerror="this.onerror=null; this.src='대체 이미지 주소';"></img> */}
                    </Box>
                </Box>
                <Box className="informationBox">
                    <Box className="informationBoxInner">
                        <Flex justify="flex-start" align="center" style={{ width: "100%", height: "100%" }}>
                            <Box className="textBox">
                                <Box className="titleBox">
                                    <Flex justify="space-between" align="center" style={{ width: "100%", height: "100%" }}>
                                        <Box>
                                            <Typography>{titleData}</Typography>
                                        </Box>
                                        <Box>
                                            <Flex>
                                                <Box className="priceTypo">
                                                    <Typography>{localeStringPrice}</Typography>
                                                </Box>
                                                <Box className="priceUnitTypo">
                                                    <Typography>{priceUnit}</Typography>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box className="descBox">
                                    <Flex justify="space-between" align="center" style={{ width: "100%", height: "100%" }}>
                                        <Box>
                                            <Flex justify="flex-start" align="center" gap="16px">
                                                <Box>
                                                    <Typography>{description}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography>{date}</Typography>
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box className="stateBox">
                                            <Box className="stateBoxInner" sx={stateBoxStyleFilter}>
                                                <Typography>{stateFilter}</Typography>
                                            </Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
}

export default AdvertiserContractListLayout;
