import React, { useState } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import ContentCard from "components/layout/contentCard";

import VcAntButton from "components/antd/general/button";

import { joinMembershipCompleteDataGroup, pageTitleData } from "data/authority/joinMembership";

function JoinMembershipComplete() {
    function serviceStartEvent() {
        console.log("시작하기");
    }

    const bodyContentData = (
        <Box className="joinMembershipCompleteBodyContent">
            <Box className="contentDataWrap">
                <Flex vertical justify="flex-start" align="center">
                    <Box className="imageBox">
                        <Box className="imageBoxText">IMG</Box>
                        <Box className="imageFile"></Box>
                    </Box>
                    <Box className="guidebox">
                        <Box className="guideTextBox">
                            <Typography>{joinMembershipCompleteDataGroup.guideText}</Typography>
                        </Box>
                        <Box className="guideDescriptionBox">
                            <Typography>{joinMembershipCompleteDataGroup.guideDescription}</Typography>
                        </Box>
                    </Box>
                    <Box className="startButtonBox">
                        <VcAntButton conceptType="concept" buttonType="default" onClick={serviceStartEvent} buttonTitle="시작하기" buttonSize="large" />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );

    return (
        <Box className="joinMembershipCompleteWrap">
            {/* 회원가입 - 완료 */}
            <ContentCard topTitle={pageTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default JoinMembershipComplete;
