import React, { useEffect, useState } from "react";

import { Row, Col, Typography, Flex } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    tableData?: any;
    viewColSize?: any;
}

function MypageSectionViewTableLayout({ className, style, tableData, viewColSize }: vcProps) {
    const [categoryAllDataArrayState, setCategoryAllDataArrayState] = useState<any[]>([]);
    useEffect(() => {
        const targetDataTitleArray = tableData.map((data: any, index: number) => data.categoryTitle);
        const targetDataTitleArrayLength = targetDataTitleArray.length;
        const targetDataContentArray = tableData.map((data: any, index: number) => data.categoryData);
        const targetAllDataArray = [];
        for (let i = 0; i < targetDataTitleArrayLength; i++) {
            targetAllDataArray[i] = { title: targetDataTitleArray[i], content: targetDataContentArray[i] };
        }
        setCategoryAllDataArrayState(targetAllDataArray);
    }, [tableData]);

    const setStyles = {
        "&": {
            display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start",
        },
    };

    // console.log(viewColSize)

    return (
        <Box className="MypageSectionViewTableLayoutWrap" sx={setStyles}>
            {categoryAllDataArrayState.map((data: any, index: number) => {
                return (
                    <Box className="viewTableLayoutWrapInner" key={index} sx={
                        viewColSize[index] === "full" ? { width: "100%" }
                        : viewColSize[index] === "half" ? { width: "50%" } : {}
                    }>
                        <Row gutter={[16, 16]} justify="center" align="middle">
                            <Col {...(viewColSize[index] === "full" ? { span: 4 } : { span: 8 })}>
                                <Box className="categoryTitleBox">
                                    <Box className="categoryTitleBoxInner">
                                        <Typography>{data.title}</Typography>
                                    </Box>
                                </Box>
                            </Col>
                            <Col {...(viewColSize[index] === "full" ? { span: 20 } : { span: 16 })}>
                                <Box className="categoryDataBox">{data.content}</Box>
                            </Col>
                        </Row>
                    </Box>
                );
            })}
        </Box>
    );
}

export default MypageSectionViewTableLayout;
