import React from "react";

import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntInput from "components/antd/data-entry/input";

import AdvertisingTableLayout from "pages/advertiser/advertising/tableLayout";

import { VcAntInputTheme } from "theme/component";

import { kpiCategoryDataGroup } from "data/advertiser/advertising/kpi";

function AdvertisingKPI() {
    const kpiDataArray: any[] = [];
    kpiCategoryDataGroup.forEach((element) => {
        kpiDataArray.push({
            categoryTitle: element.title,
            categoryData: (
                <Box className="kpiInput">
                    <Flex justify="flex-start" align="center" gap="16px" style={{ width: "100%" }}>
                        <Box style={{ width: "auto" }}>
                            <VcAntInput chooseType="basic" placeholder={element.indicatorTitPh} size="large" />
                        </Box>
                        <Box style={{ flex: 1 }}>
                            <VcAntInput chooseType="basic" placeholder={element.indicatorConPh} size="large" />
                        </Box>
                    </Flex>
                </Box>
            ),
        });
    });

    const kpiDataWrapSetStyles = {
        "&": {
            "& .kpiInput": {
                "& .ant-input": {
                    backgroundColor: VcAntInputTheme.backgroundColor,
                    "&:hover": {
                        backgroundColor: VcAntInputTheme.backgroundColorHover,
                    },
                },
            },
        },
    };

    return (
        <Box className="kpiDataWrap" sx={kpiDataWrapSetStyles}>
            <AdvertisingTableLayout tableData={kpiDataArray} />
        </Box>
    );
}

export default AdvertisingKPI;
