import { useState } from "react";
import { findIdByPhoneNumber } from "../services/findIdService";

interface FindIdResponse {
    answer: string;
    id: string;
}

export const useFindId = () => {
    const [phoneStep1, setPhoneStep1] = useState("010");
    const [phoneStep2, setPhoneStep2] = useState("");
    const [phoneStep3, setPhoneStep3] = useState("");
    const [notFoundModalOpen, setNotFoundModalOpen] = useState(false);
    const [foundResultModalOpen, setFoundResultModalOpen] = useState(false);
    const [foundId, setFoundId] = useState("");

    const phoneNumberSelectOnChangeEvent = (value: string) => {
        setPhoneStep1(value);
    };

    const handlePhoneStep2Change = (value: number) => {
        setPhoneStep2(value.toString());
    };

    const handlePhoneStep3Change = (value: number) => {
        setPhoneStep3(value.toString());
    };

    const findIdSubmitEvent = async () => {
        const phoneNumber = `${phoneStep1}-${phoneStep2}-${phoneStep3}`;
        try {
            const data: FindIdResponse[] = await findIdByPhoneNumber(phoneNumber);
            if (data.length > 0 && data[0].answer === "SUCCESS") {
                setFoundId(data[0].id);
                setFoundResultModalOpen(true);
            } else {
                setNotFoundModalOpen(true);
            }
        } catch (error) {
            setNotFoundModalOpen(true);
        }
    };

    return {
        phoneStep1,
        phoneStep2,
        phoneStep3,
        notFoundModalOpen,
        foundResultModalOpen,
        foundId,
        phoneNumberSelectOnChangeEvent,
        handlePhoneStep2Change,
        handlePhoneStep3Change,
        findIdSubmitEvent,
        setNotFoundModalOpen,
        setFoundResultModalOpen,
    };
};