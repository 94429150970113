import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useMatch } from "react-router-dom";

import $ from "jquery";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import { subLocationPathArray } from "data/mypage/main";

import { VcAntButtonTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    menuItems?: any;
}

function MypageMenu({ className, style, menuItems }: vcProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const [activeMenuNameState, setActiveMenuNameState] = useState<string>("");
    const [activeMenuIndexState, setActiveMenuIndexState] = useState<number>();

    const itemsDataKeyArray = menuItems.map((data: any, index: number) => data.key);
    const itemsDataPathArray = menuItems.map((data: any, index: number) => data.path);

    function currentLocationCheckEvent() {
        const currentLocation = location.pathname;
        const checkIndex = itemsDataPathArray.indexOf(currentLocation);
        setActiveMenuNameState(itemsDataKeyArray[checkIndex]);

        if (checkIndex === -1) {
            subLocationCheckEvent();
        }
    }

    function conditionEvent(element: any) {
        const currentLocation = location.pathname;
        if (currentLocation.includes(element)) {
            return element;
        }
    }

    function subLocationCheckEvent() {
        const currentLocation = location.pathname;

        if (currentLocation.includes("/mypage/general")) {
            // 일반
        } else if (currentLocation.includes("/mypage/advertiser")) {
            // 광고주
            const checkIndex = subLocationPathArray.advertiser.findIndex(conditionEvent);
            setActiveMenuNameState(itemsDataKeyArray[checkIndex]);
        } else if (currentLocation.includes("/mypage/media-company")) {
            // 매체사
        }
    }

    function navigateEvent(path: string) {
        navigate(path);
    }

    function menuOnClickEvent(event: any) {
        const isName = event.currentTarget.getAttribute("name");
        const isPath = event.currentTarget.value;
        // const checkIndex = itemsDataKeyArray.indexOf(isName);

        setActiveMenuNameState(isName);
        navigateEvent(isPath);
    }

    useEffect(() => {
        currentLocationCheckEvent();
    }, [location]);

    const setStyles = {
        "&": {
            "& .sidemenuItem": {
                "& .ant-btn": {
                    borderRadius: 0,
                    color: VcAntButtonTheme.fontColorWhite,
                    textAlign: "left",
                },
                "& .ant-btn.active": {
                    background: VcAntButtonTheme.backgroundConceptColor,
                },
                // 호버 hover
                "& .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover": {
                    background: VcAntButtonTheme.backgroundConceptColorSoft,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "& .ant-btn-text.active:not(:disabled):not(.ant-btn-disabled):hover": {
                    background: VcAntButtonTheme.backgroundConceptColor,
                },
            },
        },
        ...style,
    };

    return (
        <Box sx={setStyles}>
            {menuItems.map((item: any, index: number) => {
                return (
                    <Box className="sidemenuItem" key={index}>
                        <VcAntButton
                            conceptType="default"
                            // className={"menuButton " + item.key}
                            className={activeMenuNameState === item.key ? "active" : ""}
                            buttonTitle={item.label}
                            buttonType="text"
                            onClick={menuOnClickEvent}
                            name={item.key}
                            value={item.path}
                            fullWidth="100%"
                        />
                    </Box>
                );
            })}
        </Box>
    );
}

export default MypageMenu;
