export const pageTitleData = "마이페이지";

export const generalMenuItemsDataGroup = [
    { key: "general-profile", label: "프로필(일반)", path: "/mypage/general/profile" },
    { key: "change-advertiser", label: "사업자 계정 신청", path: "/mypage/general/business-user-request" },
    { key: "general-setting", label: "설정", path: "/mypage/general/setting" },
];

export const advertiserMenuItemsDataGroup = [
    { key: "advertiser-profile", label: "프로필(광고주)", path: "/mypage/advertiser/profile" },
    { key: "advertiser-partner-management", label: "파트너사 관리", path: "/mypage/advertiser/partner-management/partner-list" },
    { key: "advertiser-campaign-management", label: "캠페인 관리", path: "/mypage/advertiser/campaign-management/campaign-list" },
    { key: "advertiser-booking-management", label: "부킹 관리", path: "/mypage/advertiser/booking-management/booking-list" },
    { key: "advertiser-contract-management", label: "계약 관리", path: "/mypage/advertiser/contract-management/contract-list" },
    { key: "advertiser-interest-media", label: "관심 매체", path: "/mypage/advertiser/interest-media/interest-media-list" },
    { key: "advertiser-setting", label: "설정", path: "/mypage/advertiser/setting" },
];

export const mediaCompanyMenuItemsDataGroup = [{ key: "profile-media-company", label: "프로필(매체사)", path: "/mypage/media-company/profile" }];

// { key: "withdrawal", label: "회원탈퇴", path: "/mypage/membership-withdrawal" },

export const mypageUserName = "홍길동";

export const subLocationPathArray = {
    advertiser: [
        "/mypage/advertiser/profile",
        "/mypage/advertiser/partner-management",
        "/mypage/advertiser/campaign-management",
        "/mypage/advertiser/booking-management",
        "/mypage/advertiser/contract-management",
        "/mypage/advertiser/interest-media",
        "/mypage/advertiser/setting",
    ],
};
