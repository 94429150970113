import React, { useState } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import VcAntSwitch from "components/antd/data-entry/switch";
import VcAntCheckBox from "components/antd/data-entry/checkbox";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { settingCategorySwitchOption, generalUserSettingNoticeDataGroup, businessUserSettingNoticeDataGroup } from "data/mypage/setting";

import { VcAntSwitchTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserSetting({ className, style }: vcProps) {
    // 공지사항 Data Array
    const generalUserSwitchControlLabelArray = generalUserSettingNoticeDataGroup.categoryControlData.map((data: any, index: number) => data.switchLabel);
    const generalUserSwitchControlCheckedArray = generalUserSettingNoticeDataGroup.categoryControlData.map(
        (data: any, index: number) => data.switchCheckedValue
    );
    const businessUserSwitchControlLabelArray = businessUserSettingNoticeDataGroup.categoryControlData.map((data: any, index: number) => data.switchLabel);
    const businessUserSwitchControlCheckedArray = businessUserSettingNoticeDataGroup.categoryControlData.map(
        (data: any, index: number) => data.switchCheckedValue
    );

    const businessUserCheckboxLabelArray = businessUserSettingNoticeDataGroup.categoryControlData.map((data: any, index: number) => [
        data.checkboxEmailLabel + index,
        data.checkboxSmsLabel + index,
    ]);
    const businessUserCheckboxCheckedValueArray = businessUserSettingNoticeDataGroup.categoryControlData.map((data: any, index: number) => [
        data.checkboxEmailCheckedValue,
        data.checkboxSmsCheckedValue,
    ]);

    const businessUserCheckboxDefaultDisabledArray = businessUserSettingNoticeDataGroup.categoryControlData.map(
        (data: any, index: number) => data.defaultDisabled
    );

    const [generalUserNotificationSwitchState, setGeneralUserNotificationSwitchState] = useState<boolean[]>(generalUserSwitchControlCheckedArray);
    const [businessUserSwitchGroupState, setBusinessUserSwitchGroupState] = useState<boolean[]>(businessUserSwitchControlCheckedArray);

    const [businessUserCheckboxGroupState, setBusinessUserCheckboxGroupState] = useState<boolean[][]>(businessUserCheckboxCheckedValueArray);
    const [businessUserCheckboxGroupDisabledState, setBusinessUserCheckboxGroupDisabledState] = useState<boolean[]>(businessUserCheckboxDefaultDisabledArray);

    function generalUserNoticeSwitchOnChangeEvent(checked: boolean, event: any) {
        const isChecked = checked;
        const isLabel = event.currentTarget.dataset.label;
        const checkIndex = generalUserSwitchControlLabelArray.indexOf(isLabel);
        generalUserNotificationSwitchState[checkIndex] = isChecked;
        setGeneralUserNotificationSwitchState([...generalUserNotificationSwitchState]);
    }

    function businessUserSwitchGroupOnChangeEvent(checked: boolean, event: any) {
        const isChecked = checked;
        const isNotChecked = !checked;
        const isLabel = event.currentTarget.dataset.label;
        const checkIndex = businessUserSwitchControlLabelArray.indexOf(isLabel);
        businessUserSwitchGroupState[checkIndex] = isChecked;
        setBusinessUserSwitchGroupState([...businessUserSwitchGroupState]);
        businessUserCheckboxGroupDisabledState[checkIndex] = isNotChecked;
        setBusinessUserCheckboxGroupDisabledState([...businessUserCheckboxGroupDisabledState]);
    }

    function businessUserCheckboxGroupOnChangeEvent(event: any) {
        const isChecked = event.target.checked;
        const isLabel = event.target.id;
        const isValue = event.target.value;
        const checkIndex = businessUserCheckboxLabelArray[isValue].indexOf(isLabel);
        businessUserCheckboxGroupState[isValue][checkIndex] = isChecked;
        setBusinessUserCheckboxGroupState([...businessUserCheckboxGroupState]);
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="settingCategoryGroup">
                <Box className="setCategoryTitle">
                    <Typography>{businessUserSettingNoticeDataGroup.categoryTitle}</Typography>
                </Box>
                {generalUserSettingNoticeDataGroup.categoryControlData.map((data: any, index: number) => {
                    return (
                        <Box className="setCategoryLine" key={index}>
                            <Flex justify="space-between" align="center">
                                <Box className="setLeftBox" sx={{ flex: 1 }}>
                                    <Box className="titleBox">
                                        <Typography>{data.switchTitle}</Typography>
                                    </Box>
                                    <Box className="guideMessageBox">
                                        <Typography>{data.switchGuideMessage}</Typography>
                                    </Box>
                                </Box>
                                <Box className="setRightBox" sx={{ flexBasis: "content" }}>
                                    <Box className="switchBox">
                                        <VcAntSwitch
                                            checked={generalUserNotificationSwitchState[index]}
                                            checkedChildren={settingCategorySwitchOption[0].label}
                                            unCheckedChildren={settingCategorySwitchOption[1].label}
                                            onChange={generalUserNoticeSwitchOnChangeEvent}
                                            size="default"
                                            dataLabel={data.switchLabel}
                                        />
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    );
                })}
                {businessUserSettingNoticeDataGroup.categoryControlData.map((data: any, index: number) => {
                    return (
                        <Box className="setCategoryLine" key={index}>
                            <Flex justify="space-between" align="center">
                                <Box className="setLeftBox" sx={{ flex: 1 }}>
                                    <Box className="titleBox">
                                        <Typography>{data.switchTitle}</Typography>
                                    </Box>
                                    <Box className="guideMessageBox">
                                        <Typography>{data.switchGuideMessage}</Typography>
                                    </Box>
                                    <Box className="checkboxSwitchBox">
                                        <Flex justify="flex-start" align="center">
                                            <Box>
                                                <VcAntCheckBox
                                                    label={data.checkboxEmailLabel}
                                                    checked={businessUserCheckboxGroupState[index][0]}
                                                    onChange={businessUserCheckboxGroupOnChangeEvent}
                                                    value={index}
                                                    id={data.checkboxEmailLabel + index}
                                                    disabled={businessUserCheckboxGroupDisabledState[index]}
                                                />
                                            </Box>
                                            <Box>
                                                <VcAntCheckBox
                                                    label={data.checkboxSmsLabel}
                                                    checked={businessUserCheckboxGroupState[index][1]}
                                                    onChange={businessUserCheckboxGroupOnChangeEvent}
                                                    value={index}
                                                    id={data.checkboxSmsLabel + index}
                                                    disabled={businessUserCheckboxGroupDisabledState[index]}
                                                />
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Box>
                                <Box className="setRightBox" sx={{ flexBasis: "content" }}>
                                    <Box className="switchBox">
                                        <VcAntSwitch
                                            checked={businessUserSwitchGroupState[index]}
                                            checkedChildren={settingCategorySwitchOption[0].label}
                                            unCheckedChildren={settingCategorySwitchOption[1].label}
                                            onChange={businessUserSwitchGroupOnChangeEvent}
                                            size="default"
                                            dataLabel={data.switchLabel}
                                        />
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );

    const topTitleData = advertiserMenuItemsDataGroup[6].label;

    const setStyles = {
        "&": {},
        ...style,
    };

    return (
        <Box sx={setStyles}>
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default AdvertiserSetting;
