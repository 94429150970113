// Layout
import Error404 from "pages/error/404";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/*",
    element: <Error404 />,
};

export default MainRoutes;

// 2xx : Successful
// 3xx : Redirection
// 4xx : Client Error
// 5xx : Server Error

// 100 : Continue - 서버가 요청 헤더를 받았으며, 클라이언트는 요청 본문을 계속해서 전송.
// 101 : Switching protocols - 요청자가 서버에 프로토콜 전환을 요청.
// 103 : Checkpoint - 중단된 PUT 또는 POST 요청을 재개하는 데 사용 가능한 요청 제안에 사용.

// 200 : OK - 에러없이 전송 성공.
// 201 : Created - 요청이 이행되었으며 새로운 리소스가 생성.
// 202 : Accepted - 수락 요청이 처리 위해 승인되었지만, 처리가 완료 안 됨.
// 203 : Non-authoritative information - 요청이 성공적으로 처리되었지만, 다른 소스에서 온 정보를 반환.
// 204 : No content - 요청이 성공적으로 처리되었지만, 어떤 콘텐츠도 반환 안 함.
// 205 : Reset content - 요청이 성공적으로 처리되었지만 내용 반환 않으며, 요청자에게 문서보기 재설정 요구.
// 206 : Partial content - 클라이언트가 보낸 범위 헤더로 인해 서버가 리소스의 일부만 전송하는 경우

// 300 : Multiple choices - 링크 목록. 사용자는 링크를 선택하고 해당 위치로 이동 가능. 최대 5 개까지 가능
// 301 : Moved permanently - 요청페이지가 새 URL 주소로 영구적으로 이동됨.
// 302 : Moved temporarily - 요청페이지가 새 URL 주소로 일시적으로 이동됨.
// 303 : See other - 요청페이지를 다른 URL에서 찾을 수 있음.
// 304 : Not modified - 요청페이지가 마지막으로 요청 이후 수정된 적 없음.
// 305 : Use proxy (프록시 사용)
// 306 : Switch Proxy - 더 이상 사용 안 함.
// 307 : Temporary Redirect - 요청페이지가 일시적으로 새 URL 주소로 이동.
// 308 : Resume Incomplete - 재개 중단된 PUT 또는 POST 요청을 재개하기 위한 제안서에 사용.

// 400 : Bad request - 클라이언트의 잘못된 구문으로 처리 불가.
// 401 : Unauthorized - 요청이 정당하나 서버가 이에 대한 응답 거부 중. (요청자에 대한 인증 실패)
// 402 : Payment required - 나중 사용 위해 예약됨.
// 403 : Forbidden - 요청은 정당하나, 서버가 응답 거부. (= 접근거부된 문서를 요청)
// 404 : Not found - 현재는 요청한 페이지를 찾을 수 없지만, 나중엔 사용 가능할지도 모름.
// 405 : Method not allowed - 해당 페이지에서 지원 않는 요청 방법 사용해 페이지 요청.
// 406 : Not acceptable - 서버는 클라이언트가 수락하지 않는 응답만 생성 가능.
// 407 : Proxy authentication required - 프록시 인증 필요.
// 408 : Request timeout - 서버가 요청 대기 시간을 초과.
// 409 : Conflict - 요청이 충돌하여 요청 처리 완료 불가.
// 410 : Gone - 요청 페이지를 더 이상 사용 불가.
// 411 : Length required - "Content-Length (콘텐츠 길이)" 정의 안 된 요청이라, 서버가 요청 수락 거부.
// 412 : Precondition failed - 요청에 필요한 사전 조건이 서버에 의해 false로 평가됨.
// 413 : Request entity too large - 요청 크기가 너무 커서 서버 수락 불가.
// 414 : Request-URI too long - 요청 URL 길이가 너무 길어 서버 수락 불가. (예: post방식을 get방식 전환)
// 415 : Unsupported media type  - 지원 않는 미디어유형에 대한 요청이라서 서버 수락 불가.
// 416 : Requested Range Not Satisfiable- 파일의 일부 요청에 대해, 서버가 그 부분을 제공 불가.
// 417 : Expectation Failed - 서버가 예상 요청 헤더 필드의 요청사항을 충족할 수 없음.

// 500 : Internal server error - 내부적 서버 오류 (스크립트 오류로 인한, 일반적인 에러 메세지)
// 501 : Not implemented - 서버가 수행할 수 없는 방법으로 요청하거나, 그 요청을 처리할 능력 없음.
// 502 : Bad gateway - 서버가 게이트웨이/프록시 역할 중인데, 처리 서버로부터 잘못된 요청 받음.
// 503 : Service unavailable - 서버 현재 사용 불가 (과도한 데이터요청으로 서버 다운 상태)
// 504 : Gateway timeout - 처리서버로부터 제때 처리결과 못 받음. (해결책: Ctrl + F5 새로고침 / 나중 재접속)
// 505 : HTTP version not supported - 서버가 요청에 사용된 HTTP 프로토콜 버전 지원 안 함.
// 511 : Network Authentication Required - 요청차가 네트워크 접속에 필요한 인증 받아야 함.
