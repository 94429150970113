// Layout
import MainLayout from "layout/mainLayout";

import MypageIndex from "pages/mypage/index";
import MediaCompanyMypageLayout from "pages/mypage/mediaCompany/mypageLayout";

import MediaCompanyProfile from "pages/mypage/mediaCompany/profile";

// ==============================|| MEDIACOMPANY ROUTING ||============================== //

const MediaCompanyRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "mypage",
            element: <MypageIndex />,
            children: [
                {
                    path: "media-company",
                    element: <MediaCompanyMypageLayout />,
                    children: [
                        {
                            path: "profile",
                            element: <MediaCompanyProfile />,
                        },
                    ],
                },
            ],
        },
    ],
};

export default MediaCompanyRoutes;
