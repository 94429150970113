import React, { useEffect, useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Typography, Space, Divider, message } from "antd";
import { Box } from "@mui/material";

import ContentCard from "components/layout/contentCard";

import VcAntInput from "components/antd/data-entry/input";
import VcAntButton from "components/antd/general/button";

import { loginInputFormDataGroup, pageTitleData } from "data/authority/login";

import { VcAntButtonTheme } from "theme/component";

// import { useAuth } from "../../../hooks/useAuth"; // useAuth 훅 import
import { login } from "../../../services/authService"; // authService import

import Cookies from 'js-cookie'; // 추가된 부분

function Login() {
    const navigate = useNavigate();

    const [allDataArrayState, setAllDataArrayState] = useState<any[]>([]);
    const formRef = useRef<{ [key: string]: any }>({});
 
    useEffect(() => {
        const dataTitleArray = loginInputFormDataGroup.map((data: any, index: number) => data.title);
        const dataTitleArrayLength = dataTitleArray.length;
        const dataEmptyArray = [...Array(dataTitleArrayLength)];
        const allDataArray = [];
        for (let i = 0; i < dataTitleArrayLength; i++) {
            dataEmptyArray[i] = Array.from(dataTitleArray[i]);
            allDataArray[i] = { title: dataTitleArray[i], breakTitle: dataEmptyArray[i] };
        }
        setAllDataArrayState(allDataArray);
    }, [loginInputFormDataGroup]);

    async function loginSubmitEvent() {
        try {
            const id = formRef.current["id"];
            const password = formRef.current["password"];

            const response = await login(id, password);
            console.log(response[0].answer);
            if (response[0].answer === "SUCCESS") {
                Cookies.set('userId', id, { expires: 7, secure: true, sameSite: 'Strict' });

                const memberResponse = await fetch(`/api/?cat=member&req=select&item=${id}`);
                const memberData = await memberResponse.json();

                if (memberData && memberData.length > 0) {
                    const serial = memberData[0].serial;
                    // company API 호출 수정
                    const companyResponse = await fetch('/api/?cat=company&req=read', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            flag: 'my',
                            member_serial: serial
                        })
                    });
                    const companyData = await companyResponse.json();
                    
                    // serial 값을 쿠키에 저장
                    Cookies.set('serial', serial, { expires: 7, secure: true, sameSite: 'Strict' });
                    
                    // company 데이터가 있을 경우에만 company_serial 저장
                    if (companyData && companyData.length > 0) {
                        Cookies.set('company_serial', companyData[0].serial, { expires: 7, secure: true, sameSite: 'Strict' });
                        Cookies.set('authority', 'AdvertiserRoutes', { expires: 7, secure: true, sameSite: 'Strict' });
                    } else {
                        Cookies.set('authority', 'GeneralRoutes', { expires: 7, secure: true, sameSite: 'Strict' });
                    }
                } else {
                    message.error("회원 정보를 가져오는 데 실패했습니다.");
                    return;
                }

                message.success("로그인에 성공했습니다.");
                navigate("/dashboard");
            } else {
                message.error(response[0].answer);
            }
        } catch (error) {
            console.error("로그인 오류:", error);
            message.error("로그인 중 오류가 발생했습니다.");
        }
    }

    function naviJoinMemberPageEvent() {
        // console.log("회원가입");
        navigate("/authority/join-membership");
    }

    function findIdEvent() {
        // console.log("아이디 찾기");
        navigate("/authority/find-id");
    }
    function findPasswordEvent() {
        // console.log("비밀번호 찾기");
        navigate("/authority/find-pw");
    }

    const bodyContentData = (
        <Box className="loginBodyContent">
            <Box className="contentDataWrap">
                <Flex vertical justify="flex-start" align="center">
                    <Box className="inputFormBox">
                        {allDataArrayState.map((data: any, index: number) => {
                            const subBreakTitle = data.breakTitle;
                            return (
                                <Box className="inputForm" key={index}>
                                    <Box className="inputFormInner">
                                        <Flex justify="space-between" align="center">
                                            <Box className="inputLabelBox">
                                                <Flex justify="space-between" align="center">
                                                    {subBreakTitle.map((data: any, index: number) => {
                                                        return <Typography key={index}>{data}</Typography>;
                                                    })}
                                                </Flex>
                                            </Box>
                                            <Box className="inputBox">
                                                <VcAntInput
                                                    id={loginInputFormDataGroup[index].id}
                                                    chooseType={loginInputFormDataGroup[index].inputType}
                                                    placeholder={loginInputFormDataGroup[index].placeholder}
                                                    size="large"
                                                    variant="borderless"
                                                    allowClear={loginInputFormDataGroup[index].allowClear}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        formRef.current[loginInputFormDataGroup[index].id] = e.target.value;
                                                    }}
                                                />
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box className="buttonGroupBox">
                        <Box className="buttonBox">
                            <VcAntButton
                                conceptType="default"
                                buttonType="default"
                                onClick={loginSubmitEvent}
                                buttonTitle="로그인"
                                buttonSize="large"
                                fullWidth="100%"
                            />
                        </Box>
                        <Box className="buttonBox">
                            <VcAntButton
                                conceptType="clear"
                                buttonType="default"
                                onClick={naviJoinMemberPageEvent}
                                buttonTitle="회원가입"
                                buttonSize="large"
                                fullWidth="100%"
                            />
                        </Box>
                    </Box>
                    <Box className="subButtonGroupBox">
                        <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                            <Box className="findIdBox">
                                <VcAntButton
                                    conceptType="default"
                                    buttonType="text"
                                    onClick={findIdEvent}
                                    buttonTitle="아이디 찾기"
                                    buttonSize="small"
                                    buttonStyle={{ color: VcAntButtonTheme.fontColorWhite }}
                                />
                            </Box>
                            <Box className="findPasswordBox">
                                <VcAntButton
                                    conceptType="default"
                                    buttonType="text"
                                    onClick={findPasswordEvent}
                                    buttonTitle="비밀번호 찾기"
                                    buttonSize="small"
                                    buttonStyle={{ color: VcAntButtonTheme.fontColorWhite }}
                                />
                            </Box>
                        </Space>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );



    return (
        <Box className="loginWrap">
            {/* 로그인 */}
            <ContentCard topTitle={pageTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default Login;
