import { useOutletContext } from "react-router-dom";

import { Box } from "@mui/material";

import AdministrativeDistrict from "./AdministrativeDistrict";
import VcAdTgCheckCpnt from "./AdTargetCheckComp";

import AdvertisingTableLayout from "pages/advertiser/advertising/tableLayout";

import { administrativeCategoryData, checkButtonCategoryDataGroup } from "data/advertiser/advertising/target";

function AdvertisingTarget() {
    const targetDataArray: any[] = [];
    targetDataArray.push({
        categoryTitle: administrativeCategoryData[0].title,
        categoryData: <AdministrativeDistrict gridType="span" gridSize={12} selectSize="large" />,
    });
    checkButtonCategoryDataGroup.forEach((element) => {
        targetDataArray.push({
            categoryTitle: element.title,
            categoryData: <VcAdTgCheckCpnt itemsData={element.option} gridType={element.gridType} gridSize={element.gridSize} buttonSize="large" />,
        });
    });

    return (
        <Box className="targetDataWrap">
            <AdvertisingTableLayout tableData={targetDataArray} />
        </Box>
    );
}

export default AdvertisingTarget;
