import { useState } from 'react';
import { createMember } from '../services/memberService';

export const useMemberRegistration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const registerMember = async (memberData: any) => {
    setIsLoading(true);
    setError(null);
    const result = await createMember(memberData);
    setIsLoading(false);
    return result;
  };

  return { registerMember, isLoading, error };
};