// Layout
import MainLayout from "layout/mainLayout";

import MypageIndex from "pages/mypage/index";
import AdvertiserMypageLayout from "pages/mypage/advertiser/mypageLayout";

import AdvertiserProfile from "pages/mypage/advertiser/profile";

import AdvertiserPartnerList from "pages/mypage/advertiser/partner/list";
import AdvertiserPartnerDetail from "pages/mypage/advertiser/partner/detail";
import AdvertiserPartnerInformation from "pages/mypage/advertiser/partner/information";
import AdvertiserPartnerEdit from "pages/mypage/advertiser/partner/edit";
import AdvertiserPartnerRegister from "pages/mypage/advertiser/partner/register";
import AdvertiserPartnerRegisterCampaign from "pages/mypage/advertiser/partner/register/campaign";
import AdvertiserPartnerRegisterComplete from "pages/mypage/advertiser/partner/register/complete";

import AdvertiserCampaignList from "pages/mypage/advertiser/campaign/list";
import AdvertiserCampaignDetail from "pages/mypage/advertiser/campaign/detail";
import AdvertiserCampaignEdit from "pages/mypage/advertiser/campaign/edit";
import AdvertiserCampaignRegister from "pages/mypage/advertiser/campaign/register";

import AdvertiserBookingList from "pages/mypage/advertiser/booking/list";
import AdvertiserBookingDetail from "pages/mypage/advertiser/booking/detail";
import AdvertiserBookingRequestMediaResult from "pages/mypage/advertiser/booking/result";

import AdvertiserContractList from "pages/mypage/advertiser/contract/list";
import AdvertiserContractDetail from "pages/mypage/advertiser/contract/detail";

import AdvertiserInterestMediaList from "pages/mypage/advertiser/interestMedia/list";

import AdvertiserSetting from "pages/mypage/advertiser/setting";

import Advertising from "pages/advertiser/advertising/adMain";
import AdvertisingTarget from "pages/advertiser/advertising/adTarget";
import AdvertisingKPI from "pages/advertiser/advertising/kpi";
import AdvertisingCampaign from "pages/advertiser/advertising/campaign";
import AdvertisingAiLoading from "pages/advertiser/advertising/aiLoading";
import AdvertisingMedia from "pages/advertiser/advertising/media";
import AdvertisingResult from "pages/advertiser/advertising/result";

// ==============================|| ADVERTISER ROUTING ||============================== //

const AdvertiserRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "mypage",
            element: <MypageIndex />,
            children: [
                {
                    path: "advertiser",
                    element: <AdvertiserMypageLayout />,
                    children: [
                        {
                            path: "profile",
                            element: <AdvertiserProfile />,
                        },
                        {
                            path: "partner-management",
                            children: [
                                {
                                    path: "partner-list",
                                    element: <AdvertiserPartnerList />,
                                },
                                {
                                    path: "partner-name",
                                    children: [
                                        {
                                            path: "detail",
                                            element: <AdvertiserPartnerDetail />,
                                        },
                                        {
                                            path: "partner-information",
                                            element: <AdvertiserPartnerInformation />,
                                        },
                                        {
                                            path: "edit",
                                            element: <AdvertiserPartnerEdit />,
                                        },
                                    ],
                                },
                                {
                                    path: "add-partner",
                                    children: [
                                        {
                                            path: "profile",
                                            element: <AdvertiserPartnerRegister />,
                                        },
                                        {
                                            path: "campaign",
                                            element: <AdvertiserPartnerRegisterCampaign />,
                                        },
                                        {
                                            path: "complete",
                                            element: <AdvertiserPartnerRegisterComplete />,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "campaign-management",
                            children: [
                                {
                                    path: "campaign-list",
                                    element: <AdvertiserCampaignList />,
                                },
                                {
                                    path: "campaign-name",
                                    children: [
                                        {
                                            path: "detail",
                                            element: <AdvertiserCampaignDetail />,
                                        },
                                        {
                                            path: "edit",
                                            element: <AdvertiserCampaignEdit />,
                                        },
                                    ],
                                },
                                {
                                    path: "add-campaign",
                                    element: <AdvertiserCampaignRegister />,
                                },
                            ],
                        },
                        {
                            path: "booking-management",
                            children: [
                                {
                                    path: "booking-list",
                                    element: <AdvertiserBookingList />,
                                },
                                {
                                    path: "booking-campaign-name",
                                    children: [
                                        {
                                            path: "detail",
                                            element: <AdvertiserBookingDetail />,
                                        },
                                        {
                                            path: "booking-request-media-name",
                                            children: [
                                                {
                                                    path: "result",
                                                    element: <AdvertiserBookingRequestMediaResult />,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "contract-management",
                            children: [
                                {
                                    path: "contract-list",
                                    element: <AdvertiserContractList />,
                                },
                                {
                                    path: "contract-media-name",
                                    children: [
                                        {
                                            path: "detail",
                                            element: <AdvertiserContractDetail />,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "interest-media",
                            children: [
                                {
                                    path: "interest-media-list",
                                    element: <AdvertiserInterestMediaList />,
                                },
                            ],
                        },
                        {
                            path: "setting",
                            element: <AdvertiserSetting />,
                        },
                    ],
                },
            ],
        },
        // 광고하기
        {
            path: "mypage/advertiser/campaign-management",
            children: [
                {
                    path: "advertising",
                    element: <Advertising />,
                    children: [
                        {
                            path: "target",
                            element: <AdvertisingTarget />,
                        },
                        {
                            path: "kpi",
                            element: <AdvertisingKPI />,
                        },
                        {
                            path: "campaign",
                            element: <AdvertisingCampaign />,
                        },
                        {
                            path: "ai-loading",
                            element: <AdvertisingAiLoading />,
                        },
                        {
                            path: "media-list",
                            element: <AdvertisingMedia />,
                        },
                        {
                            path: "select-media-name",
                            children: [
                                {
                                    path: "booking-request-complete",
                                    element: <AdvertisingResult />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

export default AdvertiserRoutes;
