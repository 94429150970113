import React from "react";
import { Checkbox } from "antd";

import { Box } from "@mui/material";

import { VcAntCheckboxTheme } from "theme/component";

interface vcProps {
    conceptType?: "default" | "group" | undefined;
    className?: string;
    style?: React.CSSProperties | undefined;
    autoFocus?: boolean;
    checked?: boolean;
    defaultChecked?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    onChange?: any;
    label?: string;
    value?: any;
    id?: any;
    // group
    groupDefaultValue?: (string | number)[];
    groupDisabled?: boolean;
    groupName?: string;
    groupOptions?: any[];
    groupValue?: (string | number | boolean)[];
    groupOnChange?: any;
}

function VcAntCheckBox({
    conceptType,
    className,
    style,
    autoFocus,
    checked,
    defaultChecked,
    disabled,
    indeterminate,
    onChange,
    label,
    value,
    id,
    // group,
    groupDefaultValue,
    groupDisabled,
    groupName,
    groupOptions,
    groupValue,
    groupOnChange,
}: vcProps) {
    const VcAntCheckboxSetStyles = {
        "&": {
            "& .ant-checkbox-wrapper": {
                "& .ant-checkbox+span": {
                    color: VcAntCheckboxTheme.fontColorWhite,
                },
                // default 기본 - 상태 false
                "& .ant-checkbox": {
                    "& .ant-checkbox-input": {},
                    "& .ant-checkbox-inner": {
                        backgroundColor: VcAntCheckboxTheme.backgroundColorDefault,
                        borderColor: VcAntCheckboxTheme.borderColor,
                        "&::after": {},
                    },
                },
                // checked 액티브 - 상태 true
                "& .ant-checkbox.ant-checkbox-checked": {
                    "& .ant-checkbox-input": {},
                    "& .ant-checkbox-inner": {
                        backgroundColor: VcAntCheckboxTheme.backgroundColorChecked,
                        borderColor: VcAntCheckboxTheme.borderColor,
                        "&::after": {
                            borderColor: VcAntCheckboxTheme.borderColor,
                        },
                    },
                },
            },
            // default 기본 - hover 시
            "& .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner":
                {
                    backgroundColor: VcAntCheckboxTheme.backgroundColorDefaultHover,
                    borderColor: VcAntCheckboxTheme.borderColor,
                },
            // checked 액티브 - hover 시
            "& .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner": {
                backgroundColor: VcAntCheckboxTheme.backgroundColorCheckedHover,
                borderColor: VcAntCheckboxTheme.borderColor,
            },
            // disabled 비활성화
            "& .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled": {
                "& .ant-checkbox-disabled+span": {
                    color: VcAntCheckboxTheme.disabledFontColorWhite,
                },
                "& .ant-checkbox.ant-checkbox-disabled": {
                    "& .ant-checkbox-input": {},
                    "& .ant-checkbox-inner": {
                        backgroundColor: VcAntCheckboxTheme.disabledBackgroundColor,
                        borderColor: VcAntCheckboxTheme.disabledBorderColor,
                        "&::after": {
                            borderColor: VcAntCheckboxTheme.disabledBorderColor,
                        },
                    },
                },
            },
        },
        ...style,
    };

    switch (conceptType) {
        case "group":
            return (
                <Box className="vcAntCheckbox" sx={VcAntCheckboxSetStyles}>
                    <Checkbox.Group
                        defaultValue={groupDefaultValue}
                        disabled={groupDisabled}
                        name={groupName}
                        options={groupOptions}
                        value={groupValue}
                        onChange={groupOnChange}
                    />
                </Box>
            );
        case "default":
            return (
                <Box className="vcAntCheckbox" sx={VcAntCheckboxSetStyles}>
                    <Checkbox
                        autoFocus={autoFocus}
                        checked={checked}
                        defaultChecked={defaultChecked}
                        disabled={disabled}
                        indeterminate={indeterminate}
                        onChange={onChange}
                        value={value}
                        id={id}
                    >
                        {label}
                    </Checkbox>
                </Box>
            );
        default:
            return (
                <Box className="vcAntCheckbox" sx={VcAntCheckboxSetStyles}>
                    <Checkbox
                        autoFocus={autoFocus}
                        checked={checked}
                        defaultChecked={defaultChecked}
                        disabled={disabled}
                        indeterminate={indeterminate}
                        onChange={onChange}
                        value={value}
                        id={id}
                    >
                        {label}
                    </Checkbox>
                </Box>
            );
    }
}

export default VcAntCheckBox;
