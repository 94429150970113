export const partnerListDataGroup = [
    {
        companyImgPath: "https://picsum.photos/200/200",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/201/201",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/202/202",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/203/203",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/204/204",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/205/205",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/206/206",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/207/207",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/208/208",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/209/209",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/210/210",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
    {
        companyImgPath: "https://picsum.photos/211/211",
        companyName: "파트너사명",
        companyAddress: "서울특별시 서초구 강남대로 331",
        companyTel: "010-1234-5678",
        campaignInformation: "캠페인명 샬라샬라 외 3",
    },
];
