import React, { useState } from "react";

import { Tabs, Button, Flex } from "antd";
import {
    PlusOutlined,
    MinusOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";

import { Box } from "@mui/material";

import AdvertisingTableLayout from "pages/advertiser/advertising/tableLayout";

import VcAntSelect from "components/antd/data-entry/select";
import VcAntInput from "components/antd/data-entry/input";
import VcAntTextArea from "components/antd/data-entry/textarea";
import VcAntRadio from "components/antd/data-entry/radio";
import VcAntButton from "components/antd/general/button";

import {
    campaignRadioOptionData,
    campaignTabsItemsData,
    campaignControlDataGroup,
    existingCampaignDataGroup,
    newCampaignDataGroup,
    newCampaignMaterialDataGroup,
} from "data/advertiser/advertising/campaign";

import type { TabsProps } from "antd";
import type { RadioChangeEvent } from "antd";

function AdvertisingCampaign() {
    const [radioSelectState, setRadioSelectState] = useState("existing");

    const radioOnChangeEvent = (event: RadioChangeEvent) => {
        setRadioSelectState(event.target.value);
    };

    const tabsOnChangeEvent = (key: string) => {
        setRadioSelectState(key);
    };

    function selectOnChangeEvent(value: string) {
        console.log(`selected ${value}`);
    }

    const campaignControlDataArray: any[] = [];
    campaignControlDataArray.push({
        categoryTitle: campaignControlDataGroup.title,
        categoryData: (
            <Box className="campaignRadioBox">
                <VcAntRadio
                    onChange={radioOnChangeEvent}
                    value={radioSelectState}
                    options={campaignRadioOptionData}
                    optionType="button"
                />
            </Box>
        ),
    });

    // 기존 캠페인
    const existingCampaignDataArray: any[] = [];
    existingCampaignDataArray.push({
        categoryTitle: existingCampaignDataGroup[0].title,
        categoryData: (
            <Box className="existingCampaignBox">
                <VcAntSelect
                    className="advertisingCampaignSelect"
                    placeholder={existingCampaignDataGroup[0].placeholder}
                    onChange={selectOnChangeEvent}
                    options={existingCampaignDataGroup[0].option}
                    dropdownStyle={{ textAlign: "center" }}
                    size="large"
                    suffixIcon={<CaretDownOutlined />}
                    fullWidth="100%"
                />
            </Box>
        ),
    });

    // 신규 캠페인
    const newCampaignDataArray: any[] = [];
    newCampaignDataArray.push(
        {
            categoryTitle: newCampaignDataGroup[0].title,
            categoryData: (
                <Box className="newCampaignBox">
                    <Box className="newCampaignLine">
                        <VcAntInput
                            chooseType="basic"
                            placeholder={newCampaignDataGroup[0].placeholder}
                            size="large"
                        />
                    </Box>
                </Box>
            ),
        },
        {
            categoryTitle: newCampaignDataGroup[1].title,
            categoryData: (
                <Box className="newCampaignContent">
                    <Box className="newCampaignLine">
                        <VcAntTextArea
                            rows={4}
                            placeholder={newCampaignDataGroup[1].placeholder}
                            resize="none"
                            size="large"
                        />
                    </Box>
                </Box>
            ),
        },
        {
            categoryTitle: newCampaignDataGroup[2].title,
            categoryData: (
                <Box className="newCampaignMaterialWrap">
                    {newCampaignMaterialDataGroup.data.map(
                        (data: any, index: number) => {
                            return (
                                <Box
                                    className="newCampaignMaterialBox"
                                    key={index}
                                >
                                    <Box className="newCampaignMaterial">
                                        <Box className="newCampaignLine">
                                            <Flex
                                                justify="space-between"
                                                align="center"
                                            >
                                                <Box style={{ flex: 1 }}>
                                                    <VcAntInput
                                                        chooseType="basic"
                                                        placeholder={
                                                            newCampaignMaterialDataGroup
                                                                .commonPlaceholder
                                                                .title
                                                        }
                                                        size="large"
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box className="newCampaignLine">
                                            <VcAntInput
                                                chooseType="basic"
                                                placeholder={
                                                    newCampaignMaterialDataGroup
                                                        .commonPlaceholder.url
                                                }
                                                size="large"
                                            />
                                        </Box>
                                        <Box className="newCampaignLine">
                                            <VcAntTextArea
                                                rows={4}
                                                placeholder={
                                                    newCampaignMaterialDataGroup
                                                        .commonPlaceholder
                                                        .content
                                                }
                                                resize="none"
                                                size="large"
                                            />
                                        </Box>
                                        <Box className="minusButtonBox">
                                            <VcAntButton
                                                shape="circle"
                                                icon={<MinusOutlined />}
                                                buttonSize="small"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        }
                    )}
                    <Box sx={{ paddingTop: "16px" }}>
                        <Flex justify="center" align="center">
                            <VcAntButton
                                shape="circle"
                                icon={<PlusOutlined />}
                            />
                        </Flex>
                    </Box>
                </Box>
            ),
        }
    );

    const items: TabsProps["items"] = [
        {
            key: campaignTabsItemsData[0].key,
            label: campaignTabsItemsData[0].label,
            children: (
                <AdvertisingTableLayout tableData={existingCampaignDataArray} />
            ),
        },
        {
            key: campaignTabsItemsData[1].key,
            label: campaignTabsItemsData[1].label,
            children: (
                <AdvertisingTableLayout tableData={newCampaignDataArray} />
            ),
        },
    ];

    return (
        <Box className="campaignDataWrap">
            <AdvertisingTableLayout tableData={campaignControlDataArray} />
            <Tabs
                items={items}
                defaultActiveKey="1"
                activeKey={radioSelectState}
                onChange={tabsOnChangeEvent}
            />
        </Box>
    );
}

export default AdvertisingCampaign;
