import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import VcAntPaginationList from "components/custom/paginationList";
import AdvertiserPartnerListLayout from "./layout";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { partnerListDataGroup } from "data/mypage/advertiser/partner/list";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserPartnerList({ className, style }: vcProps) {
    const navigate = useNavigate();

    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentPageState, setCurrentPageState] = useState<number>(1);
    const [postsPerPageState, setPostsPerPageState] = useState<number>(10);

    useEffect(() => {
        setPostsState(partnerListDataGroup);
    }, []);

    function addAccountOnClickEvent() {
        // console.log("계정 추가 이벤트!");
        navigate("/mypage/advertiser/partner-management/add-partner/profile");
    }

    const finalData: any[] = [];
    partnerListDataGroup.forEach((element, number) => {
        finalData.push(
            <Box>
                <AdvertiserPartnerListLayout imgPath={element.companyImgPath} titleData={element.companyName} description={element.campaignInformation} />
            </Box>
        );
    });

    const firstPostIndex = (currentPageState - 1) * postsPerPageState;
    const lastPostIndex = firstPostIndex + postsPerPageState;
    const currentPosts = finalData.slice(firstPostIndex, lastPostIndex);

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="listInformationBox">
                <Flex justify="space-between" align="flex-end">
                    <Box className="listCountBox">
                        <Typography>Total {postsState.length} accounts</Typography>
                    </Box>
                    <Box>
                        <VcAntButton buttonTitle="계정 추가" onClick={addAccountOnClickEvent} icon={<PlusOutlined />} iconPosition="end" />
                    </Box>
                </Flex>
            </Box>
            <VcAntPaginationList
                listOptionData={currentPosts}
                postsItemCount={postsState.length}
                currentPage={currentPageState}
                setCurrentPage={setCurrentPageState}
                postsPerPage={postsPerPageState}
                itemGap={true}
            />
        </Box>
    );

    const topTitleData = advertiserMenuItemsDataGroup[1].label;

    return (
        <Box className="advertiserPartnerListWrap">
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default AdvertiserPartnerList;
