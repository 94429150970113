import { colorGroup } from "./palette";
import { defaultStyleGroup } from "./measurement";

const commonBorderRadiusDefault = defaultStyleGroup.border.radius.default;
const commonBorderRadiusFull = defaultStyleGroup.border.radius.full;

// 특정 페이지

// 광고주 - 광고하기 - 타겟 - Select
export const administrativeSelectTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColorFull: colorGroup.realColor.white.main[100],
    backgroundColorHover: colorGroup.realColor.white.main[80],
    borderColor: colorGroup.realColor.white.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
};

// 광고주 - 광고하기 - 타겟 - button(Checkbox)
export const VcAdButtonCheckBoxTheme = {
    backgroundColorClear: colorGroup.realColor.clear,
    backgroundColorFull: colorGroup.realColor.white.main[100],
    backgroundColorHover: colorGroup.realColor.white.main[80],
    borderColor: colorGroup.realColor.white.main[100],
    borderRadius: commonBorderRadiusDefault,
    fontColorWhite: colorGroup.realColor.white.main[100],
    fontColorBlack: colorGroup.realColor.black.main[100],
};
