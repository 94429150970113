import { classListInfoDataGroup } from "./class";

export const knowledgeServiceClassDetailInfoTitleGroup = {
    classPeriodCount: "교육일수",
    classProgramCount: "강의수",
    teacherName: "교수명",
    subjectName: "과목",
    classType: "강의유형",
};

export const knowledgeServiceClassDetailInfoValueGroup = {
    teacherNameValue: "김교수",
    subjectNameValue: "구분없음",
    classTypeValue: "이론강의",
};

export const classProgramIntroduceDataGroup = [
    {
        name: classListInfoDataGroup[0].className,
        content: [
            {
                thead: "강좌 소개",
                tbody: "Elit ut occaecat ullamco dolore tempor in eiusmod aute labore aliqua. Laboris est ut ullamco ex tempor anim labore laboris ipsum culpa officia proident dolor fugiat. Aliqua sit irure fugiat velit proident qui nostrud tempor commodo nisi. Consequat voluptate aliqua dolore non dolor voluptate nostrud irure cupidatat non ut deserunt. Nostrud do ut magna labore. Non aute excepteur veniam adipisicing sunt ut labore deserunt et sit aute.",
            },
            {
                thead: "수강 대상",
                tbody: "Anim occaecat sint nostrud duis consectetur in sunt minim Lorem. Veniam eu quis quis magna ullamco duis nostrud adipisicing ad eiusmod voluptate id minim pariatur. Eu in sunt pariatur mollit adipisicing amet commodo qui laboris ullamco. Ea ullamco id veniam fugiat amet tempor commodo fugiat enim mollit.",
            },
            {
                thead: "내용 및 특징",
                tbody: "Eu esse do quis ex sunt reprehenderit excepteur occaecat in officia sint non excepteur. Ullamco consequat exercitation proident dolore do elit consectetur officia enim. Id occaecat commodo ex et. Labore id amet enim enim ea laboris deserunt quis duis consequat. Mollit nostrud ut minim sint qui aliquip nostrud pariatur aliqua quis. Sit consequat non anim ut laboris laborum occaecat deserunt ex duis.",
            },
        ],
    },
];

export const classProgramSequence = [
    {
        name: classListInfoDataGroup[0].className,
        content: {
            thead: { col1: "학습내용", col2: "시간" },
            tbody: [
                { col1: "1강. 광고란 무엇인가 step1-1", col2: "20분" },
                { col1: "2강. 광고란 무엇인가 step1-2", col2: "16분" },
                { col1: "3강. 광고란 무엇인가 step1-3", col2: "14분" },
                { col1: "4강. 광고란 무엇인가 step1-4", col2: "13분" },
                { col1: "5강. 광고란 무엇인가 step2-1", col2: "17분" },
                { col1: "6강. 광고란 무엇인가 step2-2", col2: "5분" },
                { col1: "7강. 광고란 무엇인가 step2-3", col2: "16분" },
                { col1: "8강. 광고란 무엇인가 step3-1", col2: "11분" },
                { col1: "9강. 광고란 무엇인가 step3-2", col2: "13분" },
                { col1: "10강. 광고란 무엇인가 step3-3", col2: "12분" },
                { col1: "11강. 광고란 무엇인가 step4-1", col2: "16분" },
                { col1: "12강. 광고란 무엇인가 step4-2", col2: "9분" },
            ],
        },
    },
];
