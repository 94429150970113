export const pageTitleData = {
    findId: "아이디 찾기",
    findPassword: "비밀번호 찾기",
};

export const controlButtonDataGroup = [
    { label: "아이디 찾기", value: "findid" },
    { label: "비밀번호 찾기", value: "findpw" },
];

// 공통
export const formDataGroup = {
    id: {
        title: "아이디",
        placeholder: "아이디를 입력해주세요",
    },
    phone: {
        title: "휴대폰",
        option: [
            { label: "010", value: "010" },
            { label: "011", value: "011" },
            { label: "016", value: "016" },
            { label: "017", value: "017" },
            { label: "019", value: "019" },
        ],
    },
};

// 아이디 찾기
export const findIdGuideMessageDataGroup = {
    message: "가입 시 등록한 휴대폰 번호를 입력해주세요",
};

export const findIdModalDataGroup = {
    modalTitle: "아이디 찾기 결과",
    notFound: {
        message: `입력하신 정보와 일치하는 아이디가 없습니다
정보를 다시 확인하시고 시도해주세요`,
    },
    foundResult: {
        message: `입력하신 정보와 일치하는 아이디는 다음과 같습니다
개인정보 보호를 위해 일부분은 * 으로 표시됩니다`,
        resultDataId: "abcb****",
    },
};

// 비밀번호 찾기
export const findPwGuideMessageDataGroup = {
    message: `가입 시 등록한 아이디와
휴대폰 번호를 입력해주세요`,
};

export const findPwModalDataGroup = {
    modalTitle: "비밀번호 찾기 결과",
    notFound: {
        message: `입력하신 정보와 일치하는 회원정보가 없습니다
        정보를 다시 확인하시고 시도해주세요`,
    },
    foundResult: {
        message: `회원정보가 확인되었습니다
변경하실 비밀번호를 입력해주세요`,
        inputProp: {
            changePwInput: {
                label: "변경 비밀번호",
                placeHolder: "현재 비밀번호를 입력해주세요",
            },
            changePwReInput: {
                label: "변경 비밀번호 확인",
                placeHolder: "변경할 비밀번호를 재입력해주세요",
            },
        },
    },
};
