import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Row, Col, Typography } from "antd";
import { CameraOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionViewControl from "pages/mypage/common/sectionViewControl";

import notLoadingImage from "assets/images/icon/not_loading_image.svg";

import { partnerInformationDataGroup } from "data/mypage/advertiser/partner/information";

function AdvertiserPartnerInformation() {
    const navigate = useNavigate();

    function backHistoryOnClickEvent() {
        navigate(-1);
    }

    function modifyButtonEvent() {
        // console.log("수정하기");
        navigate("/mypage/advertiser/partner-management/partner-name/edit");
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Row gutter={[16, 16]} justify="center" align="middle">
                <Col span={4}>
                    <Box className="categoryTitleBox">
                        <Box className="categoryTitleBoxInner">
                            <Typography>회사 로고</Typography>
                        </Box>
                    </Box>
                </Col>
                <Col span={20}>
                    <Box className="categoryDataBox">
                        <Box className="partnerLogoImgBox">
                            <Box className="imageViewBox">
                                <Box className="imageViewBoxInner">
                                    <img
                                        src="https://picsum.photos/200/200"
                                        alt=""
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Col>
            </Row>
            <SectionViewControl
                categoryViewData={partnerInformationDataGroup}
            />
        </Box>
    );

    return (
        <Box className="advertiserPartnerInformationWrap">
            <MypageSectionCard
                topTitle="파트너사 정보"
                bodyContent={bodyContentData}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="space-between" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={backHistoryOnClickEvent}
                            buttonTitle="이전으로"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifyButtonEvent}
                            buttonTitle="수정하기"
                            buttonSize="middle"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserPartnerInformation;
