export const AdministrativeCityName = ["서울특별시", "경기도"];
export const AdministrativeCityData = {
    서울특별시: ["강남구", "서초구"],
    경기도: ["지역1", "지역2", "지역3"],
};

export const administrativeCategoryData = [
    {
        title: "위치",
        data: "",
    },
];

export const checkButtonCategoryDataGroup = [
    {
        title: "장소",
        option: [
            { label: "번화가", checkedValue: false },
            { label: "대학가", checkedValue: false },
            { label: "사무단지", checkedValue: false },
            { label: "도로", checkedValue: false },
        ],
        gridType: "flex",
        gridSize: "auto",
    },
    {
        title: "성별",
        option: [
            { label: "남성", checkedValue: false },
            { label: "여성", checkedValue: false },
        ],
        gridType: "flex",
        gridSize: "auto",
    },
    {
        title: "연령대",
        option: [
            { label: "10대", checkedValue: false },
            { label: "20대", checkedValue: false },
            { label: "30대", checkedValue: false },
            { label: "40대", checkedValue: false },
            { label: "50대", checkedValue: false },
            { label: "60대 이상", checkedValue: false },
        ],
        gridType: "flex",
        gridSize: "auto",
    },
    {
        title: "예산(월)",
        option: [
            { label: "1억 이하", checkedValue: false },
            { label: "1~5억", checkedValue: false },
            { label: "5~10억", checkedValue: false },
            { label: "10~30억", checkedValue: false },
            { label: "30~50억", checkedValue: false },
            { label: "50억 이상", checkedValue: false },
        ],
        gridType: "flex",
        gridSize: "auto",
    },
    {
        title: "광고카테고리",
        option: [
            { label: "카테고리명1", checkedValue: false },
            { label: "카테고리명2", checkedValue: false },
            { label: "카테고리명3", checkedValue: false },
            { label: "카테고리명4", checkedValue: false },
            { label: "카테고리명5", checkedValue: false },
            { label: "카테고리명6", checkedValue: false },
            { label: "카테고리명7", checkedValue: false },
            { label: "카테고리명8", checkedValue: false },
        ],
        gridType: "span",
        gridSize: 6,
    },
];
