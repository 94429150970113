import { useState, useEffect } from 'react';
import { fetchLectureDetail } from '../services/knowledgeService';

export interface LectureContent { // export 추가
    serial: string;
    lecture_serial: string;
    sequence: string;
    content: string;
    page: string;
}

export interface LectureDetail { // export 추가
    serial: string;
    professor_serial: string;
    professor_name: string;
    subject_serial: string;
    content: string;
    title: string;
    period: string;
    price: string;
    subject_name: string;
    lecture_type: string;
    description: string;
    target: string;
    characteristic: string;
    lecture_content: LectureContent[];
}

export const useLectureDetail = (lectureId: number) => {
    const [data, setData] = useState<LectureDetail | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (lectureId === 0) return; // lectureId가 null이면 useEffect를 실행하지 않음

        const fetchData = async () => {
            try {
                console.log("Hook 호출:"+lectureId);
                const result = await fetchLectureDetail(lectureId);
                setData(result as LectureDetail);
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [lectureId]);

    return { data, loading, error };
};