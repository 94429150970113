import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Row, Col, Typography } from "antd";
import { CameraOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";

import notLoadingImage from "assets/images/icon/not_loading_image.svg";

import { partnerRegisterDataGroup } from "data/mypage/advertiser/partner/register";

function AdvertiserPartnerRegister() {
    const navigate = useNavigate();

    const subButtonEventArray = [
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
    ];

    function modifyButtonEvent() {
        navigate("/mypage/advertiser/partner-management/add-partner/campaign");
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Row gutter={[16, 16]} justify="center" align="middle">
                <Col span={4}>
                    <Box className="categoryTitleBox">
                        <Box className="categoryTitleBoxInner">
                            <Typography>회사 로고</Typography>
                        </Box>
                    </Box>
                </Col>
                <Col span={20}>
                    <Box className="categoryDataBox">
                        <Box className="partnerLogoImgBox">
                            <Box
                                className="imageViewBox"
                                sx={{
                                    backgroundImage: `url(${notLoadingImage})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "28px",
                                    backgroundPosition: "center center",
                                }}
                            >
                                <Box className="imageViewBoxInner">
                                    {/* 이미지 영역 */}
                                </Box>
                            </Box>
                            <Box className="uploadImgButtonBox">
                                <VcAntButton
                                    icon={<CameraOutlined />}
                                    conceptType="concept"
                                    onClick={() => {
                                        console.log("사진 변경!");
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Col>
            </Row>
            <SectionFormControl
                categoryInputData={partnerRegisterDataGroup}
                subButtonEventData={subButtonEventArray}
            />
        </Box>
    );

    return (
        <Box className="advertiserPartnerRegisterWrap">
            <MypageSectionCard
                topTitle="파트너사 등록 - 회사 정보"
                bodyContent={bodyContentData}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifyButtonEvent}
                            buttonTitle="다음"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserPartnerRegister;
