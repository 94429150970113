import axios from "axios";

interface FindIdResponse {
  answer: string;
  id: string;
}

const API_URL = '/api/';
export const findIdByPhoneNumber = async (phoneNumber: string): Promise<FindIdResponse[]> => {
  try {
      const response = await axios.post(`${API_URL}?cat=member&req=getid`,{hp:phoneNumber});
      return response.data;
  } catch (error) {

      console.error("Error fetching ID:", error);
      throw error;
  }
};