export const settingCategorySwitchOption = [
    { label: "On", value: "on" },
    { label: "Off", value: "off" },
];

export const generalUserSettingNoticeDataGroup = {
    categoryTitle: "알림",
    categoryControlData: [
        {
            switchTitle: "공지/이벤트 알림",
            switchGuideMessage: "공지/이벤트를 알림으로 받을 수 있습니다.",
            switchLabel: "notice",
            switchCheckedValue: false,
        },
    ],
};

export const businessUserSettingNoticeDataGroup = {
    categoryTitle: "알림",
    categoryControlData: [
        {
            switchTitle: "메시지 알림",
            switchGuideMessage: "메시지가 있을 때 알림으로 받을 수 있습니다.",
            switchLabel: "message",
            switchCheckedValue: false,
            checkboxEmailLabel: "이메일",
            checkboxEmailCheckedValue: false,
            checkboxSmsLabel: "SMS",
            checkboxSmsCheckedValue: false,
            defaultDisabled: true,
        },
        {
            switchTitle: "부킹 수락 알림",
            switchGuideMessage: "부킹이 수락되면 알림으로 받을 수 있습니다.",
            switchLabel: "booking",
            switchCheckedValue: false,
            checkboxEmailLabel: "이메일",
            checkboxEmailCheckedValue: false,
            checkboxSmsLabel: "SMS",
            checkboxSmsCheckedValue: false,
            defaultDisabled: true,
        },
        {
            switchTitle: "계약 알림",
            switchGuideMessage: "계약 단계마다 알림으로 받을 수 있습니다.",
            switchLabel: "contract",
            switchCheckedValue: false,
            checkboxEmailLabel: "이메일",
            checkboxEmailCheckedValue: false,
            checkboxSmsLabel: "SMS",
            checkboxSmsCheckedValue: false,
            defaultDisabled: true,
        },
    ],
};
