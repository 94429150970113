import React, { useState } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import VcAntSwitch from "components/antd/data-entry/switch";

import { generalMenuItemsDataGroup } from "data/mypage/main";
import { settingCategorySwitchOption, generalUserSettingNoticeDataGroup } from "data/mypage/setting";

import { VcAntSwitchTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function GeneralSetting({ className, style }: vcProps) {
    // 공지사항 Data Array
    const generalUserSwitchControlLabelArray = generalUserSettingNoticeDataGroup.categoryControlData.map((data: any, index: number) => data.switchLabel);
    const generalUserSwitchControlCheckedArray = generalUserSettingNoticeDataGroup.categoryControlData.map(
        (data: any, index: number) => data.switchCheckedValue
    );
    const [generalUserNotificationSwitchState, setGeneralUserNotificationSwitchState] = useState<boolean[]>(generalUserSwitchControlCheckedArray);

    function generalUserNoticeSwitchOnChangeEvent(checked: boolean, event: any) {
        const isChecked = checked;
        const isLabel = event.currentTarget.dataset.label;
        const checkIndex = generalUserSwitchControlLabelArray.indexOf(isLabel);
        generalUserNotificationSwitchState[checkIndex] = isChecked;
        setGeneralUserNotificationSwitchState([...generalUserNotificationSwitchState]);
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="settingCategoryGroup">
                <Box className="setCategoryTitle">
                    <Typography>{generalUserSettingNoticeDataGroup.categoryTitle}</Typography>
                </Box>
                {generalUserSettingNoticeDataGroup.categoryControlData.map((data: any, index: number) => {
                    return (
                        <Box className="setCategoryLine" key={index}>
                            <Flex justify="space-between" align="center">
                                <Box className="setLeftBox" sx={{ flex: 1 }}>
                                    <Box className="titleBox">
                                        <Typography>{data.switchTitle}</Typography>
                                    </Box>
                                    <Box className="guideMessageBox">
                                        <Typography>{data.switchGuideMessage}</Typography>
                                    </Box>
                                </Box>
                                <Box className="setRightBox" sx={{ flexBasis: "content" }}>
                                    <Box className="switchBox">
                                        <VcAntSwitch
                                            checked={generalUserNotificationSwitchState[index]}
                                            checkedChildren={settingCategorySwitchOption[0].label}
                                            unCheckedChildren={settingCategorySwitchOption[1].label}
                                            onChange={generalUserNoticeSwitchOnChangeEvent}
                                            size="default"
                                            dataLabel={data.switchLabel}
                                        />
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );

    const topTitleData = generalMenuItemsDataGroup[2].label;

    const setStyles = {
        "&": {},
        ...style,
    };

    return (
        <Box sx={setStyles}>
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default GeneralSetting;
