import React, { useState } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    matName?: string;
    matPreview?: any;
    matDescription?: string;
}

function AdvertiserCampaignDetailMaterialLayout({
    className,
    style,
    matName,
    matPreview,
    matDescription,
}: vcProps) {
    return (
        <Box className="advertiserCampaignDetailMaterialLayoutWrap">
            <Box className="materialBox">
                <Flex vertical justify="flex-start" align="center" gap="12px">
                    <Box className="materialNameBox">
                        <Typography>{matName}</Typography>
                    </Box>
                    <Box className="materialPreview">
                        <Box className="materialPreviewInner">
                            <img src={matPreview} alt="" />
                        </Box>
                    </Box>
                    <Box className="materialDescription">
                        <Typography>{matDescription}</Typography>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserCampaignDetailMaterialLayout;
