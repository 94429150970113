import React, { useState, useEffect } from "react";

import { Tabs, Button, Flex } from "antd";
import {
    PlusOutlined,
    MinusOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";

import { Box } from "@mui/material";

import AdvertisingTableLayout from "pages/advertiser/advertising/tableLayout";

import VcAntSelect from "components/antd/data-entry/select";
import VcAntInput from "components/antd/data-entry/input";
import VcAntTextArea from "components/antd/data-entry/textarea";
import VcAntRadio from "components/antd/data-entry/radio";
import VcAntButton from "components/antd/general/button";

import {
    campaignRadioOptionData,
    campaignTabsItemsData,
    campaignControlDataGroup,
    existingCampaignDataGroup,
    newCampaignDataGroup,
    newCampaignMaterialDataGroup,
} from "data/advertiser/advertising/campaign";

import Cookies from 'js-cookie'; // 추가된 부분

import type { TabsProps } from "antd";
import type { RadioChangeEvent } from "antd";
import { useCampaign } from "hooks/useCampaign";
import { useNavigate } from "react-router-dom";

// 파일 상단에 인터페이스 추가
interface Campaign {
    serial: string;
    title: string;
}


function AdvertisingCampaign() {
    const [radioSelectState, setRadioSelectState] = useState("existing");
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const { getCampaign } = useCampaign();
    const [selectedCampaign, setSelectedCampaign] = useState<string>("");
    const [newCampaignTitle, setNewCampaignTitle] = useState<string>("");
    const [newCampaignDesc, setNewCampaignDesc] = useState<string>("");

    const member_serial = Cookies.get('serial') || '';
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await getCampaign('all', member_serial);
                setCampaigns(response);
            } catch (error) {
                console.error('캠페인 데이터 로딩 실패:', error);
            }
        };
        
        fetchCampaigns();
    }, []);

    // 캠페인 라디오 변경 이벤트
    const radioOnChangeEvent = (event: RadioChangeEvent) => {
        setRadioSelectState(event.target.value);
    };

    // 캠페인 탭 변경 이벤트
    const tabsOnChangeEvent = (key: string) => {
        setRadioSelectState(key);
    };

    // 기존 캠페인 선택 이벤트
    function selectOnChangeEvent(value: string) {
        setSelectedCampaign(value);
    }

    // 새 캠페인 입력 핸들러들 추가
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewCampaignTitle(e.target.value);
    };

    const handleDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewCampaignDesc(e.target.value);
    };

    // 저장 버튼 클릭 핸들러
    const handleSave = () => {
        if (radioSelectState === "existing") {
            if (!selectedCampaign) {
                alert("캠페인을 선택해주세요.");
                return;
            }
            // 기존 캠페인 저장 로직
            console.log("선택된 캠페인:", selectedCampaign);
            // API 호출 등 필요한 로직 추가
            localStorage.setItem('advertisingCampaignData', JSON.stringify(selectedCampaign));
            navigate('../ai-loading');
        } else {
            if (!newCampaignTitle) {
                alert("캠페인 제목을 입력해주세요.");
                return;
            }
            // 새 캠페인 저장 로직
            console.log("새 캠페인 정보:", {
                title: newCampaignTitle,
                description: newCampaignDesc
            });
            // API 호출 등 필요한 로직 추가
        }
    };

    const campaignControlDataArray: any[] = [];
    campaignControlDataArray.push({
        categoryTitle: campaignControlDataGroup.title,
        categoryData: (
            <Box className="campaignRadioBox">
                <VcAntRadio
                    onChange={radioOnChangeEvent}
                    value={radioSelectState}
                    options={campaignRadioOptionData}
                    optionType="button"
                />
            </Box>
        ),
    });

    // 기존 캠페인
    const existingCampaignDataArray: any[] = [];
    existingCampaignDataArray.push({
        categoryTitle: existingCampaignDataGroup[0].title,
        categoryData: (
            <Box className="existingCampaignBox">
                <VcAntSelect
                    value={selectedCampaign}
                    className="advertisingCampaignSelect"
                    placeholder={existingCampaignDataGroup[0].placeholder}
                    onChange={selectOnChangeEvent}
                    options={campaigns.map(campaign => ({
                        value: campaign.serial,
                        label: campaign.title
                    }))}
                    dropdownStyle={{ textAlign: "center" }}
                    size="large"
                    suffixIcon={<CaretDownOutlined />}
                    fullWidth="100%"
                />
            </Box>
        ),
    });

    // 신규 캠페인
    const newCampaignDataArray: any[] = [];
    newCampaignDataArray.push(
        {
            categoryTitle: newCampaignDataGroup[0].title,
            categoryData: (
                <Box className="newCampaignBox">
                    <Box className="newCampaignLine">
                        <VcAntInput
                            value={newCampaignTitle}
                            onChange={handleTitleChange}
                            chooseType="basic"
                            placeholder={newCampaignDataGroup[0].placeholder}
                            size="large"
                        />
                    </Box>
                </Box>
            ),
        },
        {
            categoryTitle: newCampaignDataGroup[1].title,
            categoryData: (
                <Box className="newCampaignContent">
                    <Box className="newCampaignLine">
                        <VcAntTextArea
                            value={newCampaignDesc}
                            rows={4}
                            placeholder={newCampaignDataGroup[1].placeholder}
                            resize="none"
                            size="large"
                        />
                    </Box>
                </Box>
            ),
        },
        {
            categoryTitle: newCampaignDataGroup[2].title,
            categoryData: (
                <Box className="newCampaignMaterialWrap">
                    {newCampaignMaterialDataGroup.data.map(
                        (data: any, index: number) => {
                            return (
                                <Box
                                    className="newCampaignMaterialBox"
                                    key={index}
                                >
                                    <Box className="newCampaignMaterial">
                                        <Box className="newCampaignLine">
                                            <Flex
                                                justify="space-between"
                                                align="center"
                                            >
                                                <Box style={{ flex: 1 }}>
                                                    <VcAntInput
                                                        chooseType="basic"
                                                        placeholder={
                                                            newCampaignMaterialDataGroup
                                                                .commonPlaceholder
                                                                .title
                                                        }
                                                        size="large"
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box className="newCampaignLine">
                                            <VcAntInput
                                                chooseType="basic"
                                                placeholder={
                                                    newCampaignMaterialDataGroup
                                                        .commonPlaceholder.url
                                                }
                                                size="large"
                                            />
                                        </Box>
                                        <Box className="newCampaignLine">
                                            <VcAntTextArea
                                                rows={4}
                                                placeholder={
                                                    newCampaignMaterialDataGroup
                                                        .commonPlaceholder
                                                        .content
                                                }
                                                resize="none"
                                                size="large"
                                            />
                                        </Box>
                                        <Box className="minusButtonBox">
                                            <VcAntButton
                                                shape="circle"
                                                icon={<MinusOutlined />}
                                                buttonSize="small"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        }
                    )}
                    <Box sx={{ paddingTop: "16px" }}>
                        <Flex justify="center" align="center">
                            <VcAntButton
                                shape="circle"
                                icon={<PlusOutlined />}
                            />
                        </Flex>
                    </Box>
                </Box>
            ),
        }
    );

    const items: TabsProps["items"] = [
        {
            key: campaignTabsItemsData[0].key,
            label: campaignTabsItemsData[0].label,
            children: (
                <AdvertisingTableLayout tableData={existingCampaignDataArray} />
            ),
        },
        {
            key: campaignTabsItemsData[1].key,
            label: campaignTabsItemsData[1].label,
            children: (
                <AdvertisingTableLayout tableData={newCampaignDataArray} />
            ),
        },
    ];

    return (
        <Box className="campaignDataWrap">
            <AdvertisingTableLayout tableData={campaignControlDataArray} />
            <Tabs
                items={items}
                defaultActiveKey="1"
                activeKey={radioSelectState}
                onChange={tabsOnChangeEvent}
            />
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button 
                    id="campaign-next-button" 
                    className="campaign-next-button" 
                    color="primary" 
                    style={{ display: 'none' }}  // sx 대신 style 사용
                    onClick={handleSave}
                >
                    다음
                </Button>
            </Box>
        </Box>
    );
}

export default AdvertisingCampaign;
