export const partnerInformationDataGroup = [
    {
        title: "회사명",
        mainData: "(주)가나다한글최고컴퍼니",
        colSize: "full",
    },
    {
        title: "대표자명",
        mainData: "김한글",
        colSize: "half",
    },
    {
        title: "사업자등록번호",
        mainData: "123-12-12345",
        colSize: "half",
    },
    {
        title: "업태",
        mainData: "업태/업태/업태",
        colSize: "half",
    },
    {
        title: "업종",
        mainData: "업종/업종/업종",
        colSize: "half",
    },
    {
        title: "주소",
        mainData: "서울특별시 서초구 강남대로 331",
        colSize: "full",
    },
    {
        title: "세금계산서 이메일",
        mainData: "lab@veritasconnect.com",
        colSize: "full",
    },
    {
        title: "담당자명",
        mainData: "나담당",
        colSize: "half",
    },
    {
        title: "담당자 연락처",
        mainData: "010-1234-5678",
        colSize: "half",
    },
];

// sample
// {
//     title: "타이틀",
//     mainData: "",
// },
