import React, { useEffect, useState } from "react";

// import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntRadio from "components/antd/data-entry/radio";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import VcAntPaginationList from "components/custom/paginationList";
import AdvertiserContractListLayout from "./layout";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { partnerListDataGroup } from "data/mypage/advertiser/partner/list";
import { contractListDataGroup, contractFilterRadioData } from "data/mypage/advertiser/contract/list";

import type { RadioChangeEvent } from "antd";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserContractList({ className, style }: vcProps) {
    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentPageState, setCurrentPageState] = useState<number>(1);
    const [postsPerPageState, setPostsPerPageState] = useState<number>(10);

    const [radioSelectState, setRadioSelectState] = useState("all"); // Radio State

    useEffect(() => {
        dataFullLoadEvent();
    }, []);

    function dataFullLoadEvent() {
        setPostsState(contractListDataGroup);
    }

    function radioOnChangeEvent(event: RadioChangeEvent) {
        const targetValue = event.target.value;
        setRadioSelectState(targetValue);
        radioFilterEvent(targetValue);
    }

    function radioFilterEvent(value: any) {
        setCurrentPageState(1);
        if (value === "all") {
            dataFullLoadEvent();
        } else {
            const isConditional = (element: any) => element.state === value;
            const filterFinalData = contractListDataGroup.filter(isConditional);
            setPostsState(filterFinalData);
        }

        // const isConditional = (cdt: any) => cdt !== allCheckState;
        // setAllCheckState(checkGroupState.every(isConditional));
    }

    // 회사명 필터 셀렉트박스 리스트
    const partnerListNameArray = partnerListDataGroup.map((data: any, index: number) => data.companyName);

    const partnerListSelectData: any[] = [];
    partnerListNameArray.forEach((element, number) => {
        partnerListSelectData.push({
            label: element,
            value: "partnerName" + number,
        });
    });

    const finalData: any[] = [];
    postsState.forEach((element, number) => {
        finalData.push(
            <Box>
                <AdvertiserContractListLayout
                    imgPath={element.imgPath}
                    titleData={element.mediaCompanyName + " " + (number + 1)}
                    description={element.description}
                    date={element.date}
                    price={element.price}
                    state={element.state}
                />
            </Box>
        );
    });

    const firstPostIndex = (currentPageState - 1) * postsPerPageState;
    const lastPostIndex = firstPostIndex + postsPerPageState;
    const currentPosts = finalData.slice(firstPostIndex, lastPostIndex);

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="listInformationBox">
                <Box>
                    <VcAntRadio
                        onChange={radioOnChangeEvent}
                        value={radioSelectState}
                        options={contractFilterRadioData}
                        optionType="button"
                        size="large"
                        displayFlex={true}
                    />
                </Box>
            </Box>
            <VcAntPaginationList
                listOptionData={currentPosts}
                postsItemCount={postsState.length}
                currentPage={currentPageState}
                setCurrentPage={setCurrentPageState}
                postsPerPage={postsPerPageState}
                itemGap={true}
            />
        </Box>
    );

    const topTitleData = advertiserMenuItemsDataGroup[4].label;

    return (
        <Box className="advertiserContractListWrap">
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default AdvertiserContractList;
