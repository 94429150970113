import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography, Radio, Tabs, Row, Col } from "antd";
import type { TabsProps } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import AdvertiserCampaignDetailMaterialLayout from "./materialLayout";

import { campaignListDataGroup } from "data/mypage/advertiser/campaign/list";
import { materialListDataGroup } from "data/mypage/advertiser/material/list";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserCampaignDetail({ className, style }: vcProps) {
    const navigate = useNavigate();

    function campaignEditOnClickEvent() {
        // console.log("캠페인 편집");
        navigate("/mypage/advertiser/campaign-management/campaign-name/edit");
    }

    function goToListButtonEvent() {
        // console.log("목록으로");
        navigate("/mypage/advertiser/campaign-management/campaign-list");
    }

    const bodyContentData1 = (
        <Box className="bodyContentDataBox bodyContentDataBox1">
            <Box className="bodyContentDataLayoutBox">
                <Flex justify="flex-start" align="center" gap="16px">
                    <Box className="imgBox">
                        <Box className="imgBoxInner">
                            <img
                                src={campaignListDataGroup[0].imgPath}
                                alt=""
                            />
                        </Box>
                    </Box>
                    <Box className="informationBox">
                        <Box className="informationBoxInner">
                            <Flex
                                justify="flex-start"
                                align="center"
                                style={{ width: "100%", height: "100%" }}
                            >
                                <Box className="textBox">
                                    <Box className="nameBox">
                                        <Typography>
                                            {
                                                campaignListDataGroup[0]
                                                    .campaignName
                                            }
                                        </Typography>
                                    </Box>
                                    <Box className="subinformationBox">
                                        <Typography>
                                            {
                                                campaignListDataGroup[0]
                                                    .description
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Box className="buttonBox">
                        <Box>
                            <VcAntButton
                                conceptType="concept"
                                buttonType="default"
                                onClick={campaignEditOnClickEvent}
                                buttonTitle="캠페인 편집"
                                buttonSize="middle"
                            />
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );

    function materialChangeTabRadioEvent(key: string) {
        console.log(key);
    }

    const tabsBoxSetStyles = {
        "&": {
            "& .ant-tabs": {
                "& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn": {},
            },
        },
    };

    const items: TabsProps["items"] = [];
    materialListDataGroup.forEach((element: any, index: any) =>
        items.push({
            key: index,
            label: element.materialName,
            children: (
                <AdvertiserCampaignDetailMaterialLayout
                    matName={element.materialName}
                    matPreview={element.materialPreview}
                    matDescription={element.description}
                />
            ),
        })
    );

    // materialListDataGroup.map(
    //     (data: any, index: number) => console.log(data)
    // );

    const bodyContentData2 = (
        <Box className="bodyContentDataBox bodyContentDataBox2">
            <Box className="tabsBox" sx={tabsBoxSetStyles}>
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={materialChangeTabRadioEvent}
                    tabPosition="left"
                />
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserCampaignDetailWrap">
            <MypageSectionCard
                topTitle="캠페인 상세"
                bodyContent={bodyContentData1}
            />
            <MypageSectionCard
                topTitle="광고 소재"
                bodyContent={bodyContentData2}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={goToListButtonEvent}
                            buttonTitle="목록으로"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserCampaignDetail;
