import React, { useEffect } from "react";
import $ from "jquery";

import { Flex, Steps, Button, Typography, Menu } from "antd";

import { Box } from "@mui/material";

import "assets/scss/page/contentCard.scss";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    topTitle?: string;
    topBarContent?: React.ReactNode;
    bodyContent?: React.ReactNode;
}

function ContentCard({ className, style, topTitle, topBarContent, bodyContent }: vcProps) {
    useEffect(() => {
        const badgeTitleWidth = $(".badgeTitle").innerWidth();
        const paddingPlusValue = 200;
        $(".stepBox").css("padding-left", Number(badgeTitleWidth) + paddingPlusValue + "px");
    }, []);

    const setStyle = {
        ...style,
    };

    return (
        <Box sx={setStyle} className="ContentCardWrap">
            {/* <Flex justify="center" align="center" style={{ width: "100%", height: "100%" }}> */}
            <Box className="container">
                <Box className="conTop">
                    <Box className="title badgeTitle">
                        <Typography>{topTitle}</Typography>
                    </Box>
                    <Box className="bar">{topBarContent}</Box>
                </Box>
                <Box className="conBody">
                    <Box className="conBodyInner">{bodyContent}</Box>
                </Box>
            </Box>
            {/* </Flex> */}
        </Box>
    );
}

export default ContentCard;
