// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#contentWrapId {
  width: 1240px;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/standard/content.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,YAAA;EACA,sBAAA;EACA,cAAA;AAHJ","sourcesContent":["// * * * * * * * * * * * * * * CONTENT * * * * * * * * * * * * * *\n\n@import \"../common/palette.scss\";\n\n#contentWrapId {\n    width: 1240px;\n    height: auto;\n    box-sizing: border-box;\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
