// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrap {
  box-sizing: border-box;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/jy.scss"],"names":[],"mappings":"AAIA;EACI,sBAAA;EACA,YAAA;AAHJ","sourcesContent":["// * * * * * * * * * * * * * * JY * * * * * * * * * * * * * *\n\n@import \"./common/palette.scss\";\n\n.contentWrap {\n    box-sizing: border-box;\n    height: auto;\n}\n\n// .inputBtn {\n//     width: 140px;\n//     height: 36px;\n//     border: none;\n//     border-radius: 12px;\n//     box-sizing: border-box;\n//     background: $concept-color-100;\n//     color: $real-color-white-100;\n//     cursor: pointer;\n\n//     &:hover,\n//     &:active {\n//         background: #284570;\n//     }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
