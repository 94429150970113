import React from "react";

import { Typography } from "antd";

import { Box } from "@mui/material";

import VcSectionCard from "components/layout/sectionCard";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    topTitle?: string;
    bodyContent?: React.ReactNode;
}

function MypageSectionCard({ className, style, topTitle, bodyContent }: vcProps) {
    return <VcSectionCard topTitle={topTitle} bodyContent={bodyContent} />;
}

export default MypageSectionCard;
