import oohBanner1Sample from "assets/images/sample/ooh_banner1.jpg";
import oohBanner2Sample from "assets/images/sample/ooh_banner2.jpg";
import oohBanner3Sample from "assets/images/sample/ooh_banner3.jpg";

export const unitDataGroup = {
    information: {
        size: { displayHorizontalUnit: "m", displayVerticalUnit: "m", displayTotalUnit: "m²" },
        multiply: "x",
    },
    price: {
        dateUnit: "월",
        valueUnit: "원",
        priceEtc: "(VAT 별도)",
    },
    matching: {
        percentTitle: "매칭률",
        percentUnit: "%",
    },
};

export const mediaDataGroup = [
    {
        number: 1,
        bannerImg: oohBanner1Sample,
        information: {
            name: "매체명1",
            character: "LED 디스플레이",
            size: {
                displayHorizontalValue: 81,
                displayVerticalValue: 20,
                displayTotalValue: 1620,
            },
        },
        price: { value: 8000000 },
        matching: {
            percentValue: 98.5,
            description: "Enim aute ad adipisicing ut ut occaecat dolore Lorem non pariatur nostrud amet sint excepteur.",
        },
    },
    {
        number: 2,
        bannerImg: oohBanner2Sample,
        information: {
            name: "매체명2",
            character: "LED 디스플레이",
            size: {
                displayHorizontalValue: 81,
                displayVerticalValue: 20,
                displayTotalValue: 1620,
            },
        },
        price: { value: 8000000 },
        matching: {
            percentValue: 98.5,
            description: "Pariatur elit nostrud ut voluptate ad non deserunt commodo amet fugiat cillum dolore Lorem.",
        },
    },
    {
        number: 3,
        bannerImg: oohBanner3Sample,
        information: {
            name: "매체명3",
            character: "LED 디스플레이",
            size: {
                displayHorizontalValue: 81,
                displayVerticalValue: 20,
                displayTotalValue: 1620,
            },
        },
        price: { value: 8000000 },
        matching: { percentValue: 98.5, description: "Pariatur voluptate elit sunt officia fugiat dolore ad occaecat sint voluptate." },
    },
    {
        number: 4,
        bannerImg: oohBanner3Sample,
        information: {
            name: "매체명4",
            character: "LED 디스플레이",
            size: {
                displayHorizontalValue: 81,
                displayVerticalValue: 20,
                displayTotalValue: 1620,
            },
        },
        price: { value: 8000000 },
        matching: { percentValue: 98.5, description: "Pariatur voluptate elit sunt officia fugiat dolore ad occaecat sint voluptate." },
    },
];

export const mediaDetailCardTitleDataGroup = {
    title: ["매체", "매칭률", "매체명", "부킹정보", "매체 환경 분석"],
};

export const mediaDetailChartCardTitleDataGroup = [
    "주변 환경",
    "보행자",
    "성별",
    "연령대",
    "통행 차량",
    "시간대별 보행자",
    "시간대별 통과 교통량",
];

export const mediaDetailCard3TableThDataGroup = {
    title: ["구분", "매체타입", "매체형태", "컨텐츠", "설치장소", "규격", "운영시간"],
};

export const mediaDetailCard3TableTdValueDataGroup: any = {
    0: "빌보드, 생활동선",
    1: null,
    2: "세로형",
    3: "영상",
    4: "실외",
    5: null,
    6: { startTime: 6, endTime: 24 },
};

export const mediaDetailCard3TableTdUnitDataGroup = {
    timeTilde: "~",
    timeDay: "일",
    timeHour: "시",
    timeInterval: "시간",
    parenthesisStart: "(",
    parenthesisEnd: ")",
};

export const mediaDetailCard4TableThDataGroup = [
    { index: 1, title: "옵션선택" },
    { index: 2, title: "기간" },
    { index: 3, title: "수량" },
    { index: 4, title: "광고비(월)" },
];

export const mediaDetailCard5ChartDataGroup = {
    chart1: [
        {
            week: "월",
            남성: 24,
            여성: 40,
        },
        {
            week: "화",
            남성: 13,
            여성: 30,
        },
        {
            week: "수",
            남성: 48,
            여성: 20,
        },
        {
            week: "목",
            남성: 39,
            여성: 27,
        },
        {
            week: "금",
            남성: 48,
            여성: 18,
        },
        {
            week: "토",
            남성: 38,
            여성: 23,
        },
        {
            week: "일",
            남성: 43,
            여성: 34,
        },
    ],
    chart2: [
        { name: "상가", value: 34, color: "#f84f4f" },
        { name: "주거지역", value: 15, color: "#f38232" },
        { name: "A1", value: 10, color: "#ecec4a" },
        { name: "B1", value: 7, color: "#5fdd35" },
        { name: "C1", value: 15, color: "#35b9dd" },
        { name: "D1", value: 11, color: "#8d7bf3" },
    ],
    chart3: {
        Sunday: [
            { 시간: "00:00", 요일: "월", value: 100 },
            { 시간: "02:00", 요일: "월", value: 200 },
            { 시간: "04:00", 요일: "월", value: 300 },
        ],
        Monday: [
            { 시간: "00:00", 요일: "월", value: 100 },
            { 시간: "02:00", 요일: "월", value: 200 },
            { 시간: "04:00", 요일: "월", value: 300 },
        ],
        Tuesday: [
            { 시간: "00:00", 요일: "월", value: 100 },
            { 시간: "02:00", 요일: "월", value: 200 },
            { 시간: "04:00", 요일: "월", value: 300 },
        ],
        Wednesday: [
            { 시간: "00:00", 요일: "월", value: 100 },
            { 시간: "02:00", 요일: "월", value: 200 },
            { 시간: "04:00", 요일: "월", value: 300 },
        ],
        Thursday: [
            { 시간: "00:00", 요일: "월", value: 100 },
            { 시간: "02:00", 요일: "월", value: 200 },
            { 시간: "04:00", 요일: "월", value: 300 },
        ],
        Friday: [
            { 시간: "00:00", 요일: "월", value: 100 },
            { 시간: "02:00", 요일: "월", value: 200 },
            { 시간: "04:00", 요일: "월", value: 300 },
        ],
        Saturday: [
            { 시간: "00:00", 요일: "월", value: 100 },
            { 시간: "02:00", 요일: "월", value: 200 },
            { 시간: "04:00", 요일: "월", value: 300 },
        ],
    },
    chart4: [
        {
            name: "15~24",
            남성: 40,
            여성: 24,
        },
        {
            name: "25~34",
            남성: 30,
            여성: 13,
        },
        {
            name: "35~44",
            남성: 20,
            여성: 51,
        },
        {
            name: "45~54",
            남성: 27,
            여성: 39,
        },
        {
            name: "55~64",
            남성: 18,
            여성: 48,
        },
        {
            name: "65+",
            남성: 23,
            여성: 38,
        },
    ],
    chart5: [
        {
            name: "1",
            승용차: 400,
            승합차: 240,
        },
        {
            name: "2",
            승용차: 300,
            승합차: 139,
        },
        {
            name: "3",
            승용차: 200,
            승합차: 580,
        },
        {
            name: "4",
            승용차: 278,
            승합차: 390,
        },
        {
            name: "5",
            승용차: 189,
            승합차: 480,
        },
        {
            name: "6",
            승용차: 239,
            승합차: 380,
        },
        {
            name: "7",
            승용차: 247,
            승합차: 168,
        },
        {
            name: "8",
            승용차: 349,
            승합차: 227,
        },
        {
            name: "9",
            승용차: 262,
            승합차: 412,
        },
        {
            name: "10",
            승용차: 388,
            승합차: 420,
        },
        {
            name: "11",
            승용차: 400,
            승합차: 240,
        },
        {
            name: "12",
            승용차: 300,
            승합차: 139,
        },
        {
            name: "13",
            승용차: 200,
            승합차: 580,
        },
        {
            name: "14",
            승용차: 278,
            승합차: 390,
        },
        {
            name: "15",
            승용차: 189,
            승합차: 480,
        },
        {
            name: "16",
            승용차: 239,
            승합차: 380,
        },
        {
            name: "17",
            승용차: 247,
            승합차: 168,
        },
        {
            name: "18",
            승용차: 349,
            승합차: 227,
        },
        {
            name: "19",
            승용차: 262,
            승합차: 412,
        },
        {
            name: "20",
            승용차: 388,
            승합차: 420,
        },
        {
            name: "21",
            승용차: 400,
            승합차: 240,
        },
        {
            name: "22",
            승용차: 300,
            승합차: 139,
        },
        {
            name: "23",
            승용차: 200,
            승합차: 580,
        },
        {
            name: "24",
            승용차: 278,
            승합차: 390,
        },
    ],
    chart6: [
        { name: "승용차", value: 555, color: "rgba(130, 202, 157, 1)" },
        { name: "승합차", value: 430, color: "rgba(136, 132, 216, 1)" },
    ],
    chart7: [
        { name: "남성", value: 524, color: "rgba(130, 202, 157, 1)" },
        { name: "여성", value: 783, color: "rgba(136, 132, 216, 1)" },
    ],
};
