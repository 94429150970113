import React, { useState, useEffect } from "react";

import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { Flex, Typography, Row, Col, Space, Divider, Tabs } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import VcSectionCard from "components/layout/sectionCard";

import type { TabsProps } from "antd";

import { classListUnitDataGroup, classListInfoDataGroup, knowledgeServiceClassListTableHeader } from "data/knowledgeService/class";
import {
    knowledgeServiceClassDetailInfoTitleGroup,
    knowledgeServiceClassDetailInfoValueGroup,
    classProgramIntroduceDataGroup,
    classProgramSequence,
} from "data/knowledgeService/detail";
import { useLectureDetail } from "hooks/useLectureDetail";
import type { LectureDetail } from "hooks/useLectureDetail"; // LectureDetail 타입을 가져옴


interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

interface LectureContent {
    serial: string;
    lecture_serial: string;
    sequence: string;
    content: string;
    page: string;
}

function KnowledgeServiceClassDetail({ className, style }: vcProps) {
    const navigate = useNavigate();
    const location = useLocation(); // useLocation 훅 추가
    const [lectureId, setLectureId] = useState<number | null>(null);

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const lectureId = pathSegments[pathSegments.length - 2];
        if (lectureId) {
            setLectureId(parseInt(lectureId, 10));
        }
    }, [location]); // location을 의존성 배열에 추가

    const { data: lectureDetail, loading, error } = useLectureDetail(lectureId ?? 0);

    const lectureInfo = Array.isArray(lectureDetail) && lectureDetail.length > 0
    ? lectureDetail[0]
    : {
        professor_name: "없음",
        serial: "없음",
        professor_serial: "없음",
        subject_serial: "없음",
        content: "없음",
        title: "없음",
        period: "없음",
        price: "없음",
        subject_name: "없음",
        lecture_type: "없음",
        description: "없음",
        target: "없음",
        characteristic: "없음",
    };


    const lectureContents: LectureContent[] = Array.isArray(lectureDetail) && lectureDetail.length > 1 && lectureDetail[1]?.lecture_content
    ? lectureDetail[1].lecture_content
    : [];


    useEffect(() => {
        // lectureId가 변경될 때마다 useLectureDetail 훅이 다시 실행되도록 함
    }, [lectureId]);

    function takeClassOnClickEvent() {
        console.log("수강하기");
    }

    function goToListButtonEvent() {
        // console.log("목록으로");
        navigate("/knowledge-service/category-name1/class-list");
    }

    const bodyContentData1 = (
        <Box className="bodyContentDataBox bodyContentDataBox1">
            <Box className="bodyContentDataLayoutBox">
                <Flex justify="flex-start" align="center" gap="16px">
                    <Box className="imgBox">
                        <Box className="imgBoxInner">
                            <img src={classListInfoDataGroup[0].classImg} alt="" />
                        </Box>
                    </Box>
                    <Box className="informationBox">
                        <Box className="informationBoxInner">
                            <Flex justify="flex-start" align="center" style={{ width: "100%", height: "100%" }}>
                                <Box className="textBox">
                                    <Box className="nameBox">
                                        <Typography>{lectureInfo.title}</Typography>
                                    </Box>
                                    <Box className="subinformationBox">
                                        <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                                            <Box>
                                                <Flex justify="flex-start" align="center" gap="6px">
                                                    <Box className="leftTitle">
                                                        <Typography>{knowledgeServiceClassDetailInfoTitleGroup.classPeriodCount}</Typography>
                                                    </Box>
                                                    <Box className="rightData">
                                                        <Typography>
                                                            {lectureInfo.period}
                                                            {classListUnitDataGroup.classPeriodUnit}
                                                        </Typography>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Flex justify="flex-start" align="center" gap="6px">
                                                    <Box className="leftTitle">
                                                        <Typography>{knowledgeServiceClassDetailInfoTitleGroup.classProgramCount}</Typography>
                                                    </Box>
                                                    <Box className="rightData">
                                                        <Typography>
                                                            {lectureInfo.content}
                                                            {classListUnitDataGroup.programCountUnit}
                                                        </Typography>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Space>
                                    </Box>
                                    <Box className="descriptionBox">
                                        <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                                            <Box>
                                                <Flex justify="flex-start" align="center" gap="6px">
                                                    <Box className="leftTitle">
                                                        <Typography>{knowledgeServiceClassDetailInfoTitleGroup.teacherName}</Typography>
                                                    </Box>
                                                    <Box className="rightData">
                                                        <Typography>{lectureInfo.professor_name}</Typography>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Flex justify="flex-start" align="center" gap="6px">
                                                    <Box className="leftTitle">
                                                        <Typography>{knowledgeServiceClassDetailInfoTitleGroup.subjectName}</Typography>
                                                    </Box>
                                                    <Box className="rightData">
                                                        <Typography>{lectureInfo.subject_name}</Typography>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Flex justify="flex-start" align="center" gap="6px">
                                                    <Box className="leftTitle">
                                                        <Typography>{knowledgeServiceClassDetailInfoTitleGroup.classType}</Typography>
                                                    </Box>
                                                    <Box className="rightData">
                                                        <Typography>{lectureInfo.lecture_type}</Typography>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Space>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Box className="buttonBox">
                        <Box>
                            <VcAntButton conceptType="concept" buttonType="default" onClick={takeClassOnClickEvent} buttonTitle="수강" buttonSize="middle" />
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );

    const tabsOnChangeEvent = (key: string) => {
        console.log(key);
    };

    const tabOneData = (
        <Box className="tabDataBox tabOneDataBox">
            <Box className="tabDataInnerBox">
                <Box className="classProgramTableBox">
                    <table className="classProgramTable">
                        <tbody>
                            <tr className="classProgramTableRow" key={0}>
                                <td className="classProgramTableCol classProgramTableTh classProgramTableTitle">{classProgramIntroduceDataGroup[0].content[0].thead}</td>
                                <td className="classProgramTableCol classProgramTableTd classProgramTableData">{lectureInfo.description}</td>
                            </tr>
                            <tr className="classProgramTableRow" key={1}>
                                <td className="classProgramTableCol classProgramTableTh classProgramTableTitle">{classProgramIntroduceDataGroup[0].content[1].thead}</td>
                                <td className="classProgramTableCol classProgramTableTd classProgramTableData">{lectureInfo.target}</td>
                            </tr>
                            <tr className="classProgramTableRow" key={2}>
                                <td className="classProgramTableCol classProgramTableTh classProgramTableTitle">{classProgramIntroduceDataGroup[0].content[2].thead}</td>   
                                <td className="classProgramTableCol classProgramTableTd classProgramTableData">{lectureInfo.characteristic}</td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );

    const tabTwoData = (
        <Box className="tabDataBox tabTwoDataBox">
            <Box className="tabDataInnerBox">
                <Box className="classProgramTableBox">
                    <table className="classProgramTable">
                        <thead>
                            <tr className="classProgramTableRow">
                                <td className="classProgramTableCol classProgramTableTh classProgramTableTitle">
                                    {classProgramSequence[0].content.thead.col1}
                                </td>
                                <td className="classProgramTableCol classProgramTableTd classProgramTableData">{classProgramSequence[0].content.thead.col2}</td>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {lectureContents.length > 0 ? (
                                lectureContents.map((data: LectureContent) => (
                                    <tr className="classProgramTableRow" key={data.serial}>
                                        <td className="classProgramTableCol classProgramTableTh classProgramTableTitle">{data.content}</td>
                                        <td className="classProgramTableCol classProgramTableTd classProgramTableData">{data.page} 시간</td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="classProgramTableRow" >
                                    <td className="classProgramTableCol classProgramTableTh classProgramTableTitle" colSpan={2}>
                                        목차가 없습니다.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );

    const tabsItemsGroup: TabsProps["items"] = [
        {
            key: "1",
            label: "강좌 소개",
            children: tabOneData,
        },
        {
            key: "2",
            label: "강좌 목차",
            children: tabTwoData,
        },
    ];

    const bodyContentData2 = (
        <Box className="bodyContentDataBox bodyContentDataBox2">
            <Box className="tabsBox">
                <Tabs defaultActiveKey="1" items={tabsItemsGroup} onChange={tabsOnChangeEvent} />
            </Box>
        </Box>
    );

    return (
        <Box className="knowledgeServiceClassDetailWrap">
            <VcSectionCard topTitle="지식 서비스 상세" bodyContent={bodyContentData1} />
            <VcSectionCard topTitle="" bodyContent={bodyContentData2} />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={goToListButtonEvent}
                            buttonTitle="목록으로"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default KnowledgeServiceClassDetail;
