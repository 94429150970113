import React, { useState } from "react";

import { Flex, Button, Modal } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    open?: boolean;
    title?: React.ReactNode;
    onOk?: any;
    onCancel?: any;
    mainContent?: any;
    classNames?: any;
    wrapClassName?: string;
    footer?: React.ReactNode;
    maskClosable?: boolean;
    centered?: boolean;
    width?: string | number;
}

function VcAntModal({ className, style, open, title, onOk, onCancel, mainContent, classNames, wrapClassName, footer, maskClosable, centered, width }: vcProps) {
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const showModal = () => {
    //     setIsModalOpen(true);
    // };

    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };

    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };

    const setStyles = {
        "&": {},
        ...style,
    };

    return (
        <Box sx={setStyles}>
            <Modal
                open={open}
                // title={title}
                // onOk={onOk}
                onCancel={onCancel}
                classNames={classNames}
                wrapClassName={wrapClassName}
                footer={footer}
                maskClosable={maskClosable}
                centered={centered}
                width={width}
            >
                {mainContent}
            </Modal>
        </Box>
    );
}

export default VcAntModal;
