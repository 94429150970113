import React, { useState } from "react";

import { Col, Row } from "antd";

import { Box } from "@mui/material";

function MediaDetailView() {
    return (
        <Box className="mediaDetailWrap">
            <Row gutter={[16, 16]} justify="center" align="top">
                <Col span={12}>
                    <Box className="detailContentBox">
                        <Box className="detailContentBoxInner">
                            <Box className="number"></Box>
                            <Box className="content">
                                <Box className="imSlideBox">{String(1).padStart(2, "0")}</Box>
                            </Box>
                        </Box>
                    </Box>
                </Col>
                <Col span={12}>
                    <Box className="detailContentBox">테스트</Box>
                </Col>
            </Row>
        </Box>
    );
}

export default MediaDetailView;
