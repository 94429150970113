import React from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Result } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function Error404({ className, style }: vcProps) {
    const navigate = useNavigate();

    function mainButtonOnClickEvent() {
        navigate("/dashboard");
    }

    const titleData = "404";
    const descData = "죄송합니다. 방문한 페이지가 존재하지 않습니다.";
    const extraData = <VcAntButton buttonTitle="메인으로" onClick={mainButtonOnClickEvent} />;

    return (
        <Box className="errorWrap">
            <Flex justify="center" align="center" style={{ width: "100%", height: "100%" }}>
                <Box className="errorBox error404Box">
                    <Result status="404" title={titleData} subTitle={descData} extra={extraData} />
                </Box>
            </Flex>
        </Box>
    );
}

export default Error404;
