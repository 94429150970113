import React, { useState } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import { generalMenuItemsDataGroup } from "data/mypage/main";
import { businessUserRequestCompleteDataGroup } from "data/mypage/business";

function BusinessUserRequestComplete() {
    function serviceStartEvent() {
        console.log("시작하기");
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="businessUserRequestCompleteBodyContent">
                <Box className="contentDataWrap">
                    <Flex vertical justify="flex-start" align="center">
                        <Box className="imageBox">
                            <Box className="imageBoxText">IMG</Box>
                            <Box className="imageFile"></Box>
                        </Box>
                        <Box className="guidebox">
                            <Box className="guideTextBox">
                                <Typography>{businessUserRequestCompleteDataGroup.guideText}</Typography>
                            </Box>
                            <Box className="guideDescriptionBox">
                                <Typography>{businessUserRequestCompleteDataGroup.guideDescription}</Typography>
                            </Box>
                        </Box>
                        <Box className="startButtonBox">
                            <VcAntButton conceptType="concept" buttonType="default" onClick={serviceStartEvent} buttonTitle="시작하기" buttonSize="large" />
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Box>
    );

    const topTitleData = generalMenuItemsDataGroup[1].label;

    return (
        <Box className="businessUserRequestCompleteWrap">
            {/* 사업자 신청 - 완료 */}
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default BusinessUserRequestComplete;
