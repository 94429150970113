// Layout
import MainLayout from "layout/mainLayout";

import Dashboard from "pages/dashboard";

// common
import KnowledgeServiceIndex from "pages/common/knowledgeService";
import KnowledgeServiceClassList from "pages/common/knowledgeService/list";
import KnowledgeServiceClassDetail from "pages/common/knowledgeService/detail";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/dashboard",
            element: <Dashboard />,
        },
        {
            path: "/knowledge-service",
            element: <KnowledgeServiceIndex />,
            children: [
                {
                    path: ":category-name",
                    children: [
                        {
                            path: "class-list",
                            element: <KnowledgeServiceClassList />,
                        },
                        {
                            path: ":class-name",
                            children: [
                                {
                                    path: "detail",
                                    element: <KnowledgeServiceClassDetail />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

export default MainRoutes;
