export const contractDetailDataUnitGroup = {
    lineOne: {},
    lineTwo: {
        left: {},
        right: {
            dateSubTilde: "~",
            countUnit: "구좌",
        },
    },
};

export const contractDetailDataTitleGroup = {
    lineOne: {},
    lineTwo: {
        left: {
            oneLev: "계약",
            twoLev: "광고 검수",
            threeLev: "광고 확정",
        },
        right: {
            media: "매체",
            option: "옵션",
            date: "기간",
            count: "수량",
        },
    },
};

export const contractDetailDataValueGroup = {
    lineOne: {},
    lineTwo: {
        left: {
            oneLev: {
                date: "2024.01.01",
                time: "12:21",
            },
            twoLev: {
                date: "2024.01.07",
                time: "10:35",
            },
            threeLev: {
                date: "2024.01.14",
                time: "16:05",
            },
        },
        right: {
            media: "매체명",
            option: "30초 소재, 1일 100회 이상",
            date: {
                start: "2024.02.07",
                end: "2024.03.07",
            },
            count: 1,
        },
    },
};
