export const campaignListDataGroup = [
    {
        imgPath: "https://picsum.photos/210/210",
        campaignName: "캠페인명1",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/211/211",
        campaignName: "캠페인명2",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/212/212",
        campaignName: "캠페인명3",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/213/213",
        campaignName: "캠페인명4",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/214/214",
        campaignName: "캠페인명5",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/215/215",
        campaignName: "캠페인명6",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/216/216",
        campaignName: "캠페인명7",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/217/217",
        campaignName: "캠페인명8",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/218/218",
        campaignName: "캠페인명9",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/219/219",
        campaignName: "캠페인명10",
        description: "광고소재명 샬라샬라 외 7",
    },
    {
        imgPath: "https://picsum.photos/220/220",
        campaignName: "캠페인명11",
        description: "광고소재명 샬라샬라 외 7",
    },
];
