import React from "react";
import { Outlet } from "react-router-dom";

// import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import MypageDefaultLayout from "pages/mypage/common/mypageDefaultLayout";
import MypageMenu from "pages/mypage/common/mypageMenu";

import { mypageUserName } from "data/mypage/main";
import { advertiserMenuItemsDataGroup } from "data/mypage/main";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    contentData?: any;
}

function AdvertiserMypageLayout({ className, style, contentData }: vcProps) {
    const setStyles = {
        "&": {},
        ...style,
    };

    return (
        <Box className="advertiserMypageLayoutWrap" sx={setStyles}>
            <MypageDefaultLayout profileName={mypageUserName} sideMenu={<MypageMenu menuItems={advertiserMenuItemsDataGroup} />} content={<Outlet />} />
        </Box>
    );
}

export default AdvertiserMypageLayout;
