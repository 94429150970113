export const resultDataGroup = {
    common: {
        titleStart: "부킹 신청이",
        titleEnd: "되었습니다",
        description:
            "Tempor irure exercitation ad sunt aute officia cupidatat culpa amet Lorem Lorem. Incididunt deserunt ea Lorem sit occaecat exercitation aliquip mollit pariatur nisi sint nostrud. Labore ut ex sunt duis duis est ea dolore ullamco ut. Aliquip magna nisi anim ipsum exercitation irure id consequat esse officia deserunt. Non cupidatat aliquip enim duis. Enim anim quis enim ea cupidatat reprehenderit Lorem.",
        requestBookingInformation: {
            badge: "부킹정보",
            unitGroup: {
                sessionTilde: "~",
                volumeUnit: "구좌",
                proposedAdvertisingExpensesUnit: "원",
                proposedAdvertisingExpensesSubDec: "(VAT별도)",
            },
            tableData: {
                row1: {
                    title: "옵션",
                    value: "30초 소재, 1일 100회 이상",
                },
                row2: {
                    title: "기간",
                    value: {
                        sessionStart: {
                            year: 2024,
                            month: 1,
                            day: 30,
                        },
                        sessionEnd: {
                            year: 2024,
                            month: 2,
                            day: 20,
                        },
                    },
                },
                row3: {
                    title: "수량",
                    value: {
                        volume: 1,
                    },
                },
            },
            proposedAdvertisingExpenses: { title: "광고비", value: 15000000 },
        },
    },
    await: {
        resultWord: "완료",
    },
    accept: {
        resultWord: "수락",
    },
    refuse: {
        resultWord: "거절",
        refuseTime: "1시간 전",
        refuseProfileImg: "https://picsum.photos/210/210",
        refuseReasonTextValue:
            "Magna pariatur tempor officia fugiat mollit minim dolor laboris commodo ad do excepteur irure. Est sit eiusmod consequat nostrud duis nostrud quis magna velit tempor. Cillum labore ipsum deserunt et amet culpa duis adipisicing. Minim aute pariatur fugiat laboris eiusmod exercitation pariatur officia ipsum adipisicing et. Excepteur ad do mollit ipsum commodo proident do. Pariatur reprehenderit ea sint id. Voluptate cupidatat voluptate aliqua laborum ad occaecat.",
    },
    cancel: {
        resultWord: "취소",
    },
};
