import axios from 'axios';

const API_URL = '/api/';

export const fetchCategories = async () => {
    try {
        const response = await axios.post(`${API_URL}?cat=lecture&req=select&order=desc&limit=20`);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            return { success: false, message: `서버 오류: ${error.response.status}` };
          } else if (error.request) {
            return { success: false, message: '서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.' };
          } else {
            return { success: false, message: `요청 오류: ${error.message}` };
          }
        } else {
          const errorMessage = (error as Error).message;
          return { success: false, message: errorMessage };
        }
      }    
};

export const fetchCategoryLectures = async (categoryId: number) => {
    try {
        const response = await axios.post(`${API_URL}?cat=lecture&req=select`,{item:categoryId});
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            return { success: false, message: `서버 오류: ${error.response.status}` };
          } else if (error.request) {
            return { success: false, message: '서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.' };
          } else {
            return { success: false, message: `요청 오류: ${error.message}` };
          }
        } else {
          const errorMessage = (error as Error).message;
          return { success: false, message: errorMessage };
        }
      }    
};

export const fetchLectureDetail = async (lectureId: number) => {
    try {
        // 실제 API 호출 로직
        const response = await axios.get(`${API_URL}?cat=lecture&req=select&item=${lectureId}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            return { success: false, message: `서버 오류: ${error.response.status}` };
          } else if (error.request) {
            return { success: false, message: '서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.' };
          } else {
            return { success: false, message: `요청 오류: ${error.message}` };
          }
        } else {
          const errorMessage = (error as Error).message;
          return { success: false, message: errorMessage };
        }
      }    
};