export const colorGroup = {
    realColor: {
        white: {
            main: {
                100: "rgba(255, 255, 255, 1)",
                90: "rgba(255, 255, 255, 0.9)",
                80: "rgba(255, 255, 255, 0.8)",
                70: "rgba(255, 255, 255, 0.7)",
                60: "rgba(255, 255, 255, 0.6)",
                50: "rgba(255, 255, 255, 0.5)",
                40: "rgba(255, 255, 255, 0.4)",
                30: "rgba(255, 255, 255, 0.3)",
                20: "rgba(255, 255, 255, 0.2)",
                10: "rgba(255, 255, 255, 0.1)",
            },
        },
        black: {
            main: {
                100: "rgba(0, 0, 0, 1)",
                90: "rgba(0, 0, 0, 0.9)",
                80: "rgba(0, 0, 0, 0.8)",
                70: "rgba(0, 0, 0, 0.7)",
                60: "rgba(0, 0, 0, 0.6)",
                50: "rgba(0, 0, 0, 0.5)",
                40: "rgba(0, 0, 0, 0.4)",
                30: "rgba(0, 0, 0, 0.3)",
                20: "rgba(0, 0, 0, 0.2)",
                10: "rgba(0, 0, 0, 0.1)",
            },
        },
        gainsboro: {
            main: {
                100: "rgba(220, 220, 220, 1)",
                90: "rgba(220, 220, 220, 0.9)",
                80: "rgba(220, 220, 220, 0.8)",
                70: "rgba(220, 220, 220, 0.7)",
                60: "rgba(220, 220, 220, 0.6)",
                50: "rgba(220, 220, 220, 0.5)",
                40: "rgba(220, 220, 220, 0.4)",
                30: "rgba(220, 220, 220, 0.3)",
                20: "rgba(220, 220, 220, 0.2)",
                10: "rgba(220, 220, 220, 0.1)",
            },
        },
        clear: "rgba(255,255,255,0)",
    },
    conceptColor: {
        main: {
            100: "rgba(48, 82, 131, 1)",
            90: "rgba(48, 82, 131, 0.9)",
            80: "rgba(48, 82, 131, 0.8)",
            70: "rgba(48, 82, 131, 0.7)",
            60: "rgba(48, 82, 131, 0.6)",
            50: "rgba(48, 82, 131, 0.5)",
            40: "rgba(48, 82, 131, 0.4)",
            30: "rgba(48, 82, 131, 0.3)",
            20: "rgba(48, 82, 131, 0.2)",
            10: "rgba(48, 82, 131, 0.1)",
        },
    },
    dangerColor: {
        main: {
            100: "rgb(255, 77, 79, 1)",
            90: "rgb(255, 77, 79, 0.9)",
            80: "rgb(255, 77, 79, 0.8)",
            70: "rgb(255, 77, 79, 0.7)",
            60: "rgb(255, 77, 79, 0.6)",
            50: "rgb(255, 77, 79, 0.5)",
            40: "rgb(255, 77, 79, 0.4)",
            30: "rgb(255, 77, 79, 0.3)",
            20: "rgb(255, 77, 79, 0.2)",
            10: "rgb(255, 77, 79, 0.1)",
        },
    },
};
