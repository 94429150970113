// import { lazy } from "react";
// import Loadable from "@loadable/component";

// Layout
import AuthLayout from "layout/authLayout";

import JoinMembership from "pages/authority/joinMembership";
import JoinMembershipComplete from "pages/authority/joinMembership/complete";
import Login from "pages/authority/login";
import FindId from "pages/authority/findId";
import FindPassword from "pages/authority/findPassword";

// ==============================|| AUTHORITY ROUTING ||============================== //

const AuthRoutes = {
    path: "/authority",
    element: <AuthLayout />,
    children: [
        {
            path: "join-membership",
            element: <JoinMembership />,
        },
        {
            path: "join-membership/complete",
            element: <JoinMembershipComplete />,
        },
        {
            path: "login",
            element: <Login />,
        },
        {
            path: "find-id",
            element: <FindId />,
        },
        {
            path: "find-pw",
            element: <FindPassword />,
        },
    ],
};

export default AuthRoutes;
