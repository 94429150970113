import React, { useEffect, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import $ from "jquery";

import { Flex, Button, Typography, Dropdown } from "antd";
import { DownOutlined, MessageOutlined, BellOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import type { MenuProps } from "antd";

// import VcContainer from "components/layout/Container";

const authItems: MenuProps["items"] = [
    {
        key: 1,
        label: <NavLink to="/authority/login">로그인</NavLink>,
    },
    {
        key: 2,
        label: <NavLink to="/authority/join-membership">회원가입</NavLink>,
    },
    {
        key: 3,
        label: <NavLink to="/authority/find-id">아이디 찾기</NavLink>,
    },
    {
        key: 4,
        label: <NavLink to="/authority/find-pw">비밀번호 찾기</NavLink>,
    },
];

const leftMenuItems = [
    {
        key: 1,
        label: "logo",
        content: (
            <Box className="logoBox">
                <NavLink to="/dashboard">
                    <Box className="logo">LOGO</Box>
                </NavLink>
            </Box>
        ),
    },
    {
        key: 2,
        label: "대시보드",
        content: (
            <NavLink to="/dashboard">
                <Button>대시보드</Button>
            </NavLink>
        ),
    },
    {
        key: 2,
        label: "광고주",
        content: <Button>광고주</Button>,
    },
    {
        key: 3,
        label: "매체사",
        content: <Button>매체사</Button>,
    },
    {
        key: 4,
        label: "지식서비스",
        content: (
            <NavLink to="/knowledge-service/category-name1/class-list">
                <Button>지식서비스</Button>
            </NavLink>
        ),
    },
    {
        key: 5,
        label: "광고하기",
        content: (
            <NavLink to="/mypage/advertiser/campaign-management/advertising/target">
                <Button>광고하기</Button>
            </NavLink>
        ),
    },
    {
        key: 6,
        label: "권한",
        content: (
            <Dropdown menu={{ items: authItems }}>
                <Button>
                    권한
                    <DownOutlined />
                </Button>
            </Dropdown>
        ),
    },
];

const mypageItems: MenuProps["items"] = [
    {
        key: 1,
        label: <NavLink to="/mypage/general/profile">마이페이지(일반유저)</NavLink>,
    },
    {
        key: 2,
        label: <NavLink to="/mypage/advertiser/profile">마이페이지(광고주)</NavLink>,
    },
    {
        key: 3,
        label: <NavLink to="/mypage/media-company/profile">마이페이지(매체사)</NavLink>,
    },
];

const rightMenuItems = [
    {
        key: 1,
        label: "메세지",
        content: (
            <Button>
                <MessageOutlined />
            </Button>
        ),
    },
    {
        key: 2,
        label: "알람",
        content: (
            <Button>
                <BellOutlined />
            </Button>
        ),
    },
    {
        key: 3,
        label: "마이페이지",
        content: (
            <Dropdown menu={{ items: mypageItems }}>
                <Button>
                    마이페이지
                    <DownOutlined />
                </Button>
            </Dropdown>
        ),
    },
    {
        key: 4,
        label: "로그아웃",
        content: <Button>로그아웃</Button>,
    },
    // {
    //     key: 5,
    //     label: "메뉴",
    //     content: (
    //         <Button >
    //             <MenuOutlinedIcon />
    //         </Button>
    //     ),
    // },
];

function MainLayout() {
    const [selectedButton, setSelectedButton] = useState<number | null>(null);

    useEffect(() => {
        const headerWrapHeight = $("#headerWrapId").innerHeight();
        const paddingPlusValue = 24;
        $("#contentWrapId").css("padding-bottom", paddingPlusValue + "px");
        $("#contentWrapId").css("padding-top", Number(headerWrapHeight) + paddingPlusValue + "px");
        if (selectedButton !== null) {
            console.log(`선택된 버튼 인덱스: ${selectedButton}`);
        }
    }, [selectedButton]);

    return (
        <Box className="mainLayout">
            <Box id="headerWrapId" className="headerWrap">
                <Flex justify="space-between" align="center">
                    <Box className="leftMenu">
                        <Flex justify="flex-start" align="center" gap="16px">
                            {leftMenuItems.map((data: any, index: number) => {
                                return (
                                    <Box className="menuBtn" key={index}>
                                        {data.content}
                                    </Box>
                                );
                            })}
                        </Flex>
                    </Box>
                    <Box className="rightMenu">
                        <Flex justify="flex-start" align="center" gap="16px">
                            {/* {rightMenuItems.map((data: any, index: number) => {
                                return (
                                    <Box className="menuBtn" key={index}>
                                        {data.content}
                                    </Box>
                                );
                            })} */}
                            {rightMenuItems.map((data: any, index: number) => {
                                return (
                                    <Box 
                                        className={`menuBtn ${selectedButton === index ? 'selected' : ''}`} 
                                        key={index}
                                        onClick={() => setSelectedButton(index)}
                                    >
                                        {data.content}
                                    </Box>
                                );
                            })}                            
                        </Flex>
                    </Box>
                </Flex>
            </Box>

            <Box className="bodyWrap">
                <Box id="contentWrapId" className="contentWrap">
                    {/* <VcContainer fullWidth={true} fullHeight={true}> */}
                    <Outlet />
                    {/* </VcContainer> */}
                </Box>
            </Box>

            <Box id="footerWrapId" className="footerWrap">
                <Typography>©{new Date().getFullYear()} Created by VERITASCONNECT</Typography>
            </Box>
        </Box>
    );
}

export default MainLayout;
