export const pageTitleData = "회원가입";

export const authCategoryInputDataGroup = [
    {
        id : "user_id",
        title: "아이디",
        placeholder: "아이디를 입력해주세요",
        inputType: "basic",
        subButton: true,
        buttonLabel: "중복확인",
    },
    {
        id : "password",
        title: "비밀번호",
        placeholder: "비밀번호를 입력해주세요",
        inputType: "password",
        subButton: false,
        buttonLabel: "",
    },
    {
        id : "passwordCheck",        
        title: "비밀번호확인",
        placeholder: "비밀번호를 재입력해주세요",
        inputType: "password",
        subButton: false,
        buttonLabel: "",
    },
    {
        id : "name",
        title: "이름",
        placeholder: "이름을 입력해주세요",
        inputType: "basic",
        subButton: true,
        buttonLabel: "인증번호",
    },
    {
        id : "email",
        title: "이메일",
        placeholder: "이메일을 입력해주세요",
        inputType: "basic",
        subButton: true,
        buttonLabel: "인증확인",
    },
];

export const authCategoryCheckDataGroup = [
    {
        title: "약관및동의",
        placeholder: "",
    },
    {
        title: "수신동의",
        placeholder: "",
    },
];

export const authTermsAgreeDataGroup = [
    {
        key: 1,
        title: "[필수] 서비스 이용약관",
        text: "A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world. A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world. A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world. A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world. A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world. A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world. A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world. ",
        radioData: [
            {
                label: "서비스 이용약관 내용에 동의합니다.",
                value: "agree",
            },
            {
                label: "서비스 이용약관 내용에 동의하지 않습니다.",
                value: "disagree",
            },
        ],
        radioDefaultValue: "disagree",
    },
    {
        key: 2,
        title: "[필수] 개인정보 취급방침",
        text: "A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.",
        radioData: [
            {
                label: "개인정보 취급방침 내용에 동의합니다.",
                value: "agree",
            },
            {
                label: "개인정보 취급방침 내용에 동의하지 않습니다.",
                value: "disagree",
            },
        ],
        radioDefaultValue: "disagree",
    },
    {
        key: 3,
        title: "[필수] 약관명",
        text: "A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.",
        radioData: [
            {
                label: "약관명 내용에 동의합니다.",
                value: "agree",
            },
            {
                label: "약관명 내용에 동의하지 않습니다.",
                value: "disagree",
            },
        ],
        radioDefaultValue: "disagree",
    },
];

export const joinMembershipCompleteDataGroup = {
    guideText: "회원가입이 완료되었습니다.",
    guideDescription: "로그인 후 편리하게 ㅇㅇㅇ서비스를 이용하실 수 있습니다.",
};
