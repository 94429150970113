import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

import { Box } from "@mui/material";
import { Height, WidthFull } from "@mui/icons-material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    chartBoxWidth: any;
    chartBoxHeight: any;
    chartSize: any;
}

function ChartComp({ className, style, chartBoxWidth, chartBoxHeight, chartSize }: vcProps) {
    const dataGroup = {
        series: [
            {
                name: "값",
                data: [80, 50, 30, 40, 100],
            },
        ],
        options: {
            // title: {
            //     text: "Basic Radar Chart",
            // },
            yaxis: {
                stepSize: 20,
            },
            xaxis: {
                categories: ["항목명", "항목명", "항목명", "항목명", "항목명"],
            },
        },
    };

    const chartBoxSetStyles = {
        "&": {
            position: "relative",
            overflow: "hidden",
            "& .apexcharts-canvas": {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(calc(-50% + 4px), calc(-50% + 18px))",
            },
            "& .apexcharts-toolbar": {
                display: "none",
            },
            ...style,
        },
    };

    return (
        <Box className="chartBoxInner" sx={{ width: chartBoxWidth, height: chartBoxHeight, ...chartBoxSetStyles }}>
            <Box id="chart">
                {/* <Chart options={dataGroup.options} series={dataGroup.series} type="bar" width="500" /> */}
                <ReactApexChart options={dataGroup.options} series={dataGroup.series} type="radar" width={chartSize} />
            </Box>
        </Box>
    );
}

export default ChartComp;
