import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import BookingApplyMediaLayout from "pages/mypage/advertiser/booking/detail/layout";

import { resultDataGroup } from "data/mypage/advertiser/booking/result";
import { mediaDataGroup } from "data/advertiser/advertising/media";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserBookingRequestMediaResult({ className, style }: vcProps) {
    const navigate = useNavigate();

    // state 상황별
    // 대기: await
    // 수락: accept
    // 거절: refuse
    // 취소: cancel
    const [resultState, setResultState] = useState("refuse");

    const bodyContentData1 = (
        <Box className="bodyContentDataBox bodyContentDataBox1">
            <Box className="resultBox">
                <Box className="resultBoxInner">
                    <Box className="titleBox">
                        <Typography>{resultDataGroup.common.titleStart}</Typography>
                        <Box className="resultWordBox">
                            {resultState === "await" ? (
                                <Typography className="resultWord" style={{ backgroundColor: "#419aff" }}>
                                    {resultDataGroup.await.resultWord}
                                </Typography>
                            ) : resultState === "accept" ? (
                                <Typography className="resultWord" style={{ backgroundColor: "#06b000" }}>
                                    {resultDataGroup.accept.resultWord}
                                </Typography>
                            ) : resultState === "refuse" ? (
                                <Typography className="resultWord" style={{ backgroundColor: "#f76b6b" }}>
                                    {resultDataGroup.refuse.resultWord}
                                </Typography>
                            ) : resultState === "cancel" ? (
                                <Typography className="resultWord" style={{ backgroundColor: "#a0a0a0" }}>
                                    {resultDataGroup.cancel.resultWord}
                                </Typography>
                            ) : (
                                <Typography>...</Typography>
                            )}
                        </Box>
                        <Typography>{resultDataGroup.common.titleEnd}</Typography>
                    </Box>
                    <Box className="descriptionBox">
                        <Typography>{resultDataGroup.common.description}</Typography>
                    </Box>
                    <Box>
                        <Flex vertical justify="flex-start" align="center" gap="16px">
                            <Box className="infoBox">
                                <Flex justify="space-between" align="flex-start" gap="16px">
                                    <Box className="leftBox">
                                        <Box className="requestMediaInfo">
                                            <BookingApplyMediaLayout
                                                mediaDataGroupData={mediaDataGroup[0]}
                                                bookingResultViewOnClickEvent={() => {
                                                    console.log("이거맞냐");
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="rightBox">
                                        <Box className="subInfoBox">
                                            <Box className="paraBox oneParaBox bookingOptionBox">
                                                <Box className="badgeBox">
                                                    <Box className="badgeTitle">
                                                        <Typography>{resultDataGroup.common.requestBookingInformation.badge}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="bookingOptionInnerBox">
                                                    <Box className="bookingOptionTableBox">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tableCol tableTh">
                                                                        <Typography>
                                                                            {
                                                                                resultDataGroup.common.requestBookingInformation.tableData
                                                                                    .row1.title
                                                                            }
                                                                        </Typography>
                                                                    </td>
                                                                    <td className="tableCol tableTd">
                                                                        <Typography>
                                                                            {
                                                                                resultDataGroup.common.requestBookingInformation.tableData
                                                                                    .row1.value
                                                                            }
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="tableCol tableTh">
                                                                        <Typography>
                                                                            {
                                                                                resultDataGroup.common.requestBookingInformation.tableData
                                                                                    .row2.title
                                                                            }
                                                                        </Typography>
                                                                    </td>
                                                                    <td className="tableCol tableTd">
                                                                        <Box className="tableOptionBox">
                                                                            <Box className="sessionStartBox">
                                                                                <Flex justify="flex-start" align="flex-end">
                                                                                    <Typography className="dateData">
                                                                                        {
                                                                                            resultDataGroup.common.requestBookingInformation
                                                                                                .tableData.row2.value.sessionStart.year
                                                                                        }
                                                                                    </Typography>
                                                                                    <Typography className="dateData">
                                                                                        {
                                                                                            resultDataGroup.common.requestBookingInformation
                                                                                                .tableData.row2.value.sessionStart.month
                                                                                        }
                                                                                    </Typography>
                                                                                    <Typography className="dateData">
                                                                                        {
                                                                                            resultDataGroup.common.requestBookingInformation
                                                                                                .tableData.row2.value.sessionStart.day
                                                                                        }
                                                                                    </Typography>
                                                                                </Flex>
                                                                            </Box>
                                                                            <Box className="sessionTilde">
                                                                                <Typography>
                                                                                    {
                                                                                        resultDataGroup.common.requestBookingInformation
                                                                                            .unitGroup.sessionTilde
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className="sessionEndBox">
                                                                                <Flex justify="flex-start" align="flex-end">
                                                                                    <Typography className="dateData">
                                                                                        {
                                                                                            resultDataGroup.common.requestBookingInformation
                                                                                                .tableData.row2.value.sessionEnd.year
                                                                                        }
                                                                                    </Typography>
                                                                                    <Typography className="dateData">
                                                                                        {
                                                                                            resultDataGroup.common.requestBookingInformation
                                                                                                .tableData.row2.value.sessionEnd.month
                                                                                        }
                                                                                    </Typography>
                                                                                    <Typography className="dateData">
                                                                                        {
                                                                                            resultDataGroup.common.requestBookingInformation
                                                                                                .tableData.row2.value.sessionEnd.day
                                                                                        }
                                                                                    </Typography>
                                                                                </Flex>
                                                                            </Box>
                                                                        </Box>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="tableCol tableTh">
                                                                        <Typography>
                                                                            {
                                                                                resultDataGroup.common.requestBookingInformation.tableData
                                                                                    .row3.title
                                                                            }
                                                                        </Typography>
                                                                    </td>
                                                                    <td className="tableCol tableTd tableVolumeRow">
                                                                        <Flex justify="flex-start" align="flex-end">
                                                                            <Typography className="volumeData">
                                                                                {
                                                                                    resultDataGroup.common.requestBookingInformation
                                                                                        .tableData.row3.value.volume
                                                                                }
                                                                            </Typography>
                                                                            <Typography className="volumeUnit">
                                                                                {
                                                                                    resultDataGroup.common.requestBookingInformation
                                                                                        .unitGroup.volumeUnit
                                                                                }
                                                                            </Typography>
                                                                        </Flex>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="paraBox twoParaBox">
                                                <Box className="proposedAdvertisingExpensesTableBox">
                                                    <Box className="proposedAdvertisingExpensesTableBoxInner">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tableCol tableTh">
                                                                        <Typography>
                                                                            {
                                                                                resultDataGroup.common.requestBookingInformation
                                                                                    .proposedAdvertisingExpenses.title
                                                                            }
                                                                        </Typography>
                                                                    </td>
                                                                    <td className="tableCol tableTd proposedAdvertisingExpensesBox">
                                                                        <Flex justify="flex-start" align="flex-end">
                                                                            <Typography className="expensesPrice">
                                                                                {resultDataGroup.common.requestBookingInformation.proposedAdvertisingExpenses.value.toLocaleString(
                                                                                    "ko-KR"
                                                                                )}
                                                                            </Typography>
                                                                            <Typography className="expensesUnit">
                                                                                {
                                                                                    resultDataGroup.common.requestBookingInformation
                                                                                        .unitGroup.proposedAdvertisingExpensesUnit
                                                                                }
                                                                            </Typography>
                                                                            <Typography className="expenseSubDec">
                                                                                {
                                                                                    resultDataGroup.common.requestBookingInformation
                                                                                        .unitGroup.proposedAdvertisingExpensesSubDec
                                                                                }
                                                                            </Typography>
                                                                        </Flex>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                            {resultState === "refuse" ? (
                                <Box className="refuseReasonBox">
                                    <Box className="refuseReasonInnerBox">
                                        <Box className="refuseReasonTime">
                                            <Typography>{resultDataGroup.refuse.refuseTime}</Typography>
                                        </Box>
                                        <Box>
                                            <Flex justify="flex-start" align="flex-start" gap="16px">
                                                <Box className="profileImgbox">
                                                    <Box className="profileImg">
                                                        <img src={resultDataGroup.refuse.refuseProfileImg} alt="" />
                                                    </Box>
                                                </Box>
                                                <Box className="companyInfoBox">
                                                    <Flex vertical justify="flex-start" align="flex-start">
                                                        <Box className="companyName">
                                                            <Typography>{mediaDataGroup[0].information.name}</Typography>
                                                        </Box>
                                                        <Box className="refuseReasonText">
                                                            <Typography>{resultDataGroup.refuse.refuseReasonTextValue}</Typography>
                                                        </Box>
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : null}
                        </Flex>
                    </Box>
                    <Box className="buttonBox">
                        <Box className="buttonBoxInner">
                            <Flex justify="center" align="center" gap="16px">
                                {resultState === "await" ? (
                                    <Box className="messageButtonBox">
                                        <VcAntButton
                                            buttonTitle="부킹 신청 취소"
                                            buttonType="default"
                                            conceptType="default"
                                            onClick={() => {
                                                console.log("부킹 신청 취소!");
                                            }}
                                        />
                                    </Box>
                                ) : null}
                                {resultState === "await" || resultState === "accept" ? (
                                    <Box className="bookingManagementButtonBox">
                                        <VcAntButton
                                            buttonTitle="부킹관리"
                                            buttonType="default"
                                            conceptType="concept"
                                            onClick={() => {
                                                console.log("부킹관리!");
                                            }}
                                        />
                                    </Box>
                                ) : null}
                                {resultState === "refuse" ? (
                                    <Box className="messageButtonBox">
                                        <VcAntButton
                                            buttonTitle="부킹 신청내역 삭제"
                                            buttonType="default"
                                            conceptType="default"
                                            onClick={() => {
                                                console.log("부킹 신청내역 삭제!");
                                            }}
                                        />
                                    </Box>
                                ) : null}
                                {resultState === "refuse" ? (
                                    <Box className="messageButtonBox">
                                        <VcAntButton
                                            buttonTitle="부킹 재신청"
                                            buttonType="default"
                                            conceptType="concept"
                                            onClick={() => {
                                                console.log("부킹 재신청!");
                                            }}
                                        />
                                    </Box>
                                ) : null}

                                <Box className="messageButtonBox">
                                    <VcAntButton
                                        buttonTitle="메세지"
                                        buttonType="default"
                                        conceptType="default"
                                        onClick={() => {
                                            console.log("메세지!");
                                        }}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserBookingRequestMediaResultWrap">
            <MypageSectionCard topTitle="부킹 신청 매체 결과" bodyContent={bodyContentData1} />
        </Box>
    );
}

export default AdvertiserBookingRequestMediaResult;
