import React, { useState } from "react";

import { Row, Col } from "antd";

import { Box } from "@mui/material";

import AdButtonCheckboxComp from "components/custom/buttonCheckBox";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    itemsData?: any;
    gridType?: string;
    gridSize?: any;
    buttonSize?: "small" | "middle" | "large" | undefined;
}

function VcAdTgCheckCpnt({ className, style, itemsData, gridType, gridSize, buttonSize }: vcProps) {
    const itemsDataLabelArray = itemsData.map((data: any, index: number) => data.label);
    const itemsDataCheckedArray = itemsData.map((data: any, index: number) => data.checkedValue);

    const itemsDataAllChecked = [...Array(itemsDataCheckedArray.length)];

    const [allCheckState, setAllCheckState] = useState<boolean>(false);
    const [checkGroupState, setCheckGroupState] = useState<boolean[]>(itemsDataCheckedArray);

    function allCheckOnChangeEvent(event: any) {
        const isChecked = event.target.checked;
        setAllCheckState(isChecked);
        const allCheckedValue = itemsDataAllChecked.fill(isChecked);
        setCheckGroupState(allCheckedValue);
    }

    function allCheckButtonOnClickEvent() {
        setAllCheckState(!allCheckState);
        const allCheckedValue = itemsDataAllChecked.fill(!allCheckState);
        setCheckGroupState(allCheckedValue);
    }

    function checkOnChangeEvent(event: any) {
        const isChecked = event.target.checked;
        const isValue = event.target.value;
        const checkIndex = itemsDataLabelArray.indexOf(isValue);
        checkGroupState[checkIndex] = isChecked;
        setCheckGroupState([...checkGroupState]);
        allCheckSyncEvent();
    }

    function checkButtonOnClickEvent(event: any) {
        const isValue = event.currentTarget.value;
        const checkIndex = itemsDataLabelArray.indexOf(isValue);
        checkGroupState[checkIndex] = !checkGroupState[checkIndex];
        setCheckGroupState([...checkGroupState]);
        allCheckSyncEvent();
    }

    function allCheckSyncEvent() {
        const isConditional = (cdt: any) => cdt !== allCheckState;
        setAllCheckState(checkGroupState.every(isConditional));
    }

    return (
        <Box className="targetCheckWrap">
            <Row gutter={[16, 8]}>
                <Col {...(gridType === "span" ? { span: gridSize } : gridType === "flex" ? { flex: gridSize } : {})}>
                    <Box className="AllCheckBox">
                        <AdButtonCheckboxComp
                            buttonClassName="targetAllButton"
                            buttonType={allCheckState === true ? "primary" : "default"}
                            buttonOnClick={allCheckButtonOnClickEvent}
                            buttonLabel="전체"
                            buttonSize={buttonSize}
                            checkboxClassName="targetAllCheck"
                            checkboxChecked={allCheckState}
                            checkboxOnChange={allCheckOnChangeEvent}
                            checkboxLabel="전체"
                            fullWidth="100%"
                        />
                    </Box>
                </Col>
                {itemsData.map((data: any, index: number) => {
                    return (
                        <Col {...(gridType === "span" ? { span: gridSize } : gridType === "flex" ? { flex: gridSize } : {})} key={index}>
                            <Box className="checkGroupBox">
                                <AdButtonCheckboxComp
                                    buttonClassName="targetButton"
                                    buttonType={checkGroupState[index] === true ? "primary" : "default"}
                                    buttonOnClick={checkButtonOnClickEvent}
                                    buttonValue={data.label}
                                    buttonLabel={data.label}
                                    buttonSize={buttonSize}
                                    checkboxClassName="targetCheck"
                                    checkboxChecked={checkGroupState[index]}
                                    checkboxOnChange={checkOnChangeEvent}
                                    checkboxValue={data.label}
                                    checkboxLabel={data.label}
                                    fullWidth="100%"
                                />
                            </Box>
                        </Col>
                    );
                })}
            </Row>
        </Box>
    );
}

export default VcAdTgCheckCpnt;
