import React, { ReactNode } from "react";

import { Collapse } from "antd";
import { Box } from "@mui/material";

import { VcAntCollapseTheme, VcScrollTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    items?: any;
    defaultActiveKey?: string[] | string | number[] | number;
    bordered?: boolean;
    expandIcon?: (panelProps: any) => ReactNode;
    expandIconPosition?: "start" | "end" | undefined;
}

function VcAntCollapse({ className, style, items, defaultActiveKey, bordered, expandIcon, expandIconPosition }: vcProps) {
    const VcAntCollapseSetStyles = {
        "&": {
            marginTop: "16px",
            "& .ant-collapse-item ": {
                backgroundColor: VcAntCollapseTheme.contentBackgroundColor + " !important",
                borderRadius: VcAntCollapseTheme.borderRadius + " !important",
                overflow: "hidden",
                "& .ant-collapse-header": {
                    backgroundColor: VcAntCollapseTheme.headerBackgroundColor,
                    borderBottomLeftRadius: VcAntCollapseTheme.borderRadius + " !important",
                    borderBottomRightRadius: VcAntCollapseTheme.borderRadius + " !important",
                },
                "& .ant-collapse-content": {
                    backgroundColor: VcAntCollapseTheme.contentBackgroundColor + " !important",
                    "& .ant-collapse-content-box": {
                        padding: "16px !important",
                        "&::-webkit-scrollbar": { background: VcScrollTheme.scrollbarColorClear, width: "8px" },
                        "&::-webkit-scrollbar-thumb": { background: VcScrollTheme.scrollbarColorThumb, borderRadius: VcScrollTheme.scrollbarBorderRadius },
                        "&::-webkit-scrollbar-track": { background: VcScrollTheme.scrollbarColorFull, borderRadius: VcScrollTheme.scrollbarBorderRadius },
                        "&::-webkit-scrollbar-button": { display: "none" },
                    },
                },
            },
        },
        ...style,
    };

    return (
        <Box className="vcAntCollapseBox" sx={VcAntCollapseSetStyles}>
            <Collapse items={items} defaultActiveKey={defaultActiveKey} bordered={bordered} expandIcon={expandIcon} expandIconPosition={expandIconPosition} />
        </Box>
    );
}

export default VcAntCollapse;
