export const kpiCategoryDataGroup = [
    {
        title: "지표1",
        indicatorTitPh: "지표명을 입력하세요",
        indicatorConPh: "KPI를 입력하세요 (%)",
    },
    {
        title: "지표2",
        indicatorTitPh: "지표명을 입력하세요",
        indicatorConPh: "KPI를 입력하세요 (%)",
    },
    {
        title: "지표3",
        indicatorTitPh: "지표명을 입력하세요",
        indicatorConPh: "KPI를 입력하세요 (%)",
    },
    {
        title: "지표4",
        indicatorTitPh: "지표명을 입력하세요",
        indicatorConPh: "KPI를 입력하세요 (%)",
    },
    {
        title: "지표5",
        indicatorTitPh: "지표명을 입력하세요",
        indicatorConPh: "KPI를 입력하세요 (%)",
    },
    {
        title: "지표6",
        indicatorTitPh: "지표명을 입력하세요",
        indicatorConPh: "KPI를 입력하세요 (%)",
    },
    {
        title: "지표7",
        indicatorTitPh: "지표명을 입력하세요",
        indicatorConPh: "KPI를 입력하세요 (%)",
    },
];
