// GlobalFont.ts
import { createGlobalStyle } from "styled-components";

// 각 폰트 파일 import
import PretendardThin from "assets/font/pretendard/web/Pretendard-Thin.woff";
import PretendardExtraLight from "assets/font/pretendard/web/Pretendard-ExtraLight.woff";
import PretendardLight from "assets/font/pretendard/web/Pretendard-Light.woff";
import PretendardRegular from "assets/font/pretendard/web/Pretendard-Regular.woff";
import PretendardMedium from "assets/font/pretendard/web/Pretendard-Medium.woff";
import PretendardSemiBold from "assets/font/pretendard/web/Pretendard-SemiBold.woff";
import PretendardBold from "assets/font/pretendard/web/Pretendard-Bold.woff";
import PretendardExtraBold from "assets/font/pretendard/web/Pretendard-ExtraBold.woff";
import PretendardBlack from "assets/font/pretendard/web/Pretendard-Black.woff";

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: "pretendardThin";
    src: url(${PretendardThin}) format("woff2"), url(${PretendardThin}) format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "pretendardExtraLight";
    src: url(${PretendardExtraLight}) format("woff2"), url(${PretendardExtraLight}) format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "pretendardLight";
    src: url(${PretendardLight}) format("woff2"), url(${PretendardLight}) format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "pretendardRegular";
    src: url(${PretendardRegular}) format("woff2"), url(${PretendardRegular}) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "pretendardMedium";
    src: url(${PretendardMedium}) format("woff2"), url(${PretendardMedium}) format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "pretendardSemiBold";
    src: url(${PretendardSemiBold}) format("woff2"), url(${PretendardSemiBold}) format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "pretendardBold";
    src: url(${PretendardBold}) format("woff2"), url(${PretendardBold}) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "pretendardExtraBold";
    src: url(${PretendardExtraBold}) format("woff2"), url(${PretendardExtraBold}) format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "pretendardBlack";
    src: url(${PretendardBlack}) format("woff2"), url(${PretendardBlack}) format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
`;
