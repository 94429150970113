import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Row, Col, Typography } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";
import SectionMaterialFormControl from "pages/mypage/common/sectionMaterialFormControl";

import notLoadingImage from "assets/images/icon/not_loading_image.svg";

import {
    campaignEditDataGroup,
    materialEditDataGroup,
} from "data/mypage/advertiser/campaign/edit";

function AdvertiserCampaignRegister() {
    const navigate = useNavigate();

    function modifyCancelButtonEvent() {
        navigate(-1);
    }

    function modifySaveButtonEvent() {
        console.log("저장하기!");
    }

    function materialPlusOnClickEvent() {
        console.log("소재 추가");
    }

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <SectionFormControl categoryInputData={campaignEditDataGroup} />
            <SectionMaterialFormControl
                categoryInputData={materialEditDataGroup}
            />
            <Box className="materialPlusButtonBox">
                <Flex justify="center" align="center">
                    <VcAntButton
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={materialPlusOnClickEvent}
                    />
                </Flex>
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserCampaignRegisterWrap">
            <MypageSectionCard
                topTitle="캠페인 편집"
                bodyContent={bodyContentData}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="space-between" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifyCancelButtonEvent}
                            buttonTitle="취소"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={modifySaveButtonEvent}
                            buttonTitle="저장하기"
                            buttonSize="middle"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserCampaignRegister;
