import React, { ReactNode, useState } from "react";

import { InputNumber } from "antd";

import { Box } from "@mui/material";

import { VcAntInputTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    fullWidth?: "100%" | "auto" | "flex" | undefined;
    min?: number;
    max?: number;
    defaultValue?: number;
    controls?: boolean | { upIcon?: React.ReactNode; downIcon?: React.ReactNode };
    onChange?: any;
    size?: "small" | "middle" | "large" | undefined;

    // addonAfter?: ReactNode;
    // addonBefore?: ReactNode;
    // autoFocus?: boolean;
    // changeOnBlur?: boolean;
    // changeOnWheel?: boolean;
    // decimalSeparator?: string;
    // placeholder?: string;
    // disabled?: boolean;
    // formatter?: any;
    // keyboard?: boolean;
    // parser?: any;
    // precision?: number;
    // readOnly?: boolean;
    // status?: "error" | "warning" | undefined;
    // prefix?: ReactNode;
    // step?: number | string;
    // stringMode?: boolean;
    // value?: number;
    // variant?: "outlined" | "borderless" | "filled" | undefined;
    // onPressEnter?: any;
    // onStep?: any;
}

function VcAntInputNumber({ className, style, fullWidth, min, max, defaultValue, onChange, controls, size }: vcProps) {
    const setFullWidth = fullWidth === "100%" ? { width: "100%" } : fullWidth === "auto" ? { width: "auto" } : fullWidth === "flex" ? { flex: 1 } : {};
    const setStyles = {
        "&": {
            "& .ant-input-number": {
                ...setFullWidth,
                borderRadius: VcAntInputTheme.borderRadius,
            },
        },
        ...style,
    };

    return (
        <Box className={className} sx={setStyles}>
            <InputNumber min={min} max={max} defaultValue={defaultValue} onChange={onChange} controls={controls} size={size} />
        </Box>
    );
}

export default VcAntInputNumber;

//
//
// addonAfter
// addonBefore
// autoFocus
// changeOnBlur
// changeOnWheel
// controls
// decimalSeparator
// placeholder
// defaultValue
// disabled
// formatter
// keyboard
// min
// max
// parser
// precision
// readOnly
// status
// prefix
// size
// step
// stringMode
// value
// variant
// onChange
// onPressEnter
// onStep
