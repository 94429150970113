import React, { useEffect, useState } from "react";

import { Row, Col, Typography, Flex } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    tableData?: any;
}

function MypageSectionTableLayout({ className, style, tableData }: vcProps) {
    const [categoryAllDataArrayState, setCategoryAllDataArrayState] = useState<any[]>([]);
    useEffect(() => {
        const targetDataTitleArray = tableData.map((data: any, index: number) => data.categoryTitle);
        const targetDataTitleArrayLength = targetDataTitleArray.length;
        const targetDataContentArray = tableData.map((data: any, index: number) => data.categoryData);
        const targetAllDataArray = [];
        for (let i = 0; i < targetDataTitleArrayLength; i++) {
            targetAllDataArray[i] = { title: targetDataTitleArray[i], content: targetDataContentArray[i] };
        }
        setCategoryAllDataArrayState(targetAllDataArray);
    }, [tableData]);

    return (
        <Box>
            {categoryAllDataArrayState.map((data: any, index: number) => {
                return (
                    <Row gutter={[16, 16]} justify="center" align="middle" key={index}>
                        <Col span={4}>
                            <Box className="categoryTitleBox">
                                <Box className="categoryTitleBoxInner">
                                    <Typography>{data.title}</Typography>
                                </Box>
                            </Box>
                        </Col>
                        <Col span={20}>
                            <Box className="categoryDataBox">{data.content}</Box>
                        </Col>
                    </Row>
                );
            })}
        </Box>
    );
}

export default MypageSectionTableLayout;
