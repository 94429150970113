import React from "react";

// import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntRadio from "components/antd/data-entry/radio";

import MypageSectionTableLayout from "pages/mypage/common/sectionTableLayout";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    titleData?: any;
    onChange?: any;
    value?: any;
    options?: any;
}

function SectionRadioControl({ className, style, titleData, onChange, value, options }: vcProps) {
    const radioDataArray: any[] = [];
    const radioData = <VcAntRadio onChange={onChange} value={value} options={options} />;

    radioDataArray.push({
        categoryTitle: titleData,
        categoryData: radioData,
    });

    return (
        <Box className="sectionRadioControlWrap">
            <MypageSectionTableLayout tableData={radioDataArray} />
        </Box>
    );
}

export default SectionRadioControl;
