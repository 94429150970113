import React, { useState } from "react";

import { Button } from "antd";
import { Box } from "@mui/material";

import { VcAntButtonTheme } from "theme/component";

interface vcProps {
    conceptType?: "default" | "clear" | "concept" | undefined;
    className?: string;
    boxStyle?: any;
    buttonStyle?: any;
    buttonTitle?: string | number;
    buttonType?: "primary" | "dashed" | "link" | "text" | "default" | undefined;
    buttonSize?: "small" | "middle" | "large" | undefined;
    onClick?: any;
    toggleDefaultState?: any;
    toggleOnIcon?: React.ReactNode;
    toggleOffIcon?: React.ReactNode;
    iconPosition?: "start" | "end" | undefined;
    iconColor?: any;
    iconSize?: any;
    disabled?: boolean;
    name?: string;
    value?: string;
    fullWidth?: "100%" | "flex" | "auto" | undefined;
}

function VcToggleButton({
    conceptType,
    className,
    boxStyle,
    buttonStyle,
    buttonTitle,
    buttonType,
    buttonSize,
    onClick,
    toggleDefaultState,
    toggleOnIcon,
    toggleOffIcon,
    iconPosition,
    iconColor,
    iconSize,
    disabled,
    name,
    value,
    fullWidth,
}: vcProps) {
    const [toggleState, setToggleState] = useState(toggleDefaultState);

    function toggleButtonOnClickEvent() {
        setToggleState(!toggleState);
        if (onClick !== undefined) {
            onClick();
        }
    }

    const setFullWidth = fullWidth === "100%" ? { width: "100%" } : fullWidth === "auto" ? { width: "auto" } : fullWidth === "flex" ? { flex: 1 } : {};
    const conceptButtonSetStyles = {
        "&": {
            ...setFullWidth,
            "& .ant-btn": {
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled)": {
                    background: VcAntButtonTheme.backgroundConceptColor,
                    borderColor: VcAntButtonTheme.borderColor,
                    borderRadius: VcAntButtonTheme.borderRadius,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover": {
                    background: VcAntButtonTheme.backgroundConceptColorHover,
                    borderColor: VcAntButtonTheme.borderColor,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "& .ant-btn-icon .anticon svg": { fill: iconColor, fontSize: iconSize },
            },
        },
        ...boxStyle,
    };
    const clearButtonSetStyles = {
        "&": {
            ...setFullWidth,
            "& .ant-btn": {
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled)": {
                    background: VcAntButtonTheme.backgroundColorClear,
                    borderColor: VcAntButtonTheme.backgroundColorClear,
                    borderRadius: VcAntButtonTheme.borderRadius,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover": {
                    background: VcAntButtonTheme.backgroundConceptColorSoft,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "& .ant-btn-icon .anticon svg": { fill: iconColor, fontSize: iconSize },
            },
        },
        ...boxStyle,
    };

    const defaultButtonSetStyles = {
        "&": {
            ...setFullWidth,
            "& .ant-btn": {
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled)": {
                    background: VcAntButtonTheme.backgroundColor,
                    borderColor: VcAntButtonTheme.borderConceptColor,
                    borderRadius: VcAntButtonTheme.borderRadius,
                    color: VcAntButtonTheme.fontColorBlack,
                },
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover": {
                    background: VcAntButtonTheme.backgroundColorHover,
                    borderColor: VcAntButtonTheme.borderConceptColor,
                    color: VcAntButtonTheme.fontColorBlack,
                },
                "& .ant-btn-icon .anticon svg": { fill: iconColor, fontSize: iconSize },
            },
            "& .ant-btn-default:disabled, & .ant-btn-default.ant-btn-disabled": {
                background: VcAntButtonTheme.backgroundDisabledColor,
                borderColor: VcAntButtonTheme.borderColorDisabled,
                borderRadius: VcAntButtonTheme.borderRadius,
                color: VcAntButtonTheme.fontColorWhiteDisabled,
            },
        },
        ...boxStyle,
    };

    switch (conceptType) {
        case "concept":
            return (
                <Box sx={conceptButtonSetStyles}>
                    <Button
                        className={className}
                        style={buttonStyle}
                        type={buttonType}
                        size={buttonSize}
                        onClick={toggleButtonOnClickEvent}
                        icon={toggleState === true ? toggleOnIcon : toggleOffIcon}
                        iconPosition={iconPosition}
                        disabled={disabled}
                        name={name}
                        value={value}
                        {...(fullWidth === "100%" && {
                            block: true,
                        })}
                    >
                        {buttonTitle}
                    </Button>
                </Box>
            );
        case "clear":
            return (
                <Box sx={clearButtonSetStyles}>
                    <Button
                        className={className}
                        style={buttonStyle}
                        type={buttonType}
                        size={buttonSize}
                        onClick={toggleButtonOnClickEvent}
                        icon={toggleState === true ? toggleOnIcon : toggleOffIcon}
                        iconPosition={iconPosition}
                        disabled={disabled}
                        name={name}
                        value={value}
                        {...(fullWidth === "100%" && {
                            block: true,
                        })}
                    >
                        {buttonTitle}
                    </Button>
                </Box>
            );
        default:
            return (
                <Box sx={defaultButtonSetStyles}>
                    <Button
                        className={className}
                        style={buttonStyle}
                        type={buttonType}
                        size={buttonSize}
                        onClick={toggleButtonOnClickEvent}
                        icon={toggleState === true ? toggleOnIcon : toggleOffIcon}
                        iconPosition={iconPosition}
                        disabled={disabled}
                        name={name}
                        value={value}
                        {...(fullWidth === "100%" && {
                            block: true,
                        })}
                    >
                        {buttonTitle}
                    </Button>
                </Box>
            );
    }
}

export default VcToggleButton;
