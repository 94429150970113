import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, message } from "antd";

import { CaretRightOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import AuthTableLayout from "pages/authority/tableLayout";

import ContentCard from "components/layout/contentCard";

import VcAntInput from "components/antd/data-entry/input";
import VcAntRadio from "components/antd/data-entry/radio";
import VcAntCheckBox from "components/antd/data-entry/checkbox";
import VcAntButton from "components/antd/general/button";
import VcAntCollapse from "components/antd/data-display/collapse";

import { authCategoryInputDataGroup, authCategoryCheckDataGroup, authTermsAgreeDataGroup, pageTitleData } from "data/authority/joinMembership";
import { receiveAgreeDataGroup } from "data/mypage/common/receiveAgree";

import "assets/scss/page/auth.scss";

import { VcAntButtonTheme } from "theme/component";
import { useMemberRegistration } from 'hooks/useMemberRegistration';


import type { RadioChangeEvent } from "antd";

function Joinmembership() {
    const navigate = useNavigate();

    const [termsRadioGroupState1, setTermsRadioGroupState1] = useState(authTermsAgreeDataGroup[0].radioDefaultValue);
    const [termsRadioGroupState2, setTermsRadioGroupState2] = useState(authTermsAgreeDataGroup[1].radioDefaultValue);
    const [termsRadioGroupState3, setTermsRadioGroupState3] = useState(authTermsAgreeDataGroup[2].radioDefaultValue);

    const receiveAgreeValueArray = receiveAgreeDataGroup.map((data: any, index: number) => data.value);
    const receiveAgreeCheckedArray = receiveAgreeDataGroup.map((data: any, index: number) => data.checked);

    const [receiveAgreeDataState, setReceiveAgreeDataState] = useState<boolean[]>(receiveAgreeCheckedArray);

    const { registerMember, isLoading, error } = useMemberRegistration();

    const formRef = useRef<{ [key: string]: any }>({});

    const radioOnChangeEvent1 = (event: RadioChangeEvent) => {
        setTermsRadioGroupState1(event.target.value);
    };
    const radioOnChangeEvent2 = (event: RadioChangeEvent) => {
        setTermsRadioGroupState2(event.target.value);
    };
    const radioOnChangeEvent3 = (event: RadioChangeEvent) => {
        setTermsRadioGroupState3(event.target.value);
    };

    function receiveAgreeOnChangeEvent(event: any) {
        const isChecked = event.target.checked;
        const isValue = event.target.value;
        const checkIndex = receiveAgreeValueArray.indexOf(isValue, 0);
        receiveAgreeDataState[checkIndex] = isChecked;
        setReceiveAgreeDataState([...receiveAgreeDataState]);
    }

    const subButtonArray = [
        {
            event: function idCheckEvent() {
                console.log("중복확인");
            },
        },
        { event: undefined },
        { event: undefined },
        {
            event: function sendAuthNumberEvent() {
                console.log("인증번호 전송");
            },
        },
        {
            event: function authNumberCheckEvent() {
                console.log("인증번호 확인");
            },
        },
    ];

    const userDataArray: any[] = [];
    // Inside your forEach loop for the user inputs
    authCategoryInputDataGroup.forEach((element, number) => {
        userDataArray.push({
            categoryTitle: element.title,
            categoryData: (
                <Flex justify="flex-start" align="center" gap="12px" style={{ width: "100%" }}>
                    <Box style={{ flex: 1 }}>
                        <VcAntInput
                            id={element.id}
                            chooseType={element.inputType}
                            placeholder={element.placeholder}
                            size="large"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                formRef.current[element.id] = e.target.value;
                            }}
                        />
                    </Box>
                </Flex>
            ),
        });
    });

    const radioGroupData = [
        {
            component: <VcAntRadio onChange={radioOnChangeEvent1} value={termsRadioGroupState1} options={authTermsAgreeDataGroup[0].radioData} />,
        },
        {
            component: <VcAntRadio onChange={radioOnChangeEvent2} value={termsRadioGroupState2} options={authTermsAgreeDataGroup[1].radioData} />,
        },
        {
            component: <VcAntRadio onChange={radioOnChangeEvent3} value={termsRadioGroupState3} options={authTermsAgreeDataGroup[2].radioData} />,
        },
    ];

    const collapseCompBoxSetStyles = {
        "&": {
            marginTop: "16px",
            "&:first-of-type": {
                marginTop: 0,
            },
        },
    };

    const collapseData = (
        <Box className="collapseBox">
            {authTermsAgreeDataGroup.map((data: any, index: number) => {
                return (
                    <Box className="collapseCompBox" sx={collapseCompBoxSetStyles} key={index}>
                        <VcAntCollapse
                            items={[
                                {
                                    key: data.key,
                                    label: data.title,
                                    children: <Box>{data.text}</Box>,
                                },
                            ]}
                            bordered={false}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}
                            expandIconPosition="end"
                        />
                        <Box className="collapseRadioBox">{radioGroupData[index].component}</Box>
                    </Box>
                );
            })}
        </Box>
    );

    const checkboxData = (
        <Flex justify="flex-start" align="center" gap="16px">
            {receiveAgreeDataGroup.map((data: any, index: number) => {
                return (
                    <Box className="checkGroupBox" key={index}>
                        <VcAntCheckBox onChange={receiveAgreeOnChangeEvent} checked={receiveAgreeDataState[index]} value={data.value} label={data.label} />
                    </Box>
                );
            })}
        </Flex>
    );

    userDataArray.push(
        {
            categoryTitle: authCategoryCheckDataGroup[0].title,
            categoryData: collapseData,
        },
        {
            categoryTitle: authCategoryCheckDataGroup[1].title,
            categoryData: checkboxData,
        }
    );

    function historyBackEvent() {
        console.log("나가기");
    }

    const validateForm = () => {
        const errors: { [key: string]: string } = {};

        if (!formRef.current.user_id) errors.user_id = "아이디를 입력해주세요.";
        if (!formRef.current.password) errors.password = "비밀번호를 입력해주세요.";
        if (formRef.current.password !== formRef.current.passwordCheck) errors.passwordCheck = "비밀번호가 일치하지 않습니다.";
        if (!formRef.current.name) errors.name = "이름을 입력해주세요.";
        if (!formRef.current.email) errors.email = "이메일을 입력해주세요.";

        if (termsRadioGroupState1 !== "agree") errors.terms1 = "서비스 이용약관에 동의해주세요.";
        if (termsRadioGroupState2 !== "agree") errors.terms2 = "개인정보 취급방침에 동의해주세요.";
        if (termsRadioGroupState3 !== "agree") errors.terms3 = "필수 약관에 동의해주세요.";
        
        // 추가적인 validation 로직을 여기에 추가하세요.

        return errors;
    };

    function formatDate() {
        return new Date().toLocaleString('ko-KR', {
            timeZone: 'Asia/Seoul',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // 24시간 형식으로 설정
        })
        .replace(/\./g, '-')
        .replace(/ /g, '');
    }

    async function joinMemberButtonEvent() {
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            console.log("Validation Errors:", errors);
            message.error(Object.values(errors).join('/'));
            return;
        }

        
        // * "id": "mmresult",                      아이디
        // * "password": "mypassword",              비밀번호
        // * "name": "이진우",                       이름
        //  "nick": "이진우",
        // * "email": "eric@veritasconnect.com",    이메일
        //  "birth": "",
        //  "tell": "",
        //  "hp": "",
        //  "zipcode": "",
        //  "city": "",
        //  "image": "",
        //  "role": "",
        //  "allow_email": "0",
        //  "allow_sms": "0",
        //  "allow_contract": "0",
        //  "nickdate": "2024-05-20 15:30:46",
        //  "modate": "2024-05-20 15:30:46",
        //  "regdate": "2024-05-20 15:30:46",
        //  "memo": ""
        const memberData = {
            id: formRef.current.user_id || "",
            password: formRef.current.password || "",
            name: formRef.current.name || "",
            email: formRef.current.email || "",
            nick: formRef.current.nick || "",            
            birth: formRef.current.birth || "",
            tell: formRef.current.tell || "",
            hp: formRef.current.hp || "010-1234-1234",
            zipcode: formRef.current.zipcode || "",
            city: formRef.current.city || "",
            image: formRef.current.image || "",
            role: formRef.current.role || "",
            allow_email: receiveAgreeDataState[0] ? "1" : "0",
            allow_sms: receiveAgreeDataState[1] ? "1" : "0",
            allow_contract: termsRadioGroupState1 === "agree" ? "1" : "0",
            nickdate: formatDate(),
            modate: formatDate(),
            regdate: formatDate(),
        };
        
        try {
          const result = await registerMember(memberData);
          if (result.success) {
            console.log('가입 성공:', result);
            // navigate("./complete");
          } else {
            console.log('가입 실패:', result.message);
            message.error(result.message);
          }
        } catch (err) {
          console.error('가입 실패:', err);
          // 에러 처리 로직 추가
        }
      }

    const bodyContentData = (
        <Box className="joinMembershipBodyContent">
            <Flex vertical justify="space-between" align="center" style={{ height: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Box className="contentDataWrap">
                        <AuthTableLayout tableData={userDataArray} />
                    </Box>
                </Box>
                <Box style={{ width: "100%" }} className="buttonWrap">
                    <Box style={{ marginTop: 24 }}>
                        <Flex justify="space-between" align="center">
                            <Box>
                                <VcAntButton
                                    conceptType="clear"
                                    buttonType="default"
                                    onClick={historyBackEvent}
                                    buttonTitle="나가기"
                                    buttonSize="large"
                                    // icon={<LeftOutlined />}
                                    iconPosition="start"
                                />
                            </Box>
                            <Box>
                                <VcAntButton
                                    conceptType="concept"
                                    buttonType="default"
                                    onClick={joinMemberButtonEvent}
                                    buttonTitle="가입하기"
                                    buttonSize="large"
                                    // icon={<RightOutlined />}
                                    iconPosition="end"
                                />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );

    return (
        <Box className="joinMembershipWrap">
            {/* 회원가입 */}
            <ContentCard topTitle={pageTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default Joinmembership;
