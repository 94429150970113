import React from "react";

import { Flex, Typography, Switch } from "antd";

import { Box } from "@mui/material";

import { VcAntSwitchTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    autoFocus?: boolean;
    checked?: boolean;
    checkedChildren?: React.ReactNode;
    unCheckedChildren?: React.ReactNode;
    defaultChecked?: boolean;
    value?: boolean;
    defaultValue?: boolean;
    disabled?: boolean;
    loading?: boolean;
    size?: "default" | "small" | undefined;
    onChange?: any;
    onClick?: any;
    dataLabel?: any;
}

function VcAntSwitch({
    className,
    style,
    autoFocus,
    checked,
    checkedChildren,
    unCheckedChildren,
    defaultChecked,
    value,
    defaultValue,
    disabled,
    loading,
    size,
    onChange,
    onClick,
    dataLabel,
}: vcProps) {
    const setStyles = {
        "&": {
            // 체크 비활성화 UnChecked 기본
            "& .ant-switch": {
                height: "auto",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: VcAntSwitchTheme.borderColor,
                boxSizing: "border-box",
                padding: "2px 4px",
                "& .ant-switch-handle": {
                    top: "50%",
                    transform: "translateY(-50%)",
                    insetInlineStart: "4px",
                },
                "& .ant-switch-inner": {
                    "& .ant-switch-inner-checked": { minHeight: "initial" },
                    "& .ant-switch-inner-unchecked": {
                        color: VcAntSwitchTheme.fontColorWhite,
                        fontSize: "13px",
                        minHeight: "initial",
                    },
                },
            },
            // 체크 활성화 Checked Active
            "& .ant-switch.ant-switch-checked": {
                background: VcAntSwitchTheme.backgroundColorActive,
                "&:hover:not(.ant-switch-disabled)": {
                    background: VcAntSwitchTheme.backgroundColorActiveHover,
                },
                "& .ant-switch-handle": { insetInlineStart: "calc(100% - 22px)" },
                "& .ant-switch-inner": {
                    "& .ant-switch-inner-checked": { color: VcAntSwitchTheme.fontColorWhite, fontSize: "13px" },
                },
            },
        },

        ...style,
    };

    return (
        <Box className={className} sx={setStyles}>
            <Switch
                checked={checked}
                value={value}
                checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                onChange={onChange}
                size={size}
                data-label={dataLabel}
            />
        </Box>
    );
}

export default VcAntSwitch;

// autoFocus,
// checked,
// checkedChildren,
// unCheckedChildren,
// defaultChecked,
// value,
// defaultValue,
// disabled,
// loading,
// size,
// onChange,
// onClick,
