import { Box } from "@mui/material";

import Button from "components/styledComponents/button/VcBtn";

function DashboardIndex() {
    return (
        <Box sx={{ color: "white" }}>
            <h1>대시보드</h1>
        </Box>
    );
}

export default DashboardIndex;
