import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import { classListUnitDataGroup } from "data/knowledgeService/class";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    classTitle?: string;
    classPeriod?: number;
    classProgram?: number;
    classPath?: string;
}

function KnowledgeServiceClassListLayout({ className, style, classTitle, classPeriod, classProgram, classPath }: vcProps) {
    const navigate = useNavigate();

    // const detailPath = "/knowledge-service/category-name1/class-name1/detail";

    function classListDetailViewOnClickEvent() {
        if (classPath) {
            navigate(classPath);
        } else {
            console.error("classPath가 정의되지 않았습니다.");
        }
    }

    const setStyles = {
        ...style,
    };

    return (
        <Box className="knowledgeServiceClassListLayoutWrap" sx={setStyles}>
            <table className="classTable">
                <tbody>
                    <tr className="classRow">
                        <td className="classCol classTitleBox">
                            <Typography.Paragraph ellipsis={{ rows: 1 }}>{classTitle}</Typography.Paragraph>
                        </td>
                        <td className="classCol classDetailBox">
                            <VcAntButton buttonTitle="상세" buttonType="default" conceptType="clear" onClick={classListDetailViewOnClickEvent} />
                        </td>
                        <td className="classCol classPeriodCountBox">
                            <Typography>
                                {classPeriod}
                                {classListUnitDataGroup.classPeriodUnit}
                            </Typography>
                        </td>
                        <td className="classCol classProgramCountBox">
                            <Typography>
                                {classProgram}
                                {classListUnitDataGroup.programCountUnit}
                            </Typography>
                        </td>
                        <td className="classCol takeClassButtonBox">
                            <VcAntButton
                                buttonTitle="수강"
                                buttonType="default"
                                conceptType="clear"
                                onClick={() => {
                                    console.log("수강");
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </Box>
    );
}

export default KnowledgeServiceClassListLayout;
