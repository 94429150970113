import React from "react";

import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";
import VcAntTextArea from "components/antd/data-entry/textarea";

import MypageSectionMaterialTableLayout from "pages/mypage/common/sectionMaterialTableLayout";
import { ConsoleSqlOutlined } from "@ant-design/icons";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    categoryInputData?: any;
}

function SectionMaterialFormControl({
    className,
    style,
    categoryInputData,
}: vcProps) {
    const profileDataArray: any[] = [];
    categoryInputData.forEach(
        (
            element: {
                dataGroup:any,
                mainInput: { switch: boolean; inputType: string | undefined; type: any; placeholder: string | undefined; };
                subButton: {
                    left: { switch: boolean; label: string | number | undefined; };
                    right: { switch: boolean; label: string | number | undefined; };
                };
            },
            number: number
        ) => {
            profileDataArray.push({
                categoryOneTitle: element.dataGroup.lineGroup1.title,
                categoryOneData: (
                    <Box>
                        <Flex justify="flex-start" align="center" gap="12px" style={{ width: "100%" }}>
                            {element.dataGroup.lineGroup1.subButton.left.switch === true && (
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonTitle={element.dataGroup.lineGroup1.subButton.left.label}
                                        buttonType="default"
                                        onClick={element.dataGroup.lineGroup1.subButton.left.event}
                                    />
                                </Box>
                            )}
                            {element.dataGroup.lineGroup1.mainInput.switch === true && (
                                element.dataGroup.lineGroup1.mainInput.inputType === "textarea" ? (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntTextArea
                                            rows={4}
                                            placeholder={element.dataGroup.lineGroup1.mainInput.placeholder}
                                            resize="none"
                                            size="large"
                                        />
                                    </Box>
                                ) : (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntInput chooseType={element.dataGroup.lineGroup1.mainInput.type} placeholder={element.dataGroup.lineGroup1.mainInput.placeholder} size="large" />
                                    </Box>
                                )
                            )}
                            {element.dataGroup.lineGroup1.subButton.right.switch === true && (
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonTitle={element.dataGroup.lineGroup1.subButton.right.label}
                                        buttonType="default"
                                        onClick={element.dataGroup.lineGroup1.subButton.right.event}
                                    />
                                </Box>
                            )}
                        </Flex>
                    </Box>
                ),
                categoryTwoTitle: element.dataGroup.lineGroup2.title,
                categoryTwoData: (
                    <Box>
                        <Flex justify="flex-start" align="center" gap="12px" style={{ width: "100%" }}>
                            {element.dataGroup.lineGroup2.subButton.left.switch === true && (
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonTitle={element.dataGroup.lineGroup2.subButton.left.label}
                                        buttonType="default"
                                        onClick={element.dataGroup.lineGroup2.subButton.left.event}
                                    />
                                </Box>
                            )}
                            {element.dataGroup.lineGroup2.mainInput.switch === true && (
                                element.dataGroup.lineGroup2.mainInput.inputType === "textarea" ? (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntTextArea
                                            rows={4}
                                            placeholder={element.dataGroup.lineGroup2.mainInput.placeholder}
                                            resize="none"
                                            size="large"
                                        />
                                    </Box>
                                ) : (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntInput chooseType={element.dataGroup.lineGroup2.mainInput.type} placeholder={element.dataGroup.lineGroup2.mainInput.placeholder} size="large" />
                                    </Box>
                                )
                            )}
                            {element.dataGroup.lineGroup2.subButton.right.switch === true && (
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonTitle={element.dataGroup.lineGroup2.subButton.right.label}
                                        buttonType="default"
                                        onClick={element.dataGroup.lineGroup2.subButton.right.event}
                                    />
                                </Box>
                            )}
                        </Flex>
                    </Box>
                ),
                categoryThreeTitle: element.dataGroup.lineGroup3.title,
                categoryThreeData: (
                    <Box>
                        <Flex justify="flex-start" align="center" gap="12px" style={{ width: "100%" }}>
                            {element.dataGroup.lineGroup3.subButton.left.switch === true && (
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonTitle={element.dataGroup.lineGroup3.subButton.left.label}
                                        buttonType="default"
                                        onClick={element.dataGroup.lineGroup3.subButton.left.event}
                                    />
                                </Box>
                            )}
                            {element.dataGroup.lineGroup3.mainInput.switch === true && (
                                element.dataGroup.lineGroup3.mainInput.inputType === "textarea" ? (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntTextArea
                                            rows={4}
                                            placeholder={element.dataGroup.lineGroup3.mainInput.placeholder}
                                            resize="none"
                                            size="large"
                                        />
                                    </Box>
                                ) : (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntInput chooseType={element.dataGroup.lineGroup3.mainInput.type} placeholder={element.dataGroup.lineGroup3.mainInput.placeholder} size="large" />
                                    </Box>
                                )
                            )}
                            {element.dataGroup.lineGroup3.subButton.right.switch === true && (
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonTitle={element.dataGroup.lineGroup3.subButton.right.label}
                                        buttonType="default"
                                        onClick={element.dataGroup.lineGroup3.subButton.right.event}
                                    />
                                </Box>
                            )}
                        </Flex>
                    </Box>
                ),
            });
        }
    );

    return (
        <Box className="sectionMaterialFormControlWrap">
            <MypageSectionMaterialTableLayout tableData={profileDataArray} />
        </Box>
    );
}

export default SectionMaterialFormControl;
