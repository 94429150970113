import PrivateRoute from './privateRoute';

import { useRoutes } from "react-router-dom";
// import { lazy } from "react";
// import Loadable from "@loadable/component";

import Landing from "pages/landing";

// Route
import MainRoutes from "./mainRoutes";
import GeneralRoutes from "./generalRoutes";
import AdvertiserRoutes from "./advertiserRoutes";
import MediaCompanyRoutes from "./mediaCompanyRoutes";

import AuthRoutes from "./authRoutes";

import ErrorRoutes from "./errorRoutes";

function RouterControl() {
    return useRoutes([
        // ******************* Front-end 요청내용 *******************
        // 비로그인방문자, 일반회원, 광고주회원, 매체사회원
        // 각각 권한을 나누어서
        // 권한이 없으면 페이지에 접근조차 못하도록
        // private 기능을 넣어주시면 좋을것 같습니다.
        // 접근권한이 없는 유저가 url을 입력할 경우 '접근권한 없다' 화면
        // ********************************************************
        {
            path: "/",
            element: <Landing />,
        },
        {
            children: [
                {
                    ...MainRoutes,
                    element: (
                        <PrivateRoute allowedRoutes={['GeneralRoutes', 'AdvertiserRoutes']}>
                            {MainRoutes.element}
                        </PrivateRoute>
                    ),
                },
                {
                    ...GeneralRoutes,
                    element: (
                        <PrivateRoute allowedRoutes={['GeneralRoutes']}>
                            {GeneralRoutes.element}
                        </PrivateRoute>
                    ),
                },
                {
                    ...AdvertiserRoutes,
                    element: (
                        <PrivateRoute allowedRoutes={['AdvertiserRoutes']}>
                            {AdvertiserRoutes.element}
                        </PrivateRoute>
                    ),
                },
            ],
        },
        {
            // 로그인 후
            children: [
                MainRoutes, // (공통)
                GeneralRoutes, // (일반회원)
                AdvertiserRoutes, // (광고주회원)
                MediaCompanyRoutes, // (매체사회원)
            ],
        },
        {
            // 로그인 전
            children: [
                AuthRoutes, // (비로그인방문자)
            ],
        },
        {
            // Page Error
            children: [
                ErrorRoutes, // (error 페이지)
            ],
        },
    ]);
}

export default RouterControl;
