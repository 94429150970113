import axios from 'axios';

const API_URL = '/api/';

interface MemberData {
  id: string;
  password: string;
  name: string;
  nick: string;
  email: string;
  birth?: string;
  tell?: string;
  hp?: string;
  zipcode?: string;
  city?: string;
  image?: string;
  role?: string;
  allow_email: string;
  allow_sms: string;
  allow_contract: string;
}

export const createMember = async (memberData: MemberData) => {
  try {
    const response = await axios.post(`${API_URL}?cat=member&req=create`, memberData);
    const responseData = response.data[0]; // 배열의 첫 번째 요소를 사용

    if (responseData.answer=== "SUCCESS") {
      return { success: true };
    } else {
      return { success: false, message: responseData.answer };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return { success: false, message: `서버 오류: ${error.response.status}` };
      } else if (error.request) {
        return { success: false, message: '서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.' };
      } else {
        return { success: false, message: `요청 오류: ${error.message}` };
      }
    } else {
      const errorMessage = (error as Error).message;
      return { success: false, message: errorMessage };
    }
  }
};