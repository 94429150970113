import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionRadioControl from "pages/mypage/common/sectionRadioControl";
import SectionFormControl from "pages/mypage/common/sectionFormControl";

import { generalMenuItemsDataGroup } from "data/mypage/main";
import { businessUserProfileDataGroup } from "data/mypage/profile/businessUser";
import { businessUserTypeCategoryTitle, businessUserType } from "data/mypage/business";

import type { RadioChangeEvent } from "antd";

import { useBusinessProfile } from "hooks/useBusinessProfile";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function BusinessUserRequest({ className, style }: vcProps) {
    const navigate = useNavigate();

    const [radioSelectState, setRadioSelectState] = useState("advertiser");
    const [userSerial, setUserSerial] = useState<number>(0);

    const radioOnChangeEvent = (event: RadioChangeEvent) => {
        setRadioSelectState(event.target.value);
    };

    const subButtonEventArray = [
        // 회사명
        { event: undefined },
        // 대표자명
        { event: undefined },
        // 사업자등록번호
        {
            event: function businessIdentificationNumberCheckEvent() {
                console.log("사업자등록번호 인증");
            },
        },
        // 업태업종
        { event: undefined },
        // 주소
        {
            event: function findPostcodeCheckEvent() {
                console.log("우편번호 찾기");
            },
        },
        // 세금계산서이메일
        { event: undefined },
    ];

    function requestButtonEvent() {
        console.log("신청하기");
        navigate("./complete");
    }

    const userMergeData = [...businessUserProfileDataGroup];

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box sx={{ boxSizing: "border-box", padding: "8px 0" }}>
                <SectionRadioControl
                    titleData={businessUserTypeCategoryTitle}
                    onChange={radioOnChangeEvent}
                    value={radioSelectState}
                    options={businessUserType}
                />
            </Box>
            <SectionFormControl categoryInputData={userMergeData} subButtonEventData={subButtonEventArray} />
        </Box>
    );

    const topTitleData = generalMenuItemsDataGroup[1].label;

    return (
        <Box>
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="flex-end" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={requestButtonEvent}
                            buttonTitle="신청하기"
                            buttonSize="middle"
                            // icon={<RightOutlined />}
                            iconPosition="end"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default BusinessUserRequest;
