import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcSectionCard from "components/layout/sectionCard";
import VcAntButton from "components/antd/general/button";
import VcAntPaginationList from "components/custom/paginationList";

import KnowledgeServiceClassListLayout from "./layout";

import { knowledgeServiceClassNoticeText } from "data/knowledgeService/class";
import { classListInfoDataGroup, knowledgeServiceClassListTableHeader } from "data/knowledgeService/class";
import { useCategories } from "hooks/useCategories";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}
function KnowledgeServiceClassList({ className, style }: vcProps) {
    const navigate = useNavigate();
    
    const { data: categories, loading, error } = useCategories();

    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentPageState, setCurrentPageState] = useState<number>(1);
    const [postsPerPageState, setPostsPerPageState] = useState<number>(10);

    useEffect(() => {
        setPostsState(classListInfoDataGroup);
    }, []);

    const finalData: any[] = [];

    categories?.forEach((category: any, number: number) => {
        finalData.push(
            <Box key={number}>
                <KnowledgeServiceClassListLayout 
                    classTitle={category?.title} 
                    classPeriod={category?.period} 
                    classProgram={category?.content} 
                    classPath={`/knowledge-service/category-name1/${category?.serial}/detail`}
                />
            </Box>
        );
    });

    // classListInfoDataGroup.forEach((category, number) => {
    //     finalData.push(
    //         <Box key={number}>
    //             <KnowledgeServiceClassListLayout 
    //                 classTitle={categories?.[number]?.title} 
    //                 classPeriod={categories?.[number]?.period} 
    //                 classProgram={categories?.[number]?.content} 
    //                 classPath={`/knowledge-service/category-name1/${categories?.[number]?.serial}/detail`}
    //             />
    //         </Box>
    //     );
    // });

    const firstPostIndex = (currentPageState - 1) * postsPerPageState;
    const lastPostIndex = firstPostIndex + postsPerPageState;
    const currentPosts = finalData.slice(firstPostIndex, lastPostIndex);

    const bodyContentData2 = (
        <Box className="bodyContentDataBox">
            <Box className="knowledgeServiceClassListTableBox">
                <Box className="knowledgeServiceClassListTableInnerBox">
                    <table className="knowledgeServiceClassListTableTh">
                        <thead>
                            <tr className="classThRow">
                                <td className="classThCol tableThClassTitleBox">
                                    <Typography>{knowledgeServiceClassListTableHeader.classTitle}</Typography>
                                </td>
                                <td className="classThCol tableThClassDetailBox">
                                    <Typography>{knowledgeServiceClassListTableHeader.classDetail}</Typography>
                                </td>
                                <td className="classThCol tableThClassPeriodCountBox">
                                    <Typography>{knowledgeServiceClassListTableHeader.classPeriodCount}</Typography>
                                </td>
                                <td className="classThCol tableThClassProgramCountBox">
                                    <Typography>{knowledgeServiceClassListTableHeader.classProgramCount}</Typography>
                                </td>
                                <td className="classThCol tableThCakeClassButtonBox">
                                    <Typography>{knowledgeServiceClassListTableHeader.takeClass}</Typography>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Box>
            </Box>
            <VcAntPaginationList
                className="knowledgeServiceClassListPagination"
                listOptionData={currentPosts}
                postsItemCount={postsState.length}
                currentPage={currentPageState}
                setCurrentPage={setCurrentPageState}
                postsPerPage={postsPerPageState}
                itemGap={false}
            />
        </Box>
    );

    const bodyContentData1 = (
        <Box className="bodyContentDataBox bodyContentDataBox1">
            <Box className="classNoticeText">
                <Typography>{knowledgeServiceClassNoticeText}</Typography>
            </Box>
        </Box>
    );

    return (
        <Box className="knowledgeServiceClassListWrap">
            <VcSectionCard topTitle="수강 안내" bodyContent={bodyContentData1} />
            <VcSectionCard topTitle="강좌 정보" bodyContent={bodyContentData2} />
        </Box>
    );
}

export default KnowledgeServiceClassList;
