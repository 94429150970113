import React from "react";
import { Outlet } from "react-router-dom";

// import { Flex } from "antd";

import { Box } from "@mui/material";

import ContentCard from "components/layout/contentCard";

import { pageTitleData } from "data/mypage/main";

import "assets/scss/jy.scss";
import "assets/scss/page/mypage.scss";

function MypageIndex() {
    const bodyContentData = (
        <Box className="mypageBodyContent">
            <Box className="contentDataWrap">
                <Outlet />
            </Box>
        </Box>
    );

    return (
        <Box className="mypageWrap">
            {/* 마이페이지 */}
            <ContentCard topTitle={pageTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default MypageIndex;
