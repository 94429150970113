import axios from 'axios';

const API_URL = '/api/';

export const login = async (id: string, password: string) => {
  try {
    const response = await axios.post(`${API_URL}?cat=member&req=login`, { id, password });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // 서버가 2xx 범위를 벗어나는 상태 코드로 응답한 경우
        throw new Error(`서버 오류: ${error.response.status}`);
      } else if (error.request) {
        // 요청이 이루어졌으나 응답을 받지 못한 경우
        throw new Error('서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.');
      } else {
        // 요청 설정 중에 오류가 발생한 경우
        throw new Error(`요청 오류: ${error.message}`);
      }
    } else {
      // 알 수 없는 오류
      throw new Error('알 수 없는 오류가 발생했습니다.');
    }
  }
};