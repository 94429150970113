import React, { useState } from "react";

import { Flex, Typography, Row, Col } from "antd";

import { Box } from "@mui/material";

import ChartComp from "../chart";

import { unitDataGroup } from "data/advertiser/advertising/media";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    fillBookingStateArray?: any;
    mediaDataGroupData?: any;
    mediaDataGroupIndex?: any;
    bookingResultViewOnClickEvent?: any;
}

function BookingApplyMediaLayout({ className, style, fillBookingStateArray, mediaDataGroupData, mediaDataGroupIndex, bookingResultViewOnClickEvent }: vcProps) {
    return (
        <Box className="bookingApplyMediaLayoutWrap">
            <Box className="bookingApplyMediaBox" onClick={bookingResultViewOnClickEvent}>
                {fillBookingStateArray !== undefined ? (
                    <Box
                        className="bookingStateBox"
                        sx={
                            fillBookingStateArray[mediaDataGroupIndex] === "대기"
                                ? { backgroundColor: "#419aff" }
                                : fillBookingStateArray[mediaDataGroupIndex] === "수락"
                                ? { backgroundColor: "#06b000" }
                                : fillBookingStateArray[mediaDataGroupIndex] === "거절"
                                ? { backgroundColor: "#f76b6b" }
                                : fillBookingStateArray[mediaDataGroupIndex] === "취소"
                                ? { backgroundColor: "#a0a0a0" }
                                : {}
                        }
                    >
                        <Typography>{fillBookingStateArray[mediaDataGroupIndex]}</Typography>
                    </Box>
                ) : null}
                <Box className="mediaBorderBox">
                    <Box className="mediaBoxInner">
                        <Box className="mediaRowBox">
                            <Box className="mediaColBox mediaColBox1Group">
                                <Box className="imgBox">
                                    <img src={mediaDataGroupData.bannerImg} alt="배너이미지" />
                                </Box>
                            </Box>
                            <Box className="mediaColBox mediaColBox1Group">
                                <Box className="chartBox">
                                    <Flex
                                        justify="center"
                                        align="center"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <ChartComp chartBoxWidth={190} chartBoxHeight={130} chartSize={240} />
                                    </Flex>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="mediaRowBox">
                            <Box className="mediaColBox mediaColBox2Group">
                                <Box className="mediaInfoBox">
                                    <Box className="badgeBox">
                                        <Box className="infoTitle">
                                            <Typography>{mediaDataGroupData.information.name}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="infoLine1">
                                        <Box className="infoCharacter">
                                            <Typography>{mediaDataGroupData.information.character}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="infoLine2">
                                        <Box className="infoSize">
                                            <Typography>
                                                {mediaDataGroupData.information.size.displayHorizontalValue.toLocaleString("ko-KR")}
                                                {unitDataGroup.information.size.displayHorizontalUnit}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    boxSizing: "border-box",
                                                    padding: "0 4px",
                                                }}
                                            >
                                                {unitDataGroup.information.multiply}
                                            </Typography>
                                            <Typography>
                                                {mediaDataGroupData.information.size.displayVerticalValue.toLocaleString("ko-KR")}
                                                {unitDataGroup.information.size.displayVerticalUnit}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    boxSizing: "border-box",
                                                    paddingLeft: "8px",
                                                }}
                                            >
                                                &#40;
                                                {mediaDataGroupData.information.size.displayTotalValue.toLocaleString("ko-KR")}
                                                {unitDataGroup.information.size.displayTotalUnit}
                                                &#41;
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="infoLine3">
                                        <Box className="infoPrice">
                                            <Flex justify="flex-start" align="flex-end">
                                                <Typography className="priceDateUnit">{unitDataGroup.price.dateUnit}</Typography>
                                                <Typography className="priceValue">{mediaDataGroupData.price.value.toLocaleString("ko-KR")}</Typography>
                                                <Typography className="priceValueUnit">{unitDataGroup.price.valueUnit}</Typography>
                                                <Typography className="priceEtc">{unitDataGroup.price.priceEtc}</Typography>
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="mediaColBox mediaColBox2Group">
                                <Box className="matchingDataBox">
                                    <Box className="badgeBox">
                                        <Box className="matchingTitle">
                                            <Typography>{unitDataGroup.matching.percentTitle}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="mediaMatching">
                                        <Box className="matchingLine1">
                                            <Box className="matchingPercent">
                                                <Flex justify="flex-end" align="flex-end">
                                                    <Box className="matchingPercentValue">
                                                        <Typography>{mediaDataGroupData.matching.percentValue}</Typography>
                                                    </Box>
                                                    <Box className="matchingPercentUnit">
                                                        <Typography>{unitDataGroup.matching.percentUnit}</Typography>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Box>
                                        <Box className="matchingLine2">
                                            <Box className="matchingDescription">
                                                <Typography.Paragraph ellipsis={{ rows: 2 }}>{mediaDataGroupData.matching.description}</Typography.Paragraph>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default BookingApplyMediaLayout;
