export const materialListDataGroup = [
    {
        imgPath: "https://picsum.photos/230/230",
        materialName: "소재명1",
        materialPreview: "https://picsum.photos/250/250",
        description:
            "Laborum adipisicing eiusmod cillum magna ad proident id cupidatat consectetur voluptate occaecat non commodo labore. Ad voluptate id nostrud esse cillum amet dolore ullamco ullamco culpa incididunt. Proident laboris laborum do ea aliquip tempor irure sit labore do ut reprehenderit non. Id nostrud adipisicing do veniam aliqua Lorem pariatur eiusmod incididunt non dolor ex proident excepteur. Dolore veniam minim anim exercitation esse do aliqua.",
    },
    {
        imgPath: "https://picsum.photos/231/231",
        materialName: "소재명2",
        materialPreview: "https://picsum.photos/251/251",
        description:
            "Sint sit et id fugiat velit nostrud amet amet. Nulla cillum tempor nisi laborum laborum cillum ut incididunt sit. Culpa in cillum cupidatat duis id sit occaecat sint. Nostrud mollit ullamco aliquip consequat sunt ut aliquip est cupidatat consequat excepteur culpa. Anim consequat magna consequat ea tempor.",
    },
    {
        imgPath: "https://picsum.photos/232/232",
        materialName: "소재명3",
        materialPreview: "https://picsum.photos/252/252",
        description:
            "Qui sit excepteur ut excepteur cupidatat proident quis magna eu Lorem nisi. Occaecat sint anim duis excepteur exercitation nisi incididunt enim. Officia nulla consectetur ullamco qui aliquip consectetur laboris officia cupidatat ullamco deserunt nulla. Labore nisi id exercitation Lorem pariatur magna amet duis dolor aliquip anim exercitation. Esse proident ut ea ipsum in mollit veniam cupidatat magna ullamco culpa nisi cupidatat ea. Aute Lorem dolor deserunt velit tempor fugiat laboris exercitation veniam nisi dolor pariatur id.",
    },
    {
        imgPath: "https://picsum.photos/233/233",
        materialName: "소재명4",
        materialPreview: "https://picsum.photos/253/253",
        description:
            "Eiusmod proident amet ea officia. Mollit dolor sunt enim nulla commodo officia excepteur consequat ad commodo dolore. Tempor ipsum voluptate mollit cillum exercitation veniam sint labore. Sit do sit laboris pariatur. Voluptate pariatur pariatur minim enim.",
    },
    {
        imgPath: "https://picsum.photos/234/234",
        materialName: "소재명5",
        materialPreview: "https://picsum.photos/254/254",
        description:
            "Anim non exercitation reprehenderit ex. Deserunt aute irure mollit consectetur nisi. Proident sit pariatur velit Lorem non adipisicing enim dolor deserunt eu exercitation sunt do eu. Cupidatat minim ut exercitation fugiat. Aliquip ipsum sunt officia occaecat.",
    },
    {
        imgPath: "https://picsum.photos/235/235",
        materialName: "소재명6",
        materialPreview: "https://picsum.photos/255/255",
        description:
            "Cupidatat voluptate officia ea aliquip consequat sunt magna in sunt reprehenderit. Pariatur labore consectetur consequat consectetur irure do consectetur nostrud nisi. Ad sint officia sint occaecat anim mollit adipisicing. Incididunt enim Lorem dolor sint irure laboris ullamco reprehenderit amet deserunt duis sunt.",
    },
    {
        imgPath: "https://picsum.photos/236/236",
        materialName: "소재명7",
        materialPreview: "https://picsum.photos/256/256",
        description:
            "Proident irure exercitation amet incididunt anim. Dolor pariatur amet pariatur irure proident nostrud exercitation cupidatat minim aliquip eiusmod commodo. Anim pariatur culpa veniam id in aute commodo eiusmod ea anim magna proident sint officia. Aliqua nostrud fugiat duis est tempor aute laborum dolore esse ipsum mollit ipsum. Laborum et nostrud ex voluptate voluptate anim deserunt et ullamco.",
    },
    {
        imgPath: "https://picsum.photos/237/237",
        materialName: "소재명8",
        materialPreview: "https://picsum.photos/257/257",
        description:
            "Tempor nulla excepteur magna deserunt aliqua do non ea adipisicing nostrud. Aute dolor commodo labore labore. Aute nulla cillum officia veniam mollit. Nisi esse eu dolor ea ut est id Lorem non veniam magna. Ea voluptate duis occaecat magna proident do Lorem laboris est ea. Nulla laboris non ipsum tempor duis non occaecat tempor proident consectetur dolore enim consectetur nulla.",
    },
    {
        imgPath: "https://picsum.photos/238/238",
        materialName: "소재명9",
        materialPreview: "https://picsum.photos/258/258",
        description:
            "Magna nulla ipsum laborum dolore fugiat sit commodo ipsum sint cillum quis et. Est officia anim sunt irure ipsum cupidatat tempor veniam ea ea. Qui consectetur magna ullamco ullamco amet irure enim eiusmod deserunt.",
    },
    {
        imgPath: "https://picsum.photos/239/239",
        materialName: "소재명10",
        materialPreview: "https://picsum.photos/259/259",
        description:
            "Sint esse officia veniam sit id. Adipisicing sit nisi nisi sit. Fugiat consequat et anim non dolor aliquip laborum non officia eu deserunt reprehenderit. In fugiat dolor sint pariatur aute. Minim enim excepteur voluptate incididunt eiusmod deserunt dolore veniam consequat laboris minim.",
    },
    {
        imgPath: "https://picsum.photos/240/240",
        materialName: "소재명11",
        materialPreview: "https://picsum.photos/260/260",
        description:
            "Eu in minim consequat laboris quis anim duis dolor cillum pariatur non qui. Mollit cillum est ullamco exercitation qui dolore. Minim enim reprehenderit qui elit nisi ad culpa esse proident. Irure fugiat anim deserunt aliquip incididunt enim fugiat anim et. Enim voluptate duis qui laboris.",
    },
];
