export const contractListDataGroup = [
    {
        imgPath: "https://picsum.photos/210/210",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "check",
    },
    {
        imgPath: "https://picsum.photos/211/211",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "check",
    },
    {
        imgPath: "https://picsum.photos/212/212",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "check",
    },
    {
        imgPath: "https://picsum.photos/213/213",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "check",
    },
    {
        imgPath: "https://picsum.photos/214/214",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "check",
    },
    {
        imgPath: "https://picsum.photos/215/215",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "check",
    },
    {
        imgPath: "https://picsum.photos/216/216",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "check",
    },
    {
        imgPath: "https://picsum.photos/217/217",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/218/218",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/219/219",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/220/220",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/221/221",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/222/222",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/223/223",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/224/224",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/225/225",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/226/226",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/227/227",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
    {
        imgPath: "https://picsum.photos/228/228",
        mediaCompanyName: "매체명",
        description: "광고주 파트너사명",
        date: "2024.02.07 ~ 2024.03.07",
        price: 500000000,
        state: "confirm",
    },
];

export const priceUnit = "원";

export const contractFilterRadioData = [
    { label: "전체", value: "all" },
    { label: "광고검수", value: "check" },
    { label: "광고확정", value: "confirm" },
];
