import React, { useState } from "react";

import { Flex, Modal, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";
import VcAntModal from "components/antd/feedback/modal";

import "assets/scss/jy.scss";

import { changePwModalDataGroup } from "data/mypage/common/receiveAgree";

interface vcProps {
    // wrapClassName?: string;
    // classNames?: string;
    style?: React.CSSProperties;
    // styles?: React.CSSProperties;
    open?: boolean;
    onOk?: any;
    onCancel?: any;
    width?: string | number;
    titleText?: string;
    contentBodyData?: React.ReactNode;
    contentFooterData?: React.ReactNode;
    footerSwitch?: boolean;
    footer?: React.ReactNode;
    footerItem?: React.ReactNode;
    maskClosable?: boolean;
    centered?: boolean;

    // afterClose?: any;
    // cancelButtonProps?: any;
    // cancelText?: React.ReactNode;
    // closable?: boolean | { closeIcon?: React.ReactNode };
    // closeIcon?: React.ReactNode;
    // confirmLoading?: boolean;
    // destroyOnClose?: boolean;
    // focusTriggerAfterClose?: boolean;
    // forceRender?: boolean;
    // getContainer?: any;
    // keyboard?: boolean;
    // mask?: boolean;
    // modalRender?: React.ReactNode;
    // okButtonProps?: any;
    // okText?: React.ReactNode;
    // okType?: string;
    // loading?: boolean;
    // title?: React.ReactNode;
    // zIndex?: number;
    // afterOpenChange?: any;
}

function VcModalPopup({
    // wrapClassName,
    // classNames,
    style,
    // styles,
    open,
    onOk,
    onCancel,
    width,
    titleText,
    contentBodyData,
    contentFooterData,
    footerSwitch,
    footer,
    footerItem,
    maskClosable,
    centered,
}: // afterClose,
// cancelButtonProps,
// cancelText,
// closable,
// closeIcon,
// confirmLoading,
// destroyOnClose,
// focusTriggerAfterClose,
// forceRender,
// getContainer,
// keyboard,
// mask,
// modalRender,
// okButtonProps,
// okText,
// okType,
// loading,
// title,
// zIndex,
// afterOpenChange,
vcProps) {
    function changePwModalCancelEvent() {
        // console.log("취소");
        onCancel();
    }
    function changePwModalPwChangeAlertEvent() {
        // console.log("비밀번호 변경");
        onCancel();
    }

    const modalClassNames = {
        mask: "vcModalPopupMask",
        content: "vcModalPopupContent",
        header: "vcModalPopupHeader",
        body: "vcModalPopupBody",
        footer: "vcModalPopupFooter",
    };

    const vcModalPopupInnerDetailData = (
        <Box className="detailDataWrap">
            <Box className="detailHeaderBox">
                <Typography>{titleText}</Typography>
            </Box>

            <Box className="detailBodyBox">
                <Box className="detailMainBox">{contentBodyData}</Box>
                <Box className="detailFooterBox">{contentFooterData}</Box>
            </Box>
        </Box>
    );

    return (
        <Box>
            <VcAntModal
                wrapClassName="vcModalPopupWrap"
                classNames={modalClassNames}
                open={open}
                onOk={onOk}
                onCancel={onCancel}
                width={width}
                {...(footerSwitch === true
                    ? {
                          footer: footerItem,
                      }
                    : { footer: null })}
                maskClosable={false}
                centered={true}
                mainContent={vcModalPopupInnerDetailData}
            />
        </Box>
    );
}

export default VcModalPopup;
