// Layout
import MainLayout from "layout/mainLayout";

import MypageIndex from "pages/mypage/index";
import GeneralMypageLayout from "pages/mypage/general/mypageLayout";

import GeneralProfile from "pages/mypage/general/profile";
import BusinessUserRequest from "pages/mypage/general/businessUserRequest";
import BusinessUserRequestComplete from "pages/mypage/general/businessUserRequest/complete";

import GeneralSetting from "pages/mypage/general/setting";

// ==============================|| GENERIAL ROUTING ||============================== //

const GeneralRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "mypage",
            element: <MypageIndex />,
            children: [
                {
                    path: "general",
                    element: <GeneralMypageLayout />,
                    children: [
                        {
                            path: "profile",
                            element: <GeneralProfile />,
                        },
                        {
                            path: "business-user-request",
                            element: <BusinessUserRequest />,
                        },
                        {
                            path: "business-user-request/complete",
                            element: <BusinessUserRequestComplete />,
                        },
                        {
                            path: "setting",
                            element: <GeneralSetting />,
                        },
                    ],
                },
            ],
        },
    ],
};

export default GeneralRoutes;
