import React, { useEffect } from "react";
import Routes from "routes";
import $ from "jquery";

import { Box } from "@mui/material";

import "assets/scss/common/reset.scss";
import "assets/scss/standard/layout.scss";
import "assets/scss/standard/header.scss";
import "assets/scss/standard/content.scss";
import "assets/scss/standard/footer.scss";
import "assets/scss/standard/error.scss";

// import bodyBackgroundImg from "assets/images/sample/sample_img.jpg";

function App() {
    // useEffect(() => {
    // $("body").css("backgroundImage", `url(${bodyBackgroundImg})`);
    // }, []);

    return (
        <Box
            className="App"
            sx={{ "& *": { fontFamily: "pretendardRegular" } }}
        >
            <Routes />
        </Box>
    );
}

export default App;
