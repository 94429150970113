import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import { advertiserPartnerRegisterCompleteDataGroup } from "data/mypage/advertiser/partner/register";

function AdvertiserPartnerRegisterComplete() {
    const navigate = useNavigate();

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="partnerRegisterCompleteBodyContent">
                <Box className="contentDataWrap">
                    <Flex vertical justify="flex-start" align="center">
                        <Box className="imageBox">
                            <Box className="imageBoxText">IMG</Box>
                            <Box className="imageFile"></Box>
                        </Box>
                        <Box className="guidebox">
                            <Box className="guideTextBox">
                                <Typography>
                                    {
                                        advertiserPartnerRegisterCompleteDataGroup.guideText
                                    }
                                </Typography>
                            </Box>
                            <Box className="guideDescriptionBox">
                                <Typography>
                                    {
                                        advertiserPartnerRegisterCompleteDataGroup.guideDescription
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="buttonLine">
                            <Flex justify="flex-start" align="center" gap="8px">
                                <Box className="informationDetailButtonBox">
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonType="default"
                                        onClick={() => {
                                            console.log(
                                                "광고주 계정 정보 상세"
                                            );
                                        }}
                                        buttonTitle="파트너사 정보 상세"
                                        buttonSize="large"
                                    />
                                </Box>
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonType="default"
                                        onClick={() => {
                                            console.log("목록으로 !");
                                        }}
                                        buttonTitle="목록으로"
                                        buttonSize="large"
                                    />
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box className="advertiserPartnerRegisterCompleteWrap">
            {/* 사업자 신청 - 완료 */}
            <MypageSectionCard
                topTitle="파트너사 등록 완료"
                bodyContent={bodyContentData}
            />
        </Box>
    );
}

export default AdvertiserPartnerRegisterComplete;
