import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import { GlobalStyle } from "styles/globalFont";
import App from "./App";

import { ThemeProvider } from "@mui/material";
import { unstable_createMuiStrictModeTheme } from "@mui/material";

const theme = unstable_createMuiStrictModeTheme();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <ThemeProvider theme={theme}>
        <React.StrictMode>
            <BrowserRouter>
                <GlobalStyle />
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </ThemeProvider>
);
