// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  width: 100vw;
  height: 100vh;
  background: #333;
}

.App {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.App .mainLayout {
  width: auto;
  height: auto;
}
.App .mainLayout .bodyWrap {
  width: 100%;
  min-width: 1240px;
  height: auto;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/standard/layout.scss"],"names":[],"mappings":"AAIA;EACI,YAAA;EACA,aAAA;EACA,gBAAA;AAHJ;;AASA;EACI,WAAA;EACA,YAAA;EACA,cAAA;AANJ;AAQI;EACI,WAAA;EACA,YAAA;AANR;AAQQ;EACI,WAAA;EACA,iBAAA;EACA,YAAA;EACA,cAAA;AANZ","sourcesContent":["// * * * * * * * * * * * * * * DEFAULT LAYOUT * * * * * * * * * * * * * *\n\n@import \"../common/palette.scss\";\n\nbody {\n    width: 100vw;\n    height: 100vh;\n    background: #333;\n    // background-repeat: no-repeat;\n    // background-size: cover;\n    // background-position: center center;\n}\n\n.App {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n\n    .mainLayout {\n        width: auto;\n        height: auto;\n\n        .bodyWrap {\n            width: 100%;\n            min-width: 1240px;\n            height: auto;\n            margin: 0 auto;\n        }\n    }\n}\n\n// * * * * * * * * * * * * * * PAGE * * * * * * * * * * * * * *\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
