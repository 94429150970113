export const bookingListDataGroup = [
    { imgPath: "https://picsum.photos/220/220", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/221/221", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/222/222", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/223/223", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/224/224", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/225/225", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/226/226", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/227/227", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/228/228", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/229/229", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/230/230", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
    { imgPath: "https://picsum.photos/231/231", campaignName: "캠페인명", description: "매체명 샬라샬라 외 5" },
];
