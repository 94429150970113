export const generalUserProfileDataGroup = [
    {
        title: "아이디",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "userid01",
            value: "",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "비밀번호",
        mainInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: true,
                label: "변경하기",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "휴대폰",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "010-1234-5678",
            value: "",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: true,
                label: "인증번호",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "인증번호",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "인증번호를 입력해주세요",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: true,
                label: "인증확인",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
];

// sample
// {
//     title: "타이틀",
//     mainInput: {
//         switch: true,
//         type: "basic",
//         placeholder: "",
//     },
//     secondInput: {
//         switch: false,
//         type: "basic",
//         placeholder: "",
//     },
//     subButton: {
//         left: {
//             switch: false,
//             label: "",
//         },
//         right: {
//             switch: false,
//             label: "",
//         },
//     },
//     moreInput: {
//         switch: false,
//         type: "basic",
//         placeholder: "",
//     },
// },
