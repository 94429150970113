import React, { ReactNode } from "react";

import { Input } from "antd";

import { Box } from "@mui/material";

import { VcAntTextAreaTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    fullWidth?: "100%" | "auto" | "flex" | undefined;
    rows?: number;
    placeholder?: string;
    value?: string;
    size?: "small" | "middle" | "large" | undefined;
    resize?: "none" | "auto" | undefined;
    readonly?: boolean;
    id?: string;
}

const { TextArea } = Input;

function VcAntTextArea({ className, style, fullWidth, rows, placeholder, value, size, resize, id }: vcProps) {
    const setFullWidth = fullWidth === "100%" ? { width: "100%" } : fullWidth === "auto" ? { width: "auto" } : fullWidth === "flex" ? { flex: 1 } : {};
    const setStyle = {
        "& .ant-input": {
            ...setFullWidth,
            borderRadius: VcAntTextAreaTheme.borderRadius,
            ...(resize === "none" ? { resize: "none" } : resize === "auto" ? { resize: "auto" } : {}),
        },
        ...style,
    };

    return (
        <Box sx={setStyle}>
            <TextArea rows={rows} placeholder={placeholder} value={value} size={size} id={id}/>
        </Box>
    );
}

export default VcAntTextArea;
