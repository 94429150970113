import React, { useState } from "react";

import { Flex, Modal, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";
import VcAntModal from "components/antd/feedback/modal";

import "assets/scss/jy.scss";

import { changePwModalDataGroup } from "data/mypage/common/receiveAgree";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    open?: boolean;
    onOk?: any;
    onCancel?: any;
}

function VcChangePasswordModal({ className, style, open, onOk, onCancel }: vcProps) {
    function changePwModalCancelEvent() {
        // console.log("취소");
        onCancel();
    }
    function changePwModalPwChangeAlertEvent() {
        console.log("비밀번호 변경");
        // alert("비밀번호가 변경되었습니다");
        // onCancel();
    }

    const setStyles = {
        "&": {},
        ...style,
    };

    const modalClassNames = {
        mask: "changePwModalMask",
        content: "changePwModalContent",
        header: "changePwModalHeader",
        body: "changePwModalBody",
        footer: "changePwModalFooter",
    };

    const changePwModalInnerDetailData = (
        <Box className="detailDataWrap">
            <Box className="detailHeaderBox">
                <Typography>{changePwModalDataGroup.modalTitle}</Typography>
            </Box>

            <Box className="detailBodyBox">
                <Box className="detailMainBox">
                    <Flex vertical justify="flex-start" align="center" gap="24px">
                        {/* <Box className="messageBox">
                            <pre>
                                <Typography>{changePwModalDataGroup.modalBody.message}</Typography>
                            </pre>
                        </Box> */}
                        <Box className="changeBox">
                            <Box className="changeFormGroup">
                                <Flex justify="space-between" align="center">
                                    <Box className="changeFormTitleBox">
                                        <Typography>{changePwModalDataGroup.modalBody.inputProp.currentPwInput.label}</Typography>
                                    </Box>
                                    <Box className="changeFormInputBox">
                                        <VcAntInput
                                            chooseType="basic"
                                            placeholder={changePwModalDataGroup.modalBody.inputProp.currentPwInput.placeHolder}
                                            size="large"
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                            <Box className="changeFormGroup">
                                <Flex justify="space-between" align="center">
                                    <Box className="changeFormTitleBox">
                                        <Typography>{changePwModalDataGroup.modalBody.inputProp.changePwInput.label}</Typography>
                                    </Box>
                                    <Box className="changeFormInputBox">
                                        <VcAntInput
                                            chooseType="basic"
                                            placeholder={changePwModalDataGroup.modalBody.inputProp.changePwInput.placeHolder}
                                            size="large"
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                            <Box className="changeFormGroup">
                                <Flex justify="space-between" align="center">
                                    <Box className="changeFormTitleBox">
                                        <Typography>{changePwModalDataGroup.modalBody.inputProp.changePwReInput.label}</Typography>
                                    </Box>
                                    <Box className="changeFormInputBox">
                                        <VcAntInput
                                            chooseType="basic"
                                            placeholder={changePwModalDataGroup.modalBody.inputProp.changePwReInput.placeHolder}
                                            size="large"
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>

                <Box className="detailFooterBox">
                    <Box className="footerButtonGroupBox">
                        <Flex justify="flex-start" align="center" gap="12px">
                            <Box className="footerButtonBox footerLoginButton">
                                <VcAntButton
                                    conceptType="clear"
                                    buttonType="default"
                                    onClick={changePwModalCancelEvent}
                                    buttonTitle="취소"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                            <Box className="footerButtonBox footerFindPwButton">
                                <VcAntButton
                                    conceptType="concept"
                                    buttonType="default"
                                    onClick={changePwModalPwChangeAlertEvent}
                                    buttonTitle="비밀번호 변경"
                                    buttonSize="large"
                                    fullWidth="100%"
                                />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box sx={setStyles}>
            <VcAntModal
                open={open}
                onCancel={onCancel}
                classNames={modalClassNames}
                wrapClassName="changePasswordModal"
                footer={null}
                maskClosable={false}
                centered={true}
                width="auto"
                mainContent={changePwModalInnerDetailData}
            />
        </Box>
    );
}

export default VcChangePasswordModal;
