import React from "react";

import { Checkbox, Button } from "antd";

import { Box } from "@mui/material";

import { VcAdButtonCheckBoxTheme } from "theme/page";

import "assets/scss/components.scss";

interface vcProps {
    buttonClassName?: string;
    buttonStyle?: React.CSSProperties | undefined;
    buttonType?: "primary" | "dashed" | "link" | "text" | "default" | undefined;
    buttonOnClick?: any;
    buttonValue?: any;
    buttonLabel?: string;
    buttonSize?: "small" | "middle" | "large" | undefined;
    fullWidth?: "100%" | "flex" | "auto" | undefined;
    checkboxClassName?: string;
    checkboxStyle?: React.CSSProperties | undefined;
    checkboxChecked?: boolean;
    checkboxOnChange?: any;
    checkboxValue?: any;
    checkboxLabel?: string;
}

function AdButtonCheckboxComp({
    buttonClassName,
    buttonStyle,
    buttonType,
    buttonOnClick,
    buttonValue,
    buttonLabel,
    buttonSize,
    fullWidth,
    checkboxClassName,
    checkboxStyle,
    checkboxChecked,
    checkboxOnChange,
    checkboxValue,
    checkboxLabel,
}: vcProps) {
    const setFullWidth = fullWidth === "100%" ? { width: "100%" } : fullWidth === "auto" ? { width: "auto" } : fullWidth === "flex" ? { flex: 1 } : {};
    const componentSetStyles = {
        "&": {
            ...setFullWidth,
            // 버튼
            "& .ant-btn": {
                background: VcAdButtonCheckBoxTheme.backgroundColorClear,
                borderColor: VcAdButtonCheckBoxTheme.borderColor,
                borderRadius: VcAdButtonCheckBoxTheme.borderRadius,
                color: VcAdButtonCheckBoxTheme.fontColorWhite,
                // 버튼 - 기본 default
                "&.ant-btn-default": {
                    background: VcAdButtonCheckBoxTheme.backgroundColorClear,
                    borderColor: VcAdButtonCheckBoxTheme.borderColor,
                    color: VcAdButtonCheckBoxTheme.fontColorWhite,
                    // 버튼 - hover 호버
                    "&:hover": {
                        background: VcAdButtonCheckBoxTheme.backgroundColorHover,
                        borderColor: VcAdButtonCheckBoxTheme.borderColor,
                        color: VcAdButtonCheckBoxTheme.fontColorBlack,
                    },
                },
                // 버튼 - 선택 active primary
                "&.ant-btn-primary": {
                    background: VcAdButtonCheckBoxTheme.backgroundColorFull,
                    borderColor: VcAdButtonCheckBoxTheme.borderColor,
                    color: VcAdButtonCheckBoxTheme.fontColorBlack,
                    // 버튼 - 선택 active hover 호버
                    "&:hover": {
                        background: VcAdButtonCheckBoxTheme.backgroundColorHover,
                        borderColor: VcAdButtonCheckBoxTheme.borderColor,
                        color: VcAdButtonCheckBoxTheme.fontColorBlack,
                    },
                },
            },

            // 체크박스
            "& .ant-checkbox-wrapper": {
                color: VcAdButtonCheckBoxTheme.fontColorWhite,
                display: "none",
                "&.ant-checkbox-wrapper-checked": {},
                // 체크박스 hover 호버
                "&:hover": {
                    "& .ant-checkbox-inner": { borderColor: VcAdButtonCheckBoxTheme.borderColor },
                    // 체크박스 active 액티브
                    "& .ant-checkbox-checked": {
                        "& .ant-checkbox-inner": {
                            background: VcAdButtonCheckBoxTheme.backgroundColorClear,
                            borderColor: VcAdButtonCheckBoxTheme.borderColor,
                        },
                    },
                },
                "& .ant-checkbox-inner": {
                    background: VcAdButtonCheckBoxTheme.backgroundColorClear,
                    borderColor: VcAdButtonCheckBoxTheme.borderColor,
                },
            },
        },
    };

    return (
        <Box className="StCompButtonCheckBoxWrap" sx={componentSetStyles}>
            <Button
                className={buttonClassName}
                style={buttonStyle}
                type={buttonType}
                onClick={buttonOnClick}
                value={buttonValue}
                size={buttonSize}
                {...(fullWidth === "100%" && {
                    block: true,
                })}
            >
                {buttonLabel}
            </Button>
            <Checkbox className={checkboxClassName} style={checkboxStyle} checked={checkboxChecked} onChange={checkboxOnChange} value={checkboxValue}>
                {checkboxLabel}
            </Checkbox>
        </Box>
    );
}

export default AdButtonCheckboxComp;
