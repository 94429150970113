import React, { useEffect, useState } from "react";

import { Row, Col, Typography, Flex } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    tableData?: any;
}

function AuthTableLayout({ className, style, tableData }: vcProps) {
    const [categoryAllDataArrayState, setCategoryAllDataArrayState] = useState<any[]>([]);

    useEffect(() => {
        const authJoinDataTitleArray = tableData.map((data: any, index: number) => data.categoryTitle);
        const authJoinDataTitleArrayLength = authJoinDataTitleArray.length;
        const authJoinDataEmptyArray = [...Array(authJoinDataTitleArrayLength)];
        const authJoinDataContentArray = tableData.map((data: any, index: number) => data.categoryData);
        const authJoinAllDataArray = [];

        for (let i = 0; i < authJoinDataTitleArrayLength; i++) {
            authJoinDataEmptyArray[i] = Array.from(authJoinDataTitleArray[i]);
            authJoinAllDataArray[i] = { title: authJoinDataTitleArray[i], breakTitle: authJoinDataEmptyArray[i], content: authJoinDataContentArray[i] };
        }

        setCategoryAllDataArrayState(authJoinAllDataArray);
    }, [tableData]);

    return (
        <Box>
            {categoryAllDataArrayState.map((data: any, index: number) => {
                const subBreakTitle = data.breakTitle;
                return (
                    <Row gutter={[16, 16]} justify="center" align="middle" key={index}>
                        <Col span={5}>
                            <Box className="categoryTitleBox">
                                <Box className="categoryTitleBoxInner">
                                    <Flex justify="space-between" align="center">
                                        {subBreakTitle.map((data: any, index: number) => {
                                            return <Typography key={index}>{data}</Typography>;
                                        })}
                                    </Flex>
                                </Box>
                            </Box>
                        </Col>
                        <Col span={19}>
                            <Box className="categoryDataBox">{data.content}</Box>
                        </Col>
                    </Row>
                );
            })}
        </Box>
    );
}

export default AuthTableLayout;
