export const campaignRadioOptionData = [
    { label: "기존 캠페인", value: "existing" },
    { label: "신규 캠페인", value: "new" },
];

export const campaignTabsItemsData = [
    { key: "existing", label: "기존 캠페인" },
    { key: "new", label: "신규 캠페인" },
];

export const campaignControlDataGroup = { title: "캠페인선택" };

export const existingCampaignDataGroup = [
    {
        title: "캠페인",
        placeholder: "캠페인명을 선택해주세요",
        option: [
            { label: "캠페인명1", value: "campaign1" },
            { label: "캠페인명2", value: "campaign2" },
            { label: "캠페인명3", value: "campaign3" },
            { label: "캠페인명4", value: "campaign4" },
            { label: "캠페인명5", value: "campaign5" },
        ],
    },
];

export const newCampaignDataGroup = [
    { title: "캠페인", placeholder: "캠페인명을 입력해주세요" },
    { title: "캠페인내용", placeholder: "캠페인 내용을 입력해주세요" },
    { title: "광고소재", placeholder: "" },
];

export const newCampaignMaterialDataGroup = {
    commonPlaceholder: {
        title: "광고 소재명을 입력해주세요",
        url: "광고 소재 URL을 입력해주세요",
        content: "광고 소재 내용을 입력해주세요",
    },
    data: [
        { title: "", url: "", content: "" },
        { title: "", url: "", content: "" },
    ],
};
