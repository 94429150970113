import React from "react";

import { Flex, Progress, Typography } from "antd";

import { Box } from "@mui/material";

import { Height, WidthFull } from "@mui/icons-material";

function AdvertisingAiLoading() {
    const loadingPercentValue = 37;
    const descriptionText = "AI 추천 중 입니다";

    const progressBarCode = (percent: number) => <Progress percent={percent} size="small" status="active" strokeColor="#173e77" />;

    return (
        <Box className="aiLoadingDataWrap">
            <Box className="percentBox">
                <Flex justify="center" align="flex-end">
                    <Box className="percentValue">
                        <Typography>{loadingPercentValue}</Typography>
                    </Box>
                    <Box className="percentUnit">
                        <Typography>%</Typography>
                    </Box>
                </Flex>
            </Box>
            <Box className="descriptionBox">
                <Box className="text">
                    <Typography>{descriptionText}</Typography>
                </Box>
            </Box>
            <Box className="progressBox">
                <Box className="progress">{progressBarCode(loadingPercentValue)}</Box>
            </Box>
        </Box>
    );
}

export default AdvertisingAiLoading;
