import React from "react";

import { Radio } from "antd";
import { Box } from "@mui/material";

import { VcAntRadioTheme, VcAntButtonTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    onChange?: any;
    value?: any;
    options?: any;
    optionType?: "default" | "button" | undefined;
    size?: "small" | "middle" | "large" | undefined;
    displayFlex?: boolean;
}

function VcAntRadio({ className, style, onChange, value, options, optionType, size, displayFlex }: vcProps) {
    const VcAntRadioSetStyles = {
        "&": {
            "& .ant-radio-group": {
                ...(displayFlex === true && { display: "flex" }),
                // 일반 라디오 type
                "& .ant-radio-wrapper": {
                    marginInlineEnd: "16px",
                    // 엑티브 avtive
                    "&.ant-radio-wrapper-checked": {
                        "& .ant-radio-inner": {
                            backgroundColor: VcAntRadioTheme.backgroundColorChecked,
                            borderColor: VcAntRadioTheme.borderColor,
                        },
                    },
                    // 평소 default
                    "& .ant-radio-inner": {
                        backgroundColor: VcAntRadioTheme.backgroundColorDefault,
                        borderColor: VcAntRadioTheme.borderColor,
                        "&::after": {
                            backgroundColor: VcAntRadioTheme.innerPointColor,
                            width: "6px",
                            height: "6px",
                            transform: "translate(-50%, -50%)",
                            top: "50%",
                            left: "50%",
                            bottom: "initial",
                            right: "initial",
                            marginBlockStart: 0,
                            marginInlineStart: 0,
                        },
                    },
                    "& span.ant-radio+*": {
                        color: VcAntRadioTheme.fontColorWhite,
                    },
                },
                // 라디오 type 버튼타입
                "& .ant-radio-button-wrapper": {
                    ...(displayFlex === true && { flex: 1 }),
                    color: VcAntButtonTheme.fontColorWhite,
                    textAlign: "center",
                    "&:first-of-type": {
                        borderStartStartRadius: VcAntButtonTheme.borderRadius,
                        borderEndStartRadius: VcAntButtonTheme.borderRadius,
                    },
                    "&:last-of-type": {
                        borderStartEndRadius: VcAntButtonTheme.borderRadius,
                        borderEndEndRadius: VcAntButtonTheme.borderRadius,
                    },
                },
                // default
                "& .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)": {
                    backgroundColor: VcAntButtonTheme.backgroundColorClear,
                    borderColor: VcAntButtonTheme.borderColor,
                    "&:hover": {
                        backgroundColor: VcAntButtonTheme.backgroundConceptColorSoft,
                    },
                },
                // active
                "& .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)": {
                    backgroundColor: VcAntButtonTheme.backgroundConceptColor,
                    borderColor: VcAntButtonTheme.borderColor,
                    "&:hover": {
                        backgroundColor: VcAntButtonTheme.backgroundConceptColorHover,
                    },
                    "&::before": {
                        backgroundColor: VcAntButtonTheme.borderColor,
                    },
                },
            },
        },
    };

    return (
        <Box sx={VcAntRadioSetStyles}>
            <Radio.Group onChange={onChange} value={value} options={options} optionType={optionType} size={size} />
        </Box>
    );
}

export default VcAntRadio;
