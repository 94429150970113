import React from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    profileLogoImg?: any;
    profileName?: string;
    sideMenu?: any;
    content?: any;
}

function MypageDefaultLayout({ className, style, profileLogoImg, profileName, sideMenu, content }: vcProps) {
    const setStyles = {
        "&": {},
        ...style,
    };

    return (
        <Box sx={setStyles}>
            <Flex justify="space-between" align="flex-start" gap="16px" style={{ width: "100%" }}>
                <Box className="cardLeftBox">
                    <Flex vertical justify="flex-start" align="flex-start" gap="12px">
                        <Box className="userInfoBox">
                            <Box className="logo">{profileLogoImg}</Box>
                            <Box className="name">
                                <Typography>{profileName}</Typography>
                            </Box>
                        </Box>
                        <Box className="sideMenuBox">{sideMenu}</Box>
                    </Flex>
                </Box>
                <Box className="cardRightBox" sx={{ flex: 1 }}>
                    {content}
                </Box>
            </Flex>
        </Box>
    );
}

export default MypageDefaultLayout;
