export const bookingInformationDataGroup = {
    target: {
        groupTitle: "타겟",
        groupItemData: [
            { title: "위치", value: ["전국"] },
            { title: "장소", value: ["번화가", "대학가"] },
            { title: "성별", value: ["여성"] },
            { title: "연령대", value: ["30대"] },
            { title: "예산(월)", value: ["10~30억"] },
            { title: "광고카테고리", value: ["화장품"] },
        ],
    },
    kpi: {
        groupTitle: "KPI",
        groupItemData: {
            indicatorData: [
                { title: "지표명1", value: 70, valueUnit: "%" },
                { title: "지표명2", value: 80, valueUnit: "%" },
                { title: "지표명3", value: 90, valueUnit: "%" },
            ],
        },
    },
    campaign: {
        groupTitle: "캠페인",
        groupItemData: {},
    },
};
