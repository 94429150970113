export const profileCategoryCheckDataGroup = {
    title: "수신동의",
};

export const receiveAgreeDataGroup = [
    {
        label: "이메일 수신을 동의합니다.",
        value: "email",
        checked: false,
    },
    {
        label: "SMS 수신을 동의합니다.",
        value: "sms",
        checked: false,
    },
];

export const changePwModalDataGroup = {
    modalTitle: "비밀번호 변경",
    modalBody: {
        message: "",
        inputProp: {
            currentPwInput: {
                label: "현재 비밀번호",
                placeHolder: "현재 비밀번호를 입력해주세요",
            },
            changePwInput: {
                label: "변경 비밀번호",
                placeHolder: "현재 비밀번호를 입력해주세요",
            },
            changePwReInput: {
                label: "변경 비밀번호 확인",
                placeHolder: "변경할 비밀번호를 재입력해주세요",
            },
        },
    },
};
