export const pageTitleData = "광고하기";

export const advertisingDataGroup = [
    { key: "target", label: "타겟", path: "/mypage/advertiser/campaign-management/advertising/target" },
    { key: "kpi", label: "KPI", path: "/mypage/advertiser/campaign-management/advertising/kpi" },
    { key: "campaign", label: "캠페인", path: "/mypage/advertiser/campaign-management/advertising/campaign" },
    { key: "ailoading", label: "AI추천", path: "/mypage/advertiser/campaign-management/advertising/ai-loading" },
    { key: "medialist", label: "매체선택", path: "/mypage/advertiser/campaign-management/advertising/media-list" },
    {
        key: "result",
        label: "부킹신청",
        path: "/mypage/advertiser/campaign-management/advertising/select-media-name/booking-request-complete",
    },
];
