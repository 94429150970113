import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Row, Col, Typography } from "antd";
import {
    CaretDownOutlined,
    MinusOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import MypageSectionCard from "pages/mypage/common/sectionCard";
import SectionFormControl from "pages/mypage/common/sectionFormControl";

import { campaignListDataGroup } from "data/mypage/advertiser/campaign/list";
import VcAntSelect from "components/antd/data-entry/select";

function AdvertiserPartnerRegisterCampaign() {
    const navigate = useNavigate();

    const subButtonEventArray = [
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
        { event: undefined },
    ];

    function selectOnChangeEvent(value: string) {
        console.log(`selected ${value}`);
    }

    function campaignSelectDeleteButtonEvent(idx: number) {
        console.log(`${idx + 1}번째 캠페인 셀렉트 삭제(-)!`);
    }

    function campaignSelectAddButtonEvent() {
        console.log("캠페인 셀렉트 추가(+)!");
    }

    function prevButtonEvent() {
        console.log("이전!");
    }

    function partnerRegisterCompleteButtonEvent() {
        // console.log("완료!");
        navigate("/mypage/advertiser/partner-management/add-partner/complete");
    }

    const campaignListNameArray = campaignListDataGroup.map(
        (data: any, index: number) => data.campaignName
    );

    const campaignListSelectData: any[] = [];
    campaignListNameArray.forEach((element: string, number: number) => {
        campaignListSelectData.push({
            label: element,
            value: element,
        });
    });

    // 임시로 3번만 반복하기 위하여 사용하였습니다.
    const emptyCampaignSelectCountArray = [
        { idx: 1, name: "캠페인1" },
        { idx: 2, name: "캠페인2" },
        { idx: 3, name: "캠페인3" },
    ];

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Flex vertical justify="flex-start" align="flex-start">
                {emptyCampaignSelectCountArray.map(
                    (data: any, index: number) => {
                        return (
                            <Box
                                className="campaignSelectRowLine"
                                key={index}
                                sx={{ width: "100%" }}
                            >
                                <Row
                                    gutter={[16, 16]}
                                    justify="center"
                                    align="middle"
                                >
                                    <Col span={4}>
                                        <Box className="categoryTitleBox">
                                            <Box className="categoryTitleBoxInner">
                                                <Typography>
                                                    캠페인{index + 1}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col span={16}>
                                        <Box className="categoryDataBox">
                                            <Box className="campaignSelectBox">
                                                <VcAntSelect
                                                    className="partnerRegisterCampaignSelect"
                                                    placeholder="캠페인명을 선택해주세요"
                                                    onChange={
                                                        selectOnChangeEvent
                                                    }
                                                    options={
                                                        campaignListSelectData
                                                    }
                                                    dropdownStyle={{
                                                        textAlign: "center",
                                                    }}
                                                    suffixIcon={
                                                        <CaretDownOutlined />
                                                    }
                                                    fullWidth="100%"
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col span={4}>
                                        <Flex
                                            justify="flex-start"
                                            align="center"
                                            gap="8px"
                                        >
                                            <Box>
                                                <VcAntButton
                                                    conceptType="concept"
                                                    buttonType="default"
                                                    onClick={() => {
                                                        campaignSelectDeleteButtonEvent(
                                                            index
                                                        );
                                                    }}
                                                    buttonTitle=""
                                                    buttonSize="middle"
                                                    icon={<MinusOutlined />}
                                                    iconPosition="end"
                                                />
                                            </Box>

                                            {index ===
                                                emptyCampaignSelectCountArray.length -
                                                    1 && (
                                                <Box>
                                                    <VcAntButton
                                                        conceptType="concept"
                                                        buttonType="default"
                                                        onClick={
                                                            campaignSelectAddButtonEvent
                                                        }
                                                        buttonTitle=""
                                                        buttonSize="middle"
                                                        icon={<PlusOutlined />}
                                                        iconPosition="end"
                                                    />
                                                </Box>
                                            )}
                                        </Flex>
                                    </Col>
                                </Row>
                            </Box>
                        );
                    }
                )}
            </Flex>
        </Box>
    );

    return (
        <Box className="AdvertiserPartnerRegisterCampaignWrap">
            <MypageSectionCard
                topTitle="파트너사 등록 - 캠페인 정보"
                bodyContent={bodyContentData}
            />
            <Box style={{ width: "100%", marginTop: 16 }}>
                <Flex justify="space-between" align="center">
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={prevButtonEvent}
                            buttonTitle="이전"
                            buttonSize="middle"
                        />
                    </Box>
                    <Box>
                        <VcAntButton
                            conceptType="concept"
                            buttonType="default"
                            onClick={partnerRegisterCompleteButtonEvent}
                            buttonTitle="완료"
                            buttonSize="middle"
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default AdvertiserPartnerRegisterCampaign;
