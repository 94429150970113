export const campaignEditDataGroup = [
    {
        title: "캠페인",
        mainInput: {
            switch: true,
            type: "basic",
            placeholder: "캠페인명을 입력해주세요",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
    {
        title: "캠페인 내용",
        mainInput: {
            switch: true,
            inputType: "textarea",
            type: "basic",
            placeholder: "캠페인 내용을 입력해주세요",
        },
        secondInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
        subButton: {
            left: {
                switch: false,
                label: "",
            },
            right: {
                switch: false,
                label: "",
            },
        },
        moreInput: {
            switch: false,
            type: "basic",
            placeholder: "",
        },
    },
];

export const materialEditDataGroup = [
    {
        dataGroup: {
            lineGroup1: {
                title: "광고 소재",
                mainInput: {
                    switch: true,
                    type: "basic",
                    placeholder: "광고 소재를 입력해주세요",
                },
                subButton: {
                    left: {
                        switch: false,
                        label: "",
                        event: () => {
                            console.log("보기");
                        },
                    },
                    right: {
                        switch: false,
                        label: "",
                        event: () => {
                            console.log("보기");
                        },
                    },
                },
            },
            lineGroup2: {
                title: "광고 소재 URL",
                mainInput: {
                    switch: true,
                    type: "basic",
                    placeholder: "광고 소재 URL을 입력해주세요",
                },
                subButton: {
                    left: {
                        switch: false,
                        label: "",
                        event: () => {
                            console.log("보기");
                        },
                    },
                    right: {
                        switch: true,
                        label: "보기",
                        event: () => {
                            console.log("보기");
                        },
                    },
                },
            },
            lineGroup3: {
                title: "광고 소재 내용",
                mainInput: {
                    switch: true,
                    inputType: "textarea",
                    type: "basic",
                    placeholder: "광고 소재 내용을 입력해주세요",
                },
                subButton: {
                    left: {
                        switch: false,
                        label: "",
                        event: () => {
                            console.log("보기");
                        },
                    },
                    right: {
                        switch: false,
                        label: "",
                        event: () => {
                            console.log("보기");
                        },
                    },
                },
            },
        },
    },
];

// sample
// {
//     title: "타이틀",
//     mainInput: {
//         switch: true,
//         type: "basic",
//         placeholder: "",
//     },
//     secondInput: {
//         switch: false,
//         type: "basic",
//         placeholder: "",
//     },
//     subButton: {
//         left: {
//             switch: false,
//             label: "",
//         },
//         right: {
//             switch: false,
//             label: "",
//         },
//     },
//     moreInput: {
//         switch: false,
//         type: "basic",
//         placeholder: "",
//     },
// },
