import React from "react";
import { Outlet } from "react-router-dom";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import ContentCard from "components/layout/contentCard";

import VcMenu from "pages/common/vcMenu";

import { knowledgeServiceMenuItemsDataGroup } from "data/knowledgeService/class";

import "assets/scss/page/knowledgeService.scss";

function KnowledgeServiceIndex() {
    const knowledgeServiceLayout = (
        <Box className="knowledgeServiceLayoutWrap">
            <Box>
                <Flex justify="space-between" align="flex-start" gap="16px" style={{ width: "100%" }}>
                    <Box className="cardLeftBox">
                        <Box className="sideMenuBox">
                            <VcMenu menuItems={knowledgeServiceMenuItemsDataGroup} />
                        </Box>
                    </Box>
                    <Box className="cardRightBox" sx={{ flex: 1 }}>
                        <Outlet />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );

    const bodyContentData = (
        <Box className="knowledgeServiceBodyContent">
            <Box className="contentDataWrap">{knowledgeServiceLayout}</Box>
        </Box>
    );

    return (
        <Box className="knowledgeServiceWrap">
            {/* 마이페이지 */}
            <ContentCard topTitle="지식서비스" bodyContent={bodyContentData} />
        </Box>
    );
}

export default KnowledgeServiceIndex;
