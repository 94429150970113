export const resultDataGroup = {
    common: {
        titleStart: "부킹 신청이",
        titleEnd: "되었습니다",
        description:
            "Deserunt qui fugiat incididunt minim sunt anim nisi quis elit amet do. Pariatur ex proident cillum exercitation qui reprehenderit dolore eiusmod incididunt. Esse sunt veniam adipisicing duis nulla fugiat esse nulla commodo eiusmod in. Nostrud occaecat consectetur reprehenderit quis reprehenderit.",
        requestBookingInformation: {
            badge: "부킹정보",
            unitGroup: {
                sessionTilde: "~",
                volumeUnit: "구좌",
                proposedAdvertisingExpensesUnit: "원",
                proposedAdvertisingExpensesSubDec: "(VAT별도)",
            },
            tableData: {
                row1: {
                    title: "옵션",
                    value: "30초 소재, 1일 100회 이상",
                },
                row2: {
                    title: "기간",
                    value: {
                        sessionStart: {
                            year: 2024,
                            month: 1,
                            day: 30,
                        },
                        sessionEnd: {
                            year: 2024,
                            month: 2,
                            day: 20,
                        },
                    },
                },
                row3: {
                    title: "수량",
                    value: {
                        volume: 1,
                    },
                },
            },
            proposedAdvertisingExpenses: { title: "광고비", value: 15000000 },
        },
    },
    complete: {
        resultWord: "완료",
    },
};
