export const knowledgeServiceMenuItemsDataGroup = [
    { key: "knowledge-category1", label: "카테고리1", path: "/knowledge-service/category-name1/class-list" },
    { key: "knowledge-category2", label: "카테고리2", path: "/knowledge-service/category-name2/class-list" },
    { key: "knowledge-category3", label: "카테고리3", path: "/knowledge-service/category-name3/class-list" },
    { key: "knowledge-category4", label: "카테고리4", path: "/knowledge-service/category-name4/class-list" },
    { key: "knowledge-category5", label: "카테고리5", path: "/knowledge-service/category-name5/class-list" },
];

export const knowledgeServiceClassNoticeText =
    "Ad veniam quis sunt deserunt. Eiusmod adipisicing velit enim sint non tempor amet culpa. Proident non laborum veniam nostrud consectetur quis nisi fugiat qui amet sit. Officia ut tempor occaecat consectetur laborum irure. Mollit velit aliqua enim laboris velit labore in velit. In proident sint officia occaecat cupidatat sit mollit culpa culpa. Consectetur sit enim minim pariatur sunt nostrud ex elit irure sint ut reprehenderit cillum irure. Nostrud in dolore laborum Lorem. Minim minim in aliqua dolore velit. Lorem anim anim eu do eu cillum elit voluptate veniam amet nulla aliquip ipsum nostrud. Pariatur eu est officia velit cupidatat nostrud sint nostrud.";

export const classListUnitDataGroup = {
    classPeriodUnit: "일",
    programCountUnit: "강",
};

export const classListInfoDataGroup = [
    { classImg: "https://picsum.photos/201/201", className: "강좌명1", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/202/202", className: "강좌명2", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/203/203", className: "강좌명3", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/204/204", className: "강좌명4", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/205/205", className: "강좌명5", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/206/206", className: "강좌명6", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/207/207", className: "강좌명7", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/208/208", className: "강좌명8", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/209/209", className: "강좌명9", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/210/210", className: "강좌명10", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/211/211", className: "강좌명11", classPeriodCount: 30, programCount: 24 },
    { classImg: "https://picsum.photos/212/212", className: "강좌명12", classPeriodCount: 30, programCount: 24 },
];

export const knowledgeServiceClassListTableHeader = {
    classTitle: "강좌명",
    classDetail: "상세보기",
    classPeriodCount: "교육일수",
    classProgramCount: "강의수",
    takeClass: "수강",
};

// 주의! classSubLocationPathArray 은 지식서비스 카테고리명의 따라 변경되는 데이타 입니다.
export const classSubLocationPathArray = [
    "/knowledge-service/category-name1",
    "/knowledge-service/category-name2",
    "/knowledge-service/category-name3",
    "/knowledge-service/category-name4",
    "/knowledge-service/category-name5",
];
