import React, { useState } from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcPagination from "./pagination";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    listOptionData?: any;
    postsItemCount: number;
    currentPage: number;
    setCurrentPage: any;
    postsPerPage: number;
    itemGap?: boolean;
}

function VcAntPaginationList({ className, style, listOptionData, postsItemCount, currentPage, setCurrentPage, postsPerPage, itemGap }: vcProps) {
    const setStyles = {
        "&": {
            "& .listBox": {
                ...(itemGap === true
                    ? {
                          "& .postBox": {
                              boxSizing: "border-box",
                              paddingTop: "16px",
                              "&:first-of-type": {
                                  paddingTop: "2px",
                              },
                          },
                      }
                    : {}),
            },
            "& .paginationBox": {
                boxSizing: "border-box",
                paddingTop: "24px",
            },
        },
        ...style,
    };

    return (
        <Box className={className} sx={setStyles}>
            <Box className="listBox">
                {listOptionData.map((data: any, index: number) => {
                    return (
                        <Box className="postBox" key={index}>
                            {data}
                        </Box>
                    );
                })}
            </Box>
            <Box className="paginationBox">
                <VcPagination postsItemCount={postsItemCount} currentPage={currentPage} setCurrentPage={setCurrentPage} postsPerPage={postsPerPage} />
            </Box>
        </Box>
    );
}

export default VcAntPaginationList;
