import React, { ReactNode } from "react";

import { Input } from "antd";
import { Box } from "@mui/material";

import { VcAntInputTheme } from "theme/component";

interface vcProps {
    // chooseType: "basic" | "password" | undefined;
    chooseType: any;
    className?: string;
    style?: React.CSSProperties | undefined;
    fullWidth?: "100%" | "auto" | "flex" | undefined;
    placeholder?: string;
    readOnly?: boolean;
    addonBefore?: ReactNode;
    addonAfter?: ReactNode;
    allowClear?: boolean | { clearIcon: ReactNode };
    classNames?: string;
    count?: any;
    defaultValue?: string;
    disabled?: boolean;
    id?: string;
    maxLength?: number;
    prefix?: ReactNode;
    showCount?: boolean | { formatter: (info: { value: string; count: number; maxLength?: number }) => ReactNode };
    status?: "error" | "warning" | undefined;
    styles?: React.CSSProperties;
    size?: "small" | "middle" | "large" | undefined;
    suffix?: ReactNode;
    type?: string;
    value?: string;
    variant?: "outlined" | "borderless" | "filled" | undefined;
    // onChange?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPressEnter?: any;
    onClear?: any;
}

function VcAntInput({
    chooseType,
    className,
    style,
    fullWidth,
    placeholder,
    readOnly,
    addonBefore,
    addonAfter,
    allowClear,
    classNames,
    count,
    defaultValue,
    disabled,
    id,
    maxLength,
    prefix,
    showCount,
    status,
    styles,
    size,
    suffix,
    type,
    value,
    variant,
    onChange,
    onPressEnter,
    onClear,
}: vcProps) {
    const setFullWidth = fullWidth === "100%" ? { width: "100%" } : fullWidth === "auto" ? { width: "auto" } : fullWidth === "flex" ? { flex: 1 } : {};
    const setStyles = {
        "&": {
            "& .ant-input": {
                ...setFullWidth,
                borderRadius: VcAntInputTheme.borderRadius,
            },
            "& .ant-input-affix-wrapper": {
                ...setFullWidth,
                borderRadius: VcAntInputTheme.borderRadius,
            },
        },
        ...style,
    };

    switch (chooseType) {
        case "password":
            return (
                <Box sx={setStyles}>
                    <Input.Password
                        id={id}
                        className={className}
                        placeholder={placeholder}
                        value={value}
                        size={size}
                        addonBefore={addonBefore}
                        addonAfter={addonAfter}
                        variant={variant}
                        allowClear={allowClear}
                        readOnly={readOnly}
                        onChange={onChange} // onChange 전달
                    />
                </Box>
            );
        case "basic":
            return (
                <Box sx={setStyles}>
                    <Input
                        id={id}
                        className={className}
                        placeholder={placeholder}
                        value={value}
                        size={size}
                        addonBefore={addonBefore}
                        addonAfter={addonAfter}
                        variant={variant}
                        allowClear={allowClear}
                        readOnly={readOnly}
                        onChange={onChange} // onChange 전달
                    />
                </Box>
            );
        default:
            return (
                <Box sx={setStyles}>
                    <Input
                        id={id}
                        className={className}
                        placeholder={placeholder}
                        value={value}
                        size={size}
                        addonBefore={addonBefore}
                        addonAfter={addonAfter}
                        variant={variant}
                        allowClear={allowClear}
                        readOnly={readOnly}
                        onChange={onChange} // onChange 전달
                    />
                </Box>
            );
    }
}

export default VcAntInput;
