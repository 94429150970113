export const pageTitleData = "로그인";

export const loginInputFormDataGroup = [
    {
        title: "아이디",
        placeholder: "아이디를 입력해주세요",
        inputType: "basic",
        allowClear: true,
        id:'id',
    },
    {
        title: "비밀번호",
        placeholder: "비밀번호를 입력해주세요",
        inputType: "password",
        allowClear: true,
        id:'password',
    },
];
