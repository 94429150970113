export const businessUserTypeCategoryTitle = "사업자 종류";

export const businessUserType = [
    { label: "광고주 계정", value: "advertiser" },
    { label: "매체사 계정", value: "media-company" },
];

export const businessUserRequestCompleteDataGroup = {
    guideText: "광고주 회원으로 변경되었습니다.",
    guideDescription: "ㅇㅇㅇ서비스를 이용하실 수 있습니다.",
};
