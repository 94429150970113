import React from "react";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";

import MypageSectionViewTableLayout from "pages/mypage/common/sectionViewTableLayout";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    categoryViewData?: any;
}

function SectionViewControl({ className, style, categoryViewData }: vcProps) {
    const colSizeArray = categoryViewData.map(
        (data: any, index: number) => data.colSize
    );

    const profileDataArray: any[] = [];
    categoryViewData.forEach(
        (element: { title: string; mainData: string; colSize: string }) => {
            profileDataArray.push({
                categoryTitle: element.title,
                categoryData: (
                    <Box className="dataBox">
                        <Box style={{ flex: 1 }}>
                            <Typography>{element.mainData}</Typography>
                        </Box>
                    </Box>
                ),
            });
        }
    );

    return (
        <Box className="sectionViewControlWrap">
            <MypageSectionViewTableLayout
                tableData={profileDataArray}
                viewColSize={colSizeArray}
            />
        </Box>
    );
}

export default SectionViewControl;
