import React from "react";

import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntInput from "components/antd/data-entry/input";
import VcAntTextArea from "components/antd/data-entry/textarea";

import MypageSectionTableLayout from "pages/mypage/common/sectionTableLayout";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    categoryInputData?: any;
    subButtonEventData?: any;
    onInputChange?: (id: string, value: any) => void;
}

function SectionFormControl({ 
    className, 
    style, 
    categoryInputData, 
    subButtonEventData, 
    onInputChange 
}: vcProps & { onInputChange: (id: string, value: any) => void }) {
    const profileDataArray: any[] = [];
    categoryInputData.forEach(
        (
            element: {
                title: string;
                mainInput: {value: string | undefined; switch: boolean; inputType: string | undefined; type: any; placeholder: string | undefined; id: string; };
                secondInput: { switch: boolean; inputType: string | undefined; type: any; placeholder: string | undefined; id: string; };
                subButton: { left: { switch: boolean; label: string | undefined }; right: { switch: boolean; label: string | undefined } };
                moreInput: { switch: boolean; inputType: string | undefined; type: any; placeholder: string | undefined; id: string; };
            },
            number: number
        ) => {
            profileDataArray.push({
                categoryTitle: element.title,
                categoryData: (
                    <Box>
                        <Flex justify="flex-start" align="center" gap="12px" style={{ width: "100%" }}>
                            {element.subButton.left.switch === true && (
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonTitle={element.subButton.left.label}
                                        buttonType="default"
                                        onClick={subButtonEventData[number].event}
                                    />
                                </Box>
                            )}
                            {element.mainInput.switch === true && (
                                element.mainInput.inputType === "textarea" ? (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntTextArea
                                            id={element.mainInput.id}
                                            rows={4}
                                            placeholder={element.mainInput.placeholder}
                                            resize="none"
                                            size="large"
                                        />
                                    </Box>
                                ) : (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntInput
                                            id={element.mainInput.id}
                                            chooseType={element.mainInput.type}
                                            placeholder={element.mainInput.placeholder}
                                            size="large"
                                        />
                                    </Box>
                                )
                            )}
                            {element.secondInput.switch === true ? (
                                element.secondInput.inputType === "textarea" ? (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntTextArea
                                            id={element.secondInput.id}
                                            rows={4}
                                            placeholder={element.secondInput.placeholder}
                                            resize="none"
                                            size="large"
                                        />
                                    </Box>
                                ) : (
                                    <Box style={{ flex: 1 }}>
                                        <VcAntInput
                                            id={element.secondInput.id}
                                            chooseType={element.secondInput.type}
                                            placeholder={element.secondInput.placeholder}
                                            size="large"
                                        />
                                    </Box>
                                )
                            ) : null}
                            {element.subButton.right.switch === true && (
                                <Box>
                                    <VcAntButton
                                        conceptType="concept"
                                        buttonTitle={element.subButton.right.label}
                                        buttonType="default"
                                        onClick={subButtonEventData[number].event}
                                    />
                                </Box>
                            )}
                        </Flex>
                        {element.moreInput.switch === true && (
                            element.moreInput.inputType === "textarea" ? (
                                <Box style={{ flex: 1, paddingTop: "8px" }}>
                                    <VcAntTextArea
                                        id={element.moreInput.id}
                                        rows={4}
                                        placeholder={element.moreInput.placeholder}
                                        resize="none"
                                        size="large"
                                    />
                                </Box>
                            ) : (
                                <Box style={{ flex: 1, paddingTop: "8px" }}>
                                    <VcAntInput
                                        id={element.moreInput.id}
                                        chooseType={element.moreInput.type}
                                        placeholder={element.moreInput.placeholder}
                                        size="large"
                                    />
                                </Box>
                            )
                        )}
                    </Box>
                ),
            });
        }
    );

    return (
        <Box className="sectionFormControlWrap">
            <MypageSectionTableLayout tableData={profileDataArray} />
        </Box>
    );
}

export default SectionFormControl;
