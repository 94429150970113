import React, { useState } from "react";

import { Flex, Typography, Modal, Space, Divider } from "antd";

import { Box } from "@mui/material";

import VcAntModal from "components/antd/feedback/modal";

import mapImageSample from "assets/images/sample/map_img.jpg";

import ChartComp from "./chart";
import MediaDetailView from "./detail";

import { unitDataGroup, mediaDataGroup } from "data/advertiser/advertising/media";

function AdvertisingMedia() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    function listDetailEvent() {
        setIsModalOpen(true);
    }

    function mediaModalHandleOk() {
        setIsModalOpen(false);
    }

    function mediaModalHandleCancel() {
        setIsModalOpen(false);
    }

    const classNames = {
        mask: "mediaDetailModalMask",
        content: "mediaDetailModalContent",
        header: "mediaDetailModalHeader",
        body: "mediaDetailModalBody",
        footer: "mediaDetailModalFooter",
    };

    return (
        <Box className="MediaDataWrap">
            <Box className="mapBox">
                <Box className="mapContent">
                    <img src={mapImageSample} alt="맵이미지" />
                </Box>
            </Box>
            <Box className="listBox">
                <ul>
                    {mediaDataGroup.map((data: any, index: number) => {
                        return (
                            <li key={index}>
                                <Box className="mediaContent">
                                    <span className="mediaClickWrap" onClick={listDetailEvent}>
                                        <Box className="number">{String(data.number).padStart(2, "0")}</Box>
                                        <Flex vertical justify="flex-start" align="flex-start">
                                            <Box className="imgChartBox">
                                                <Flex justify="space-between" align="flex-start" style={{ width: "100%" }}>
                                                    <Box className="imgBox">
                                                        <img src={data.bannerImg} alt="배너이미지" />
                                                    </Box>
                                                    <Box className="chartBox">
                                                        <ChartComp chartBoxWidth={220} chartBoxHeight={150} chartSize={300} />
                                                    </Box>
                                                </Flex>
                                            </Box>
                                            <Box className="mediaInfo">
                                                <Box className="infoLine1">
                                                    <Box className="infoPrice">
                                                        <Flex justify="flex-start" align="flex-end">
                                                            <Typography className="priceDateUnit">
                                                                {unitDataGroup.price.dateUnit}
                                                            </Typography>
                                                            <Typography className="priceValue">
                                                                {data.price.value.toLocaleString("ko-KR")}
                                                            </Typography>
                                                            <Typography className="priceValueUnit">
                                                                {unitDataGroup.price.valueUnit}
                                                            </Typography>
                                                            <Typography className="priceEtc">{unitDataGroup.price.priceEtc}</Typography>
                                                        </Flex>
                                                    </Box>
                                                </Box>
                                                <Box className="infoLine2">
                                                    <Box className="infoTitle">
                                                        <Typography>{data.information.name}</Typography>
                                                    </Box>
                                                    <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                                                        <Box className="infoCharacter">
                                                            <Typography>{data.information.character}</Typography>
                                                        </Box>
                                                        <Box className="infoSize">
                                                            <Typography>
                                                                {data.information.size.displayHorizontalValue.toLocaleString("ko-KR")}
                                                                {unitDataGroup.information.size.displayHorizontalUnit}
                                                            </Typography>
                                                            <Typography style={{ boxSizing: "border-box", padding: "0 4px" }}>
                                                                {unitDataGroup.information.multiply}
                                                            </Typography>
                                                            <Typography>
                                                                {data.information.size.displayVerticalValue.toLocaleString("ko-KR")}
                                                                {unitDataGroup.information.size.displayVerticalUnit}
                                                            </Typography>
                                                            <Typography style={{ boxSizing: "border-box", paddingLeft: "8px" }}>
                                                                &#40;
                                                                {data.information.size.displayTotalValue.toLocaleString("ko-KR")}
                                                                {unitDataGroup.information.size.displayTotalUnit}
                                                                &#41;
                                                            </Typography>
                                                        </Box>
                                                    </Space>
                                                </Box>
                                                <Box className="mediaMatching">
                                                    <Space split={<Divider type="vertical" />} align="center" direction="horizontal">
                                                        <Box className="matchingTitle">
                                                            <Typography>{unitDataGroup.matching.percentTitle}</Typography>
                                                        </Box>
                                                        <Box className="matchingPercent">
                                                            <Flex justify="flex-start" align="flex-end">
                                                                <Box className="matchingPercentValue">
                                                                    <Typography>{data.matching.percentValue}</Typography>
                                                                </Box>
                                                                <Box className="matchingPercentUnit">
                                                                    <Typography>{unitDataGroup.matching.percentUnit}</Typography>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    </Space>
                                                </Box>
                                            </Box>
                                        </Flex>
                                    </span>
                                </Box>
                            </li>
                        );
                    })}
                </ul>
            </Box>
            <VcAntModal
                open={isModalOpen}
                onOk={mediaModalHandleOk}
                onCancel={mediaModalHandleCancel}
                classNames={classNames}
                wrapClassName="listDetailModal"
                footer={null}
                maskClosable={false}
                centered={true}
                width="auto"
                mainContent={<MediaDetailView />}
            />
        </Box>
    );
}

export default AdvertisingMedia;
