import React, { ReactNode } from "react";

import { Select } from "antd";
import { Box } from "@mui/material";

import { VcAntSelectTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties | undefined;
    fullWidth?: "100%" | "auto" | "flex" | undefined;
    placeholder?: string;
    value?: string | number;
    defaultValue?: string | string[] | number | number[];
    size?: "small" | "middle" | "large" | undefined;
    onChange?: any;
    options?: any;
    popupClassName?: string;
    selectStyle?: React.CSSProperties | undefined;
    dropdownStyle?: React.CSSProperties | undefined;
    suffixIcon?: ReactNode;
    popupMatchSelectWidth?: boolean | number;
}

function VcAntSelect({
    className,
    style,
    fullWidth,
    placeholder,
    value,
    defaultValue,
    size,
    onChange,
    options,
    popupClassName,
    selectStyle,
    dropdownStyle,
    suffixIcon,
    popupMatchSelectWidth,
}: vcProps) {
    const setFullWidth = fullWidth === "100%" ? { width: "100%" } : fullWidth === "auto" ? { width: "auto" } : fullWidth === "flex" ? { flex: 1 } : {};
    const setStyle = {
        "& .ant-select": {
            ...setFullWidth,
            textAlign: "center",

            // outlined select
            "&.ant-select-outlined": {
                // 기본 default
                "& .ant-select-selector": {
                    background: VcAntSelectTheme.backgroundColorFull,
                    borderColor: VcAntSelectTheme.borderColor,
                    borderRadius: VcAntSelectTheme.borderRadius,
                    color: VcAntSelectTheme.fontColorBlack,
                },
                // hover 호버
                "&:not(.ant-select-disabled):hover": {
                    "& .ant-select-selector": {
                        background: VcAntSelectTheme.backgroundColorHover,
                        borderColor: VcAntSelectTheme.borderColorHover,
                        color: VcAntSelectTheme.fontColorBlack,
                    },
                },

                // focus
                "&.ant-select-focused": {
                    "&:not(.ant-select-disabled) .ant-select-selector": {
                        background: VcAntSelectTheme.backgroundColorFull,
                    },
                },

                // drop down open
                "&.ant-select-open": {
                    "& .ant-select-selection-item": {
                        color: VcAntSelectTheme.fontColorBlackSoft,
                    },
                    "& .ant-select-arrow": {
                        color: VcAntSelectTheme.fontColorBlackSoft,
                    },
                },
            },

            "& .ant-select-arrow": {
                color: VcAntSelectTheme.fontColorBlack,
            },
        },
        ...style,
    };

    return (
        <Box sx={setStyle}>
            <Select
                className={className}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                size={size}
                onChange={onChange}
                options={options}
                popupClassName={popupClassName + " vcCompSelectClassName"}
                style={selectStyle}
                dropdownStyle={dropdownStyle}
                suffixIcon={suffixIcon}
                popupMatchSelectWidth={popupMatchSelectWidth}
            />
        </Box>
    );
}

export default VcAntSelect;
