import React, { ReactNode } from "react";

import { Button } from "antd";
import { Box } from "@mui/material";

import { VcAntButtonTheme } from "theme/component";

interface vcProps {
    conceptType?: "default" | "clear" | "concept" | "danger" | undefined;
    className?: string;
    boxStyle?: any;
    buttonStyle?: any;
    buttonTitle?: string | number;
    buttonType?: "primary" | "dashed" | "link" | "text" | "default" | undefined;
    buttonSize?: "small" | "middle" | "large" | undefined;
    onClick?: any;
    icon?: React.ReactNode;
    iconPosition?: "start" | "end" | undefined;
    iconColor?: any;
    iconSize?: any;
    disabled?: boolean;
    name?: string;
    value?: string;
    fullWidth?: "100%" | "flex" | "auto" | undefined;
    shape?: "default" | "circle" | "round" | undefined;
}

function VcAntButton({
    conceptType,
    className,
    boxStyle,
    buttonStyle,
    buttonTitle,
    buttonType,
    buttonSize,
    onClick,
    icon,
    iconPosition,
    iconColor,
    iconSize,
    disabled,
    name,
    value,
    fullWidth,
    shape,
}: vcProps) {
    const setFullWidth =
        fullWidth === "100%"
            ? { width: "100%" }
            : fullWidth === "auto"
            ? { width: "auto" }
            : fullWidth === "flex"
            ? { flex: 1 }
            : {};
    const dangerButtonSetStyles = {
        "&": {
            ...setFullWidth,
            "& .ant-btn": {
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled)": {
                    background: VcAntButtonTheme.backgroundColorClear,
                    borderColor: VcAntButtonTheme.borderDangerColor,
                    borderRadius: VcAntButtonTheme.borderRadius,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover":
                    {
                        background: VcAntButtonTheme.backgroundDangerColorSoft,
                        borderColor: VcAntButtonTheme.borderDangerColor,
                        color: VcAntButtonTheme.fontColorWhite,
                    },
                "& .ant-btn-icon .anticon svg": {
                    fill: iconColor,
                    fontSize: iconSize,
                },
            },
        },
        ...boxStyle,
    };
    const conceptButtonSetStyles = {
        "&": {
            ...setFullWidth,
            "& .ant-btn": {
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled)": {
                    background: VcAntButtonTheme.backgroundConceptColor,
                    borderColor: VcAntButtonTheme.borderColor,
                    borderRadius: VcAntButtonTheme.borderRadius,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover":
                    {
                        background:
                            VcAntButtonTheme.backgroundConceptColorHover,
                        borderColor: VcAntButtonTheme.borderColor,
                        color: VcAntButtonTheme.fontColorWhite,
                    },
                "& .ant-btn-icon .anticon svg": {
                    fill: iconColor,
                    fontSize: iconSize,
                },
            },
        },
        ...boxStyle,
    };
    const clearButtonSetStyles = {
        "&": {
            ...setFullWidth,
            "& .ant-btn": {
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled)": {
                    background: VcAntButtonTheme.backgroundColorClear,
                    borderColor: VcAntButtonTheme.borderConceptColor,
                    borderRadius: VcAntButtonTheme.borderRadius,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover":
                    {
                        background: VcAntButtonTheme.backgroundConceptColorSoft,
                        borderColor: VcAntButtonTheme.borderConceptColor,
                        color: VcAntButtonTheme.fontColorWhite,
                    },
                "& .ant-btn-icon .anticon svg": {
                    fill: iconColor,
                    fontSize: iconSize,
                },
            },
        },
        ...boxStyle,
    };

    const defaultButtonSetStyles = {
        "&": {
            ...setFullWidth,
            "& .ant-btn": {
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled)": {
                    background: VcAntButtonTheme.backgroundColor,
                    borderColor: VcAntButtonTheme.borderConceptColor,
                    borderRadius: VcAntButtonTheme.borderRadius,
                    color: VcAntButtonTheme.fontColorBlack,
                },
                "&.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover":
                    {
                        background: VcAntButtonTheme.backgroundColorHover,
                        borderColor: VcAntButtonTheme.borderConceptColor,
                        color: VcAntButtonTheme.fontColorBlack,
                    },
                "& .ant-btn-icon .anticon svg": {
                    fill: iconColor,
                    fontSize: iconSize,
                },
            },
            "& .ant-btn-default:disabled, & .ant-btn-default.ant-btn-disabled":
                {
                    background: VcAntButtonTheme.backgroundDisabledColor,
                    borderColor: VcAntButtonTheme.borderColorDisabled,
                    borderRadius: VcAntButtonTheme.borderRadius,
                    color: VcAntButtonTheme.fontColorWhiteDisabled,
                },
        },
        ...boxStyle,
    };

    switch (conceptType) {
        case "danger":
            return (
                <Box sx={dangerButtonSetStyles}>
                    <Button
                        className={className}
                        style={buttonStyle}
                        type={buttonType}
                        size={buttonSize}
                        onClick={onClick}
                        icon={icon}
                        iconPosition={iconPosition}
                        disabled={disabled}
                        name={name}
                        value={value}
                        shape={shape}
                        {...(fullWidth === "100%" && {
                            block: true,
                        })}
                    >
                        {buttonTitle}
                    </Button>
                </Box>
            );
        case "concept":
            return (
                <Box sx={conceptButtonSetStyles}>
                    <Button
                        className={className}
                        style={buttonStyle}
                        type={buttonType}
                        size={buttonSize}
                        onClick={onClick}
                        icon={icon}
                        iconPosition={iconPosition}
                        disabled={disabled}
                        name={name}
                        value={value}
                        shape={shape}
                        {...(fullWidth === "100%" && {
                            block: true,
                        })}
                    >
                        {buttonTitle}
                    </Button>
                </Box>
            );
        case "clear":
            return (
                <Box sx={clearButtonSetStyles}>
                    <Button
                        className={className}
                        style={buttonStyle}
                        type={buttonType}
                        size={buttonSize}
                        onClick={onClick}
                        icon={icon}
                        iconPosition={iconPosition}
                        disabled={disabled}
                        name={name}
                        value={value}
                        shape={shape}
                        {...(fullWidth === "100%" && {
                            block: true,
                        })}
                    >
                        {buttonTitle}
                    </Button>
                </Box>
            );
        default:
            return (
                <Box sx={defaultButtonSetStyles}>
                    <Button
                        className={className}
                        style={buttonStyle}
                        type={buttonType}
                        size={buttonSize}
                        onClick={onClick}
                        icon={icon}
                        iconPosition={iconPosition}
                        disabled={disabled}
                        name={name}
                        value={value}
                        shape={shape}
                        {...(fullWidth === "100%" && {
                            block: true,
                        })}
                    >
                        {buttonTitle}
                    </Button>
                </Box>
            );
    }
}

export default VcAntButton;
