import axios from 'axios';

const API_URL = '/api/';

export const fetchProfile = async (userId: string) => {
    try {
        // 실제 API 호출 로직
        const response = await axios.get(`${API_URL}?cat=member&req=select&item=${userId}`);
        return response.data[0];
    } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            return { success: false, message: `서버 오류: ${error.response.status}` };
          } else if (error.request) {
            return { success: false, message: '서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.' };
          } else {
            return { success: false, message: `요청 오류: ${error.message}` };
          }
        } else {
          const errorMessage = (error as Error).message;
          return { success: false, message: errorMessage };
        }
      }    
};


export const fetchBusinessProfile = async (userSerial: number) => {
  try {
      // 실제 API 호출 로직
      const response = await axios.post(`${API_URL}?cat=company&req=read`,{flag:"my", member_serial:userSerial});
      return response.data[0];
  } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          return { success: false, message: `서버 오류: ${error.response.status}` };
        } else if (error.request) {
          return { success: false, message: '서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.' };
        } else {
          return { success: false, message: `요청 오류: ${error.message}` };
        }
      } else {
        const errorMessage = (error as Error).message;
        return { success: false, message: errorMessage };
      }
    }    
};