import React, { useEffect, useState } from "react";

import { Flex, Typography } from "antd";
import { PlusOutlined, CaretDownOutlined } from "@ant-design/icons";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";
import VcAntSelect from "components/antd/data-entry/select";

import MypageSectionCard from "pages/mypage/common/sectionCard";

import VcAntPaginationList from "components/custom/paginationList";
import AdvertiserBookingListLayout from "./layout";

import { advertiserMenuItemsDataGroup } from "data/mypage/main";
import { bookingListDataGroup } from "data/mypage/advertiser/booking/list";
import { partnerListDataGroup } from "data/mypage/advertiser/partner/list";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
}

function AdvertiserBookingList({ className, style }: vcProps) {
    const [postsState, setPostsState] = useState<any[]>([]);
    const [currentPageState, setCurrentPageState] = useState<number>(1);
    const [postsPerPageState, setPostsPerPageState] = useState<number>(10);

    useEffect(() => {
        setPostsState(bookingListDataGroup);
    }, []);

    // 회사명 필터 셀렉트박스 리스트
    const partnerListNameArray = partnerListDataGroup.map((data: any, index: number) => data.companyName);

    const partnerListSelectData: any[] = [];
    partnerListNameArray.forEach((element, number) => {
        partnerListSelectData.push({
            label: element,
            value: "partnerName" + number,
        });
    });

    function selectOnChangeEvent(value: string) {
        console.log(`selected ${value}`);
    }

    const finalData: any[] = [];
    bookingListDataGroup.forEach((element, number) => {
        finalData.push(
            <Box>
                <AdvertiserBookingListLayout imgPath={element.imgPath} titleData={element.campaignName} description={element.description} />
            </Box>
        );
    });

    const firstPostIndex = (currentPageState - 1) * postsPerPageState;
    const lastPostIndex = firstPostIndex + postsPerPageState;
    const currentPosts = finalData.slice(firstPostIndex, lastPostIndex);

    const bodyContentData = (
        <Box className="bodyContentDataBox">
            <Box className="listInformationBox">
                <Flex justify="space-between" align="flex-end">
                    <Box className="listCountBox">
                        <Typography>Total {postsState.length} bookings</Typography>
                    </Box>
                    <Box>
                        <Flex justify="flex-end" align="center" gap="12px">
                            <Box>
                                <VcAntSelect
                                    className="advertisingBookingSelect"
                                    placeholder="파트너사 분류"
                                    onChange={selectOnChangeEvent}
                                    options={partnerListSelectData}
                                    dropdownStyle={{ textAlign: "center" }}
                                    suffixIcon={<CaretDownOutlined />}
                                    selectStyle={{ width: "150px" }}
                                />
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
            <VcAntPaginationList
                listOptionData={currentPosts}
                postsItemCount={postsState.length}
                currentPage={currentPageState}
                setCurrentPage={setCurrentPageState}
                postsPerPage={postsPerPageState}
                itemGap={true}
            />
        </Box>
    );

    const topTitleData = advertiserMenuItemsDataGroup[3].label;

    return (
        <Box className="advertiserBookingListWrap">
            <MypageSectionCard topTitle={topTitleData} bodyContent={bodyContentData} />
        </Box>
    );
}

export default AdvertiserBookingList;
