import React from "react";

import { Typography } from "antd";

import { Box } from "@mui/material";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    topTitle?: string;
    bodyContent?: React.ReactNode;
}

function VcSectionCard({ className, style, topTitle, bodyContent }: vcProps) {
    const sectionSetStyles = {
        "&": {},
        ...style,
    };

    return (
        <Box className="section" sx={sectionSetStyles}>
            <Box className="sectionTitle">
                <Typography>{topTitle}</Typography>
            </Box>
            <Box className="sectionBodyBox">{bodyContent}</Box>
        </Box>
    );
}

export default VcSectionCard;
