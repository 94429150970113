import React, { useState } from "react";

import { Flex } from "antd";

import { Box } from "@mui/material";

import VcAntCheckBox from "components/antd/data-entry/checkbox";

import MypageSectionTableLayout from "pages/mypage/common/sectionTableLayout";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    titleData?: any;
    contentData?: any;
}

function SectionCheckboxControl({ className, style, titleData, contentData }: vcProps) {
    const receiveAgreeValueArray = contentData.map((data: any, index: number) => data.value);
    const receiveAgreeCheckedArray = contentData.map((data: any, index: number) => data.checked);

    const [receiveAgreeDataState, setReceiveAgreeDataState] = useState<boolean[]>(receiveAgreeCheckedArray);

    function receiveAgreeOnChangeEvent(event: any) {
        const isChecked = event.target.checked;
        const isValue = event.target.value;
        const checkIndex = receiveAgreeValueArray.indexOf(isValue, 0);
        receiveAgreeDataState[checkIndex] = isChecked;
        setReceiveAgreeDataState([...receiveAgreeDataState]);
    }

    const checkboxDataArray: any[] = [];
    const checkboxData = (
        <Flex justify="flex-start" align="center" gap="16px">
            {contentData.map((data: any, index: number) => {
                return (
                    <Box className="checkGroupBox" key={index}>
                        <VcAntCheckBox onChange={receiveAgreeOnChangeEvent} checked={receiveAgreeDataState[index]} value={data.value} label={data.label} />
                    </Box>
                );
            })}
        </Flex>
    );

    checkboxDataArray.push({
        categoryTitle: titleData,
        categoryData: checkboxData,
    });

    return (
        <Box className="sectionCheckboxControlWrap">
            <MypageSectionTableLayout tableData={checkboxDataArray} />
        </Box>
    );
}

export default SectionCheckboxControl;
