import React, { useState } from "react";

import $ from "jquery";

import { Flex, Typography } from "antd";

import { Box } from "@mui/material";

import VcAntButton from "components/antd/general/button";

import { VcAntButtonTheme } from "theme/component";

interface vcProps {
    className?: string;
    style?: React.CSSProperties;
    postsItemCount: number;
    currentPage: number;
    setCurrentPage: any;
    postsPerPage: number;
}

function VcPagination({ className, style, postsItemCount, currentPage, setCurrentPage, postsPerPage }: vcProps) {
    const pageButtonList: any[] = [];
    const totalPages = Math.ceil(postsItemCount / postsPerPage);

    for (let i = 1; i <= totalPages; i++) {
        pageButtonList.push(i);
    }

    const prevButtonOnClickEvent = () => {
        setCurrentPage(currentPage - 1);
    };
    const nextButtonOnClickEvent = () => {
        setCurrentPage(currentPage + 1);
    };

    if (totalPages === 1) {
        return null;
    }

    function paginationButtonOnClickEvent(event: any) {
        const isName = event.currentTarget.getAttribute("name");
        const isValue = event.currentTarget.value;
        // const checkIndex = itemsDataKeyArray.indexOf(isName);

        setCurrentPage(Number(isValue));
    }

    const setStyles = {
        "&": {
            "& .paginationItem": {
                boxSizing: "border-box",
                padding: "0 2px",
                "& .ant-btn": {
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "& .ant-btn.active": {
                    background: VcAntButtonTheme.backgroundConceptColor,
                },
                // 호버 hover
                "& .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover": {
                    background: VcAntButtonTheme.backgroundConceptColorSoft,
                    color: VcAntButtonTheme.fontColorWhite,
                },
                "& .ant-btn-text.active:not(:disabled):not(.ant-btn-disabled):hover": {
                    background: VcAntButtonTheme.backgroundConceptColor,
                },
            },
            // Prev, Next button
            "& .prevButtonBox, & .nextButtonBox": {
                boxSizing: "border-box",
                padding: "0 12px",
                "& .ant-btn": {
                    color: VcAntButtonTheme.fontColorWhite,
                },
                // 호버 hover
                "& .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover": {
                    background: VcAntButtonTheme.backgroundConceptColorSoft,
                    color: VcAntButtonTheme.fontColorWhite,
                },
            },
        },
        ...style,
    };

    return (
        <Box className="VcPaginationWrap" sx={setStyles}>
            <Box>
                <Flex justify="center" align="center">
                    <Box className="prevButtonBox">
                        <VcAntButton conceptType="default" buttonType="text" buttonTitle="<" onClick={prevButtonOnClickEvent} disabled={currentPage === 1} />
                    </Box>

                    {pageButtonList.map((data: any, index: number) => {
                        return (
                            <Box className="paginationItem" key={index}>
                                <VcAntButton
                                    conceptType="default"
                                    buttonType="text"
                                    buttonTitle={data}
                                    className={currentPage === data ? "active" : ""}
                                    onClick={paginationButtonOnClickEvent}
                                    name={data}
                                    value={data}
                                />
                            </Box>
                        );
                    })}

                    <Box className="nextButtonBox">
                        <VcAntButton
                            conceptType="default"
                            buttonType="text"
                            buttonTitle=">"
                            onClick={nextButtonOnClickEvent}
                            disabled={currentPage === pageButtonList.length}
                        />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default VcPagination;
